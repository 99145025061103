:host, :root {
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Solid";
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Regular";
  --fa-font-light: normal 300 1em / 1 "Font Awesome 6 Light";
  --fa-font-thin: normal 100 1em / 1 "Font Awesome 6 Thin";
  --fa-font-duotone: normal 900 1em / 1 "Font Awesome 6 Duotone";
  --fa-font-sharp-solid: normal 900 1em / 1 "Font Awesome 6 Sharp";
  --fa-font-sharp-regular: normal 400 1em / 1 "Font Awesome 6 Sharp";
  --fa-font-sharp-light: normal 300 1em / 1 "Font Awesome 6 Sharp";
  --fa-font-brands: normal 400 1em / 1 "Font Awesome 6 Brands";
}

svg:not(:host).svg-inline--fa, svg:not(:root).svg-inline--fa {
  box-sizing: content-box;
  overflow: visible;
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  vertical-align: -.125em;
  overflow: visible;
}

.svg-inline--fa.fa-2xs {
  vertical-align: .1em;
}

.svg-inline--fa.fa-xs {
  vertical-align: 0;
}

.svg-inline--fa.fa-sm {
  vertical-align: -.07143em;
}

.svg-inline--fa.fa-lg {
  vertical-align: -.2em;
}

.svg-inline--fa.fa-xl {
  vertical-align: -.25em;
}

.svg-inline--fa.fa-2xl {
  vertical-align: -.3125em;
}

.svg-inline--fa.fa-pull-left {
  margin-right: var(--fa-pull-margin, .3em);
  width: auto;
}

.svg-inline--fa.fa-pull-right {
  margin-left: var(--fa-pull-margin, .3em);
  width: auto;
}

.svg-inline--fa.fa-li {
  width: var(--fa-li-width, 2em);
  top: .25em;
}

.svg-inline--fa.fa-fw {
  width: var(--fa-fw-width, 1.25em);
}

.fa-layers svg.svg-inline--fa {
  margin: auto;
  position: absolute;
  inset: 0;
}

.fa-layers-counter, .fa-layers-text {
  text-align: center;
  display: inline-block;
  position: absolute;
}

.fa-layers {
  height: 1em;
  text-align: center;
  vertical-align: -.125em;
  width: 1em;
  display: inline-block;
  position: relative;
}

.fa-layers svg.svg-inline--fa {
  transform-origin: center;
}

.fa-layers-text {
  transform-origin: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fa-layers-counter {
  background-color: var(--fa-counter-background-color, #ff253a);
  border-radius: var(--fa-counter-border-radius, 1em);
  box-sizing: border-box;
  color: var(--fa-inverse, #fff);
  line-height: var(--fa-counter-line-height, 1);
  max-width: var(--fa-counter-max-width, 5em);
  min-width: var(--fa-counter-min-width, 1.5em);
  padding: var(--fa-counter-padding, .25em .5em);
  right: var(--fa-right, 0);
  text-overflow: ellipsis;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-counter-scale, .25));
  transform: scale(var(--fa-counter-scale, .25));
  transform-origin: 100% 0;
  overflow: hidden;
}

.fa-layers-bottom-right {
  bottom: var(--fa-bottom, 0);
  right: var(--fa-right, 0);
  -webkit-transform: scale(var(--fa-layers-scale, .25));
  transform: scale(var(--fa-layers-scale, .25));
  transform-origin: 100% 100%;
  top: auto;
}

.fa-layers-bottom-left {
  bottom: var(--fa-bottom, 0);
  left: var(--fa-left, 0);
  -webkit-transform: scale(var(--fa-layers-scale, .25));
  transform: scale(var(--fa-layers-scale, .25));
  transform-origin: 0 100%;
  top: auto;
  right: auto;
}

.fa-layers-top-right {
  top: var(--fa-top, 0);
  right: var(--fa-right, 0);
  -webkit-transform: scale(var(--fa-layers-scale, .25));
  transform: scale(var(--fa-layers-scale, .25));
  transform-origin: 100% 0;
}

.fa-layers-top-left {
  left: var(--fa-left, 0);
  right: auto;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-layers-scale, .25));
  transform: scale(var(--fa-layers-scale, .25));
  transform-origin: 0 0;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .08333em;
}

.fa-sm {
  vertical-align: .05357em;
  font-size: .875em;
  line-height: .07143em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .04167em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-radius: var(--fa-border-radius, .1em);
  border: var(--fa-border-width, .08em) var(--fa-border-style, solid) var(--fa-border-color, #eee);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade, .fa-fade {
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-shake, .fa-spin {
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
}

.fa-spin {
  animation-name: fa-spin;
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-beat-fade, .fa-bounce, .fa-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    -webkit-transform: scale(1) translateY(var(--fa-bounce-rebound, -.125em));
    transform: scale(1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  to {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, to {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, to {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  vertical-align: middle;
  height: 2em;
  width: 2.5em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  z-index: var(--fa-stack-z-index, auto);
  margin: auto;
  position: absolute;
  inset: 0;
}

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em;
}

.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-sr-only, .fa-sr-only-focusable:not(:focus), .sr-only, .sr-only-focusable:not(:focus) {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
}

.svg-inline--fa .fa-secondary, .svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: var(--fa-secondary-opacity, .4);
}

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa mask .fa-primary, .svg-inline--fa mask .fa-secondary {
  fill: #000;
}

.fa-duotone.fa-inverse, .fad.fa-inverse {
  color: var(--fa-inverse, #fff);
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

td:not([align]), th:not([align]) {
  text-align: inherit;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  background-color: #fff;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

body, button, input, optgroup, select, textarea {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body {
  color: #4a4a4a;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #ad7d59;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

a:hover {
  color: #363636;
}

code {
  color: #da1039;
  background-color: #fafcfb;
  padding: .25em .5em;
  font-size: .875em;
  font-weight: normal;
}

hr {
  height: 2px;
  background-color: #fafcfb;
  border: none;
  margin: 1.5rem 0;
  display: block;
}

img {
  height: auto;
  max-width: 100%;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #363636;
  font-weight: 700;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #4a4a4a;
  white-space: pre;
  word-wrap: normal;
  background-color: #fafcfb;
  padding: 1.25rem 1.5rem;
  font-size: .875em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  vertical-align: top;
}

table td:not([align]), table th:not([align]) {
  text-align: inherit;
}

table th {
  color: #363636;
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.file-cta, .file-name, .select select, .textarea, .input, .button {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  height: 2.5em;
  vertical-align: top;
  border: 1px solid #0000;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  padding: calc(.5em - 1px) calc(.75em - 1px);
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.file-cta:focus, .file-name:focus, .select select:focus, .textarea:focus, .input:focus, .button:focus, .is-focused.file-cta, .is-focused.file-name, .select select.is-focused, .is-focused.textarea, .is-focused.input, .is-focused.button, .file-cta:active, .file-name:active, .select select:active, .textarea:active, .input:active, .button:active, .is-active.file-cta, .is-active.file-name, .select select.is-active, .is-active.textarea, .is-active.input, .is-active.button {
  outline: none;
}

[disabled].file-cta, [disabled].file-name, .select select[disabled], [disabled].textarea, [disabled].input, [disabled].button, fieldset[disabled] .file-cta, fieldset[disabled] .file-name, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input, fieldset[disabled] .button {
  cursor: not-allowed;
}

.file, .button, .tabs {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.select:not(.is-multiple):not(.is-loading):after, .navbar-link:not(.is-arrowless):after {
  content: " ";
  height: .625em;
  pointer-events: none;
  transform-origin: center;
  width: .625em;
  border-top: 0;
  border-bottom: 3px solid #0000;
  border-left: 3px solid #0000;
  border-right: 0;
  border-radius: 2px;
  margin-top: -.4375em;
  display: block;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
}

.table-container:not(:last-child), .table:not(:last-child), .title:not(:last-child), .subtitle:not(:last-child), .block:not(:last-child), .notification:not(:last-child), .content:not(:last-child), .box:not(:last-child), .message:not(:last-child), .tabs:not(:last-child), .level:not(:last-child) {
  margin-bottom: 1.5rem;
}

.delete, .modal-close {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  pointer-events: auto;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  vertical-align: top;
  width: 20px;
  background-color: #0a0a0a33;
  border: none;
  border-radius: 9999px;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.delete:before, .modal-close:before, .delete:after, .modal-close:after {
  content: "";
  transform-origin: center;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.delete:before, .modal-close:before {
  height: 2px;
  width: 50%;
}

.delete:after, .modal-close:after {
  height: 50%;
  width: 2px;
}

.delete:hover, .modal-close:hover, .delete:focus, .modal-close:focus {
  background-color: #0a0a0a4d;
}

.delete:active, .modal-close:active {
  background-color: #0a0a0a66;
}

.is-small.delete, .is-small.modal-close {
  height: 16px;
  max-height: 16px;
  max-width: 16px;
  min-height: 16px;
  min-width: 16px;
  width: 16px;
}

.is-medium.delete, .is-medium.modal-close {
  height: 24px;
  max-height: 24px;
  max-width: 24px;
  min-height: 24px;
  min-width: 24px;
  width: 24px;
}

.is-large.delete, .is-large.modal-close {
  height: 32px;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  width: 32px;
}

.control.is-loading:after, .select.is-loading:after, .loader, .button.is-loading:after {
  content: "";
  height: 1em;
  width: 1em;
  border: 2px solid #0000;
  border-color: #0000 #0000 #dbdbdb #dbdbdb;
  border-radius: 9999px;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
}

.hero-video, .is-overlay, .image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio, .modal-background, .modal {
  position: absolute;
  inset: 0;
}

.navbar-burger {
  -webkit-appearance: none;
  appearance: none;
  color: currentColor;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
}

.level {
  justify-content: space-between;
  align-items: center;
}

.level code {
  border-radius: 4px;
}

.level img {
  vertical-align: top;
  display: inline-block;
}

.level.is-mobile, .level.is-mobile .level-left, .level.is-mobile .level-right {
  display: flex;
}

.level.is-mobile .level-left + .level-right {
  margin-top: 0;
}

.level.is-mobile .level-item:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.level.is-mobile .level-item:not(.is-narrow) {
  flex-grow: 1;
}

@media screen and (min-width: 769px), print {
  .level {
    display: flex;
  }

  .level > .level-item:not(.is-narrow) {
    flex-grow: 1;
  }
}

.level-item {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.level-item .title, .level-item .subtitle {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .level-item:not(:last-child) {
    margin-bottom: .75rem;
  }
}

.level-left, .level-right {
  flex: none;
}

.level-left .level-item.is-flexible, .level-right .level-item.is-flexible {
  flex-grow: 1;
}

@media screen and (min-width: 769px), print {
  .level-left .level-item:not(:last-child), .level-right .level-item:not(:last-child) {
    margin-right: .75rem;
  }
}

.level-left {
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .level-left + .level-right {
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 769px), print {
  .level-left {
    display: flex;
  }
}

.level-right {
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 769px), print {
  .level-right {
    display: flex;
  }
}

.card {
  color: #4a4a4a;
  max-width: 100%;
  background-color: #fff;
  border-radius: .25rem;
  position: relative;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

.card-footer:first-child, .card-content:first-child, .card-header:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-footer:last-child, .card-content:last-child, .card-header:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.card-header {
  background-color: #0000;
  align-items: stretch;
  display: flex;
  box-shadow: 0 .125em .25em #0a0a0a1a;
}

.card-header-title {
  color: #363636;
  flex-grow: 1;
  align-items: center;
  padding: .75rem 1rem;
  font-weight: 700;
  display: flex;
}

.card-header-title.is-centered {
  justify-content: center;
}

.card-header-icon {
  -webkit-appearance: none;
  appearance: none;
  color: currentColor;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .75rem 1rem;
  font-family: inherit;
  font-size: 1em;
  display: flex;
}

.card-image {
  display: block;
  position: relative;
}

.card-image:first-child img {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-image:last-child img {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.card-content {
  background-color: #0000;
  padding: 1.5rem;
}

.card-footer {
  background-color: #0000;
  border-top: 1px solid #ededed;
  align-items: stretch;
  display: flex;
}

.card-footer-item {
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.card-footer-item:not(:last-child) {
  border-right: 1px solid #ededed;
}

.card .media:not(:last-child) {
  margin-bottom: 1.5rem;
}

.navbar {
  min-height: 3.25rem;
  z-index: 30;
  background-color: #fff;
  position: relative;
}

.navbar.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.navbar.is-white .navbar-brand > .navbar-item, .navbar.is-white .navbar-brand .navbar-link {
  color: #0a0a0a;
}

.navbar.is-white .navbar-brand > a.navbar-item:focus, .navbar.is-white .navbar-brand > a.navbar-item:hover, .navbar.is-white .navbar-brand > a.navbar-item.is-active, .navbar.is-white .navbar-brand .navbar-link:focus, .navbar.is-white .navbar-brand .navbar-link:hover, .navbar.is-white .navbar-brand .navbar-link.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
}

.navbar.is-white .navbar-brand .navbar-link:after {
  border-color: #0a0a0a;
}

.navbar.is-white .navbar-burger {
  color: #0a0a0a;
}

@media screen and (min-width: 1024px) {
  .navbar.is-white .navbar-start > .navbar-item, .navbar.is-white .navbar-start .navbar-link, .navbar.is-white .navbar-end > .navbar-item, .navbar.is-white .navbar-end .navbar-link {
    color: #0a0a0a;
  }

  .navbar.is-white .navbar-start > a.navbar-item:focus, .navbar.is-white .navbar-start > a.navbar-item:hover, .navbar.is-white .navbar-start > a.navbar-item.is-active, .navbar.is-white .navbar-start .navbar-link:focus, .navbar.is-white .navbar-start .navbar-link:hover, .navbar.is-white .navbar-start .navbar-link.is-active, .navbar.is-white .navbar-end > a.navbar-item:focus, .navbar.is-white .navbar-end > a.navbar-item:hover, .navbar.is-white .navbar-end > a.navbar-item.is-active, .navbar.is-white .navbar-end .navbar-link:focus, .navbar.is-white .navbar-end .navbar-link:hover, .navbar.is-white .navbar-end .navbar-link.is-active {
    color: #0a0a0a;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-start .navbar-link:after, .navbar.is-white .navbar-end .navbar-link:after {
    border-color: #0a0a0a;
  }

  .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link {
    color: #0a0a0a;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-dropdown a.navbar-item.is-active {
    color: #0a0a0a;
    background-color: #fff;
  }
}

.navbar.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.navbar.is-black .navbar-brand > .navbar-item, .navbar.is-black .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-black .navbar-brand > a.navbar-item:focus, .navbar.is-black .navbar-brand > a.navbar-item:hover, .navbar.is-black .navbar-brand > a.navbar-item.is-active, .navbar.is-black .navbar-brand .navbar-link:focus, .navbar.is-black .navbar-brand .navbar-link:hover, .navbar.is-black .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.navbar.is-black .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-black .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-black .navbar-start > .navbar-item, .navbar.is-black .navbar-start .navbar-link, .navbar.is-black .navbar-end > .navbar-item, .navbar.is-black .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-black .navbar-start > a.navbar-item:focus, .navbar.is-black .navbar-start > a.navbar-item:hover, .navbar.is-black .navbar-start > a.navbar-item.is-active, .navbar.is-black .navbar-start .navbar-link:focus, .navbar.is-black .navbar-start .navbar-link:hover, .navbar.is-black .navbar-start .navbar-link.is-active, .navbar.is-black .navbar-end > a.navbar-item:focus, .navbar.is-black .navbar-end > a.navbar-item:hover, .navbar.is-black .navbar-end > a.navbar-item.is-active, .navbar.is-black .navbar-end .navbar-link:focus, .navbar.is-black .navbar-end .navbar-link:hover, .navbar.is-black .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #000;
  }

  .navbar.is-black .navbar-start .navbar-link:after, .navbar.is-black .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-black .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-black .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #000;
  }

  .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #0a0a0a;
  }
}

.navbar.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.navbar.is-light .navbar-brand > .navbar-item, .navbar.is-light .navbar-brand .navbar-link {
  color: #000000b3;
}

.navbar.is-light .navbar-brand > a.navbar-item:focus, .navbar.is-light .navbar-brand > a.navbar-item:hover, .navbar.is-light .navbar-brand > a.navbar-item.is-active, .navbar.is-light .navbar-brand .navbar-link:focus, .navbar.is-light .navbar-brand .navbar-link:hover, .navbar.is-light .navbar-brand .navbar-link.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
}

.navbar.is-light .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

.navbar.is-light .navbar-burger {
  color: #000000b3;
}

@media screen and (min-width: 1024px) {
  .navbar.is-light .navbar-start > .navbar-item, .navbar.is-light .navbar-start .navbar-link, .navbar.is-light .navbar-end > .navbar-item, .navbar.is-light .navbar-end .navbar-link {
    color: #000000b3;
  }

  .navbar.is-light .navbar-start > a.navbar-item:focus, .navbar.is-light .navbar-start > a.navbar-item:hover, .navbar.is-light .navbar-start > a.navbar-item.is-active, .navbar.is-light .navbar-start .navbar-link:focus, .navbar.is-light .navbar-start .navbar-link:hover, .navbar.is-light .navbar-start .navbar-link.is-active, .navbar.is-light .navbar-end > a.navbar-item:focus, .navbar.is-light .navbar-end > a.navbar-item:hover, .navbar.is-light .navbar-end > a.navbar-item.is-active, .navbar.is-light .navbar-end .navbar-link:focus, .navbar.is-light .navbar-end .navbar-link:hover, .navbar.is-light .navbar-end .navbar-link.is-active {
    color: #000000b3;
    background-color: #e8e8e8;
  }

  .navbar.is-light .navbar-start .navbar-link:after, .navbar.is-light .navbar-end .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-light .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-light .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-light .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000000b3;
    background-color: #e8e8e8;
  }

  .navbar.is-light .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #f5f5f5;
  }
}

.navbar.is-dark {
  color: #fff;
  background-color: #271f26;
}

.navbar.is-dark .navbar-brand > .navbar-item, .navbar.is-dark .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-dark .navbar-brand > a.navbar-item:focus, .navbar.is-dark .navbar-brand > a.navbar-item:hover, .navbar.is-dark .navbar-brand > a.navbar-item.is-active, .navbar.is-dark .navbar-brand .navbar-link:focus, .navbar.is-dark .navbar-brand .navbar-link:hover, .navbar.is-dark .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #191418;
}

.navbar.is-dark .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-dark .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-dark .navbar-start > .navbar-item, .navbar.is-dark .navbar-start .navbar-link, .navbar.is-dark .navbar-end > .navbar-item, .navbar.is-dark .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-dark .navbar-start > a.navbar-item:focus, .navbar.is-dark .navbar-start > a.navbar-item:hover, .navbar.is-dark .navbar-start > a.navbar-item.is-active, .navbar.is-dark .navbar-start .navbar-link:focus, .navbar.is-dark .navbar-start .navbar-link:hover, .navbar.is-dark .navbar-start .navbar-link.is-active, .navbar.is-dark .navbar-end > a.navbar-item:focus, .navbar.is-dark .navbar-end > a.navbar-item:hover, .navbar.is-dark .navbar-end > a.navbar-item.is-active, .navbar.is-dark .navbar-end .navbar-link:focus, .navbar.is-dark .navbar-end .navbar-link:hover, .navbar.is-dark .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #191418;
  }

  .navbar.is-dark .navbar-start .navbar-link:after, .navbar.is-dark .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-dark .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-dark .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #191418;
  }

  .navbar.is-dark .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #271f26;
  }
}

.navbar.is-primary {
  color: #fff;
  background-color: #264d4a;
}

.navbar.is-primary .navbar-brand > .navbar-item, .navbar.is-primary .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-primary .navbar-brand > a.navbar-item:focus, .navbar.is-primary .navbar-brand > a.navbar-item:hover, .navbar.is-primary .navbar-brand > a.navbar-item.is-active, .navbar.is-primary .navbar-brand .navbar-link:focus, .navbar.is-primary .navbar-brand .navbar-link:hover, .navbar.is-primary .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #1e3c3a;
}

.navbar.is-primary .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-primary .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-primary .navbar-start > .navbar-item, .navbar.is-primary .navbar-start .navbar-link, .navbar.is-primary .navbar-end > .navbar-item, .navbar.is-primary .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-primary .navbar-start > a.navbar-item:focus, .navbar.is-primary .navbar-start > a.navbar-item:hover, .navbar.is-primary .navbar-start > a.navbar-item.is-active, .navbar.is-primary .navbar-start .navbar-link:focus, .navbar.is-primary .navbar-start .navbar-link:hover, .navbar.is-primary .navbar-start .navbar-link.is-active, .navbar.is-primary .navbar-end > a.navbar-item:focus, .navbar.is-primary .navbar-end > a.navbar-item:hover, .navbar.is-primary .navbar-end > a.navbar-item.is-active, .navbar.is-primary .navbar-end .navbar-link:focus, .navbar.is-primary .navbar-end .navbar-link:hover, .navbar.is-primary .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #1e3c3a;
  }

  .navbar.is-primary .navbar-start .navbar-link:after, .navbar.is-primary .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-primary .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #1e3c3a;
  }

  .navbar.is-primary .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #264d4a;
  }
}

.navbar.is-link {
  color: #fff;
  background-color: #ad7d59;
}

.navbar.is-link .navbar-brand > .navbar-item, .navbar.is-link .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-link .navbar-brand > a.navbar-item:focus, .navbar.is-link .navbar-brand > a.navbar-item:hover, .navbar.is-link .navbar-brand > a.navbar-item.is-active, .navbar.is-link .navbar-brand .navbar-link:focus, .navbar.is-link .navbar-brand .navbar-link:hover, .navbar.is-link .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #9e714e;
}

.navbar.is-link .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-link .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-link .navbar-start > .navbar-item, .navbar.is-link .navbar-start .navbar-link, .navbar.is-link .navbar-end > .navbar-item, .navbar.is-link .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-link .navbar-start > a.navbar-item:focus, .navbar.is-link .navbar-start > a.navbar-item:hover, .navbar.is-link .navbar-start > a.navbar-item.is-active, .navbar.is-link .navbar-start .navbar-link:focus, .navbar.is-link .navbar-start .navbar-link:hover, .navbar.is-link .navbar-start .navbar-link.is-active, .navbar.is-link .navbar-end > a.navbar-item:focus, .navbar.is-link .navbar-end > a.navbar-item:hover, .navbar.is-link .navbar-end > a.navbar-item.is-active, .navbar.is-link .navbar-end .navbar-link:focus, .navbar.is-link .navbar-end .navbar-link:hover, .navbar.is-link .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #9e714e;
  }

  .navbar.is-link .navbar-start .navbar-link:after, .navbar.is-link .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-link .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-link .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #9e714e;
  }

  .navbar.is-link .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #ad7d59;
  }
}

.navbar.is-info {
  color: #fff;
  background-color: #3f3024;
}

.navbar.is-info .navbar-brand > .navbar-item, .navbar.is-info .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-info .navbar-brand > a.navbar-item:focus, .navbar.is-info .navbar-brand > a.navbar-item:hover, .navbar.is-info .navbar-brand > a.navbar-item.is-active, .navbar.is-info .navbar-brand .navbar-link:focus, .navbar.is-info .navbar-brand .navbar-link:hover, .navbar.is-info .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #2f241b;
}

.navbar.is-info .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-info .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-info .navbar-start > .navbar-item, .navbar.is-info .navbar-start .navbar-link, .navbar.is-info .navbar-end > .navbar-item, .navbar.is-info .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-info .navbar-start > a.navbar-item:focus, .navbar.is-info .navbar-start > a.navbar-item:hover, .navbar.is-info .navbar-start > a.navbar-item.is-active, .navbar.is-info .navbar-start .navbar-link:focus, .navbar.is-info .navbar-start .navbar-link:hover, .navbar.is-info .navbar-start .navbar-link.is-active, .navbar.is-info .navbar-end > a.navbar-item:focus, .navbar.is-info .navbar-end > a.navbar-item:hover, .navbar.is-info .navbar-end > a.navbar-item.is-active, .navbar.is-info .navbar-end .navbar-link:focus, .navbar.is-info .navbar-end .navbar-link:hover, .navbar.is-info .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #2f241b;
  }

  .navbar.is-info .navbar-start .navbar-link:after, .navbar.is-info .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-info .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-info .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-info .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #2f241b;
  }

  .navbar.is-info .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #3f3024;
  }
}

.navbar.is-success {
  color: #000000b3;
  background-color: #86ce93;
}

.navbar.is-success .navbar-brand > .navbar-item, .navbar.is-success .navbar-brand .navbar-link {
  color: #000000b3;
}

.navbar.is-success .navbar-brand > a.navbar-item:focus, .navbar.is-success .navbar-brand > a.navbar-item:hover, .navbar.is-success .navbar-brand > a.navbar-item.is-active, .navbar.is-success .navbar-brand .navbar-link:focus, .navbar.is-success .navbar-brand .navbar-link:hover, .navbar.is-success .navbar-brand .navbar-link.is-active {
  color: #000000b3;
  background-color: #74c783;
}

.navbar.is-success .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

.navbar.is-success .navbar-burger {
  color: #000000b3;
}

@media screen and (min-width: 1024px) {
  .navbar.is-success .navbar-start > .navbar-item, .navbar.is-success .navbar-start .navbar-link, .navbar.is-success .navbar-end > .navbar-item, .navbar.is-success .navbar-end .navbar-link {
    color: #000000b3;
  }

  .navbar.is-success .navbar-start > a.navbar-item:focus, .navbar.is-success .navbar-start > a.navbar-item:hover, .navbar.is-success .navbar-start > a.navbar-item.is-active, .navbar.is-success .navbar-start .navbar-link:focus, .navbar.is-success .navbar-start .navbar-link:hover, .navbar.is-success .navbar-start .navbar-link.is-active, .navbar.is-success .navbar-end > a.navbar-item:focus, .navbar.is-success .navbar-end > a.navbar-item:hover, .navbar.is-success .navbar-end > a.navbar-item.is-active, .navbar.is-success .navbar-end .navbar-link:focus, .navbar.is-success .navbar-end .navbar-link:hover, .navbar.is-success .navbar-end .navbar-link.is-active {
    color: #000000b3;
    background-color: #74c783;
  }

  .navbar.is-success .navbar-start .navbar-link:after, .navbar.is-success .navbar-end .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-success .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000000b3;
    background-color: #74c783;
  }

  .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #86ce93;
  }
}

.navbar.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.navbar.is-warning .navbar-brand > .navbar-item, .navbar.is-warning .navbar-brand .navbar-link {
  color: #000000b3;
}

.navbar.is-warning .navbar-brand > a.navbar-item:focus, .navbar.is-warning .navbar-brand > a.navbar-item:hover, .navbar.is-warning .navbar-brand > a.navbar-item.is-active, .navbar.is-warning .navbar-brand .navbar-link:focus, .navbar.is-warning .navbar-brand .navbar-link:hover, .navbar.is-warning .navbar-brand .navbar-link.is-active {
  color: #000000b3;
  background-color: #ffd970;
}

.navbar.is-warning .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

.navbar.is-warning .navbar-burger {
  color: #000000b3;
}

@media screen and (min-width: 1024px) {
  .navbar.is-warning .navbar-start > .navbar-item, .navbar.is-warning .navbar-start .navbar-link, .navbar.is-warning .navbar-end > .navbar-item, .navbar.is-warning .navbar-end .navbar-link {
    color: #000000b3;
  }

  .navbar.is-warning .navbar-start > a.navbar-item:focus, .navbar.is-warning .navbar-start > a.navbar-item:hover, .navbar.is-warning .navbar-start > a.navbar-item.is-active, .navbar.is-warning .navbar-start .navbar-link:focus, .navbar.is-warning .navbar-start .navbar-link:hover, .navbar.is-warning .navbar-start .navbar-link.is-active, .navbar.is-warning .navbar-end > a.navbar-item:focus, .navbar.is-warning .navbar-end > a.navbar-item:hover, .navbar.is-warning .navbar-end > a.navbar-item.is-active, .navbar.is-warning .navbar-end .navbar-link:focus, .navbar.is-warning .navbar-end .navbar-link:hover, .navbar.is-warning .navbar-end .navbar-link.is-active {
    color: #000000b3;
    background-color: #ffd970;
  }

  .navbar.is-warning .navbar-start .navbar-link:after, .navbar.is-warning .navbar-end .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-warning .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-warning .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-warning .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000000b3;
    background-color: #ffd970;
  }

  .navbar.is-warning .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #ffe08a;
  }
}

.navbar.is-danger {
  color: #fff;
  background-color: #843e37;
}

.navbar.is-danger .navbar-brand > .navbar-item, .navbar.is-danger .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-danger .navbar-brand > a.navbar-item:focus, .navbar.is-danger .navbar-brand > a.navbar-item:hover, .navbar.is-danger .navbar-brand > a.navbar-item.is-active, .navbar.is-danger .navbar-brand .navbar-link:focus, .navbar.is-danger .navbar-brand .navbar-link:hover, .navbar.is-danger .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #723630;
}

.navbar.is-danger .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-danger .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-danger .navbar-start > .navbar-item, .navbar.is-danger .navbar-start .navbar-link, .navbar.is-danger .navbar-end > .navbar-item, .navbar.is-danger .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-danger .navbar-start > a.navbar-item:focus, .navbar.is-danger .navbar-start > a.navbar-item:hover, .navbar.is-danger .navbar-start > a.navbar-item.is-active, .navbar.is-danger .navbar-start .navbar-link:focus, .navbar.is-danger .navbar-start .navbar-link:hover, .navbar.is-danger .navbar-start .navbar-link.is-active, .navbar.is-danger .navbar-end > a.navbar-item:focus, .navbar.is-danger .navbar-end > a.navbar-item:hover, .navbar.is-danger .navbar-end > a.navbar-item.is-active, .navbar.is-danger .navbar-end .navbar-link:focus, .navbar.is-danger .navbar-end .navbar-link:hover, .navbar.is-danger .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #723630;
  }

  .navbar.is-danger .navbar-start .navbar-link:after, .navbar.is-danger .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-danger .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #723630;
  }

  .navbar.is-danger .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #843e37;
  }
}

.navbar > .container {
  min-height: 3.25rem;
  width: 100%;
  align-items: stretch;
  display: flex;
}

.navbar.has-shadow {
  box-shadow: 0 2px #fafcfb;
}

.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  z-index: 30;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar.is-fixed-bottom {
  bottom: 0;
}

.navbar.is-fixed-bottom.has-shadow {
  box-shadow: 0 -2px #fafcfb;
}

.navbar.is-fixed-top {
  top: 0;
}

html.has-navbar-fixed-top, body.has-navbar-fixed-top {
  padding-top: 3.25rem;
}

html.has-navbar-fixed-bottom, body.has-navbar-fixed-bottom {
  padding-bottom: 3.25rem;
}

.navbar-brand, .navbar-tabs {
  min-height: 3.25rem;
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
}

.navbar-brand a.navbar-item:focus, .navbar-brand a.navbar-item:hover {
  background-color: #0000;
}

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.navbar-burger {
  color: #4a4a4a;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  height: 3.25rem;
  width: 3.25rem;
  background: none;
  border: none;
  margin-left: auto;
  display: block;
  position: relative;
}

.navbar-burger span {
  height: 1px;
  transform-origin: center;
  width: 16px;
  background-color: currentColor;
  transition-property: background-color, opacity, transform;
  transition-duration: 86ms;
  transition-timing-function: ease-out;
  display: block;
  position: absolute;
  left: calc(50% - 8px);
}

.navbar-burger span:nth-child(1) {
  top: calc(50% - 6px);
}

.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px);
}

.navbar-burger span:nth-child(3) {
  top: calc(50% + 4px);
}

.navbar-burger:hover {
  background-color: #0000000d;
}

.navbar-burger.is-active span:nth-child(1) {
  transform: translateY(5px)rotate(45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  transform: translateY(-5px)rotate(-45deg);
}

.navbar-menu {
  display: none;
}

.navbar-item, .navbar-link {
  color: #4a4a4a;
  padding: .5rem .75rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

.navbar-item .icon:only-child, .navbar-link .icon:only-child {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

a.navbar-item, .navbar-link {
  cursor: pointer;
}

a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
  color: #ad7d59;
  background-color: #fafafa;
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
}

.navbar-item img {
  max-height: 1.75rem;
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-item.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-item.is-tab {
  min-height: 3.25rem;
  border-bottom: 1px solid #0000;
  padding-bottom: calc(.5rem - 1px);
}

.navbar-item.is-tab:focus, .navbar-item.is-tab:hover {
  background-color: #0000;
  border-bottom-color: #ad7d59;
}

.navbar-item.is-tab.is-active {
  color: #ad7d59;
  background-color: #0000;
  border-bottom: 3px solid #ad7d59;
  padding-bottom: calc(.5rem - 3px);
}

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-link:not(.is-arrowless) {
  padding-right: 2.5em;
}

.navbar-link:not(.is-arrowless):after {
  border-color: #ad7d59;
  margin-top: -.375em;
  right: 1.125em;
}

.navbar-dropdown {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-divider {
  height: 2px;
  background-color: #fafcfb;
  border: none;
  margin: .5rem 0;
  display: none;
}

@media screen and (max-width: 1023px) {
  .navbar > .container {
    display: block;
  }

  .navbar-brand .navbar-item, .navbar-tabs .navbar-item {
    align-items: center;
    display: flex;
  }

  .navbar-link:after {
    display: none;
  }

  .navbar-menu {
    background-color: #fff;
    padding: .5rem 0;
    box-shadow: 0 8px 16px #0a0a0a1a;
  }

  .navbar-menu.is-active {
    display: block;
  }

  .navbar.is-fixed-bottom-touch, .navbar.is-fixed-top-touch {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-touch {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-touch.has-shadow {
    box-shadow: 0 -2px 3px #0a0a0a1a;
  }

  .navbar.is-fixed-top-touch {
    top: 0;
  }

  .navbar.is-fixed-top .navbar-menu, .navbar.is-fixed-top-touch .navbar-menu {
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 3.25rem);
    overflow: auto;
  }

  html.has-navbar-fixed-top-touch, body.has-navbar-fixed-top-touch {
    padding-top: 3.25rem;
  }

  html.has-navbar-fixed-bottom-touch, body.has-navbar-fixed-bottom-touch {
    padding-bottom: 3.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .navbar, .navbar-menu, .navbar-start, .navbar-end {
    align-items: stretch;
    display: flex;
  }

  .navbar {
    min-height: 3.25rem;
  }

  .navbar.is-spaced {
    padding: 1rem 2rem;
  }

  .navbar.is-spaced .navbar-start, .navbar.is-spaced .navbar-end {
    align-items: center;
  }

  .navbar.is-spaced a.navbar-item, .navbar.is-spaced .navbar-link {
    border-radius: 4px;
  }

  .navbar.is-transparent a.navbar-item:focus, .navbar.is-transparent a.navbar-item:hover, .navbar.is-transparent a.navbar-item.is-active, .navbar.is-transparent .navbar-link:focus, .navbar.is-transparent .navbar-link:hover, .navbar.is-transparent .navbar-link.is-active, .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus-within .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:hover .navbar-link {
    background-color: #0000 !important;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item:focus, .navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
    color: #0a0a0a;
    background-color: #fafcfb;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
    color: #ad7d59;
    background-color: #fafcfb;
  }

  .navbar-burger {
    display: none;
  }

  .navbar-item, .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
  }

  .navbar-item.has-dropdown-up .navbar-link:after {
    transform: rotate(135deg)translate(.25em, -.25em);
  }

  .navbar-item.has-dropdown-up .navbar-dropdown {
    border-top: none;
    border-bottom: 2px solid #dbdbdb;
    border-radius: 6px 6px 0 0;
    top: auto;
    bottom: 100%;
    box-shadow: 0 -8px 8px #0a0a0a1a;
  }

  .navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar.is-spaced .navbar-item.is-active .navbar-dropdown, .navbar-item.is-active .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  .navbar-dropdown {
    min-width: 100%;
    z-index: 20;
    background-color: #fff;
    border-top: 2px solid #dbdbdb;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    font-size: .875rem;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 8px 8px #0a0a0a1a;
  }

  .navbar-dropdown .navbar-item {
    white-space: nowrap;
    padding: .375rem 1rem;
  }

  .navbar-dropdown a.navbar-item {
    padding-right: 3rem;
  }

  .navbar-dropdown a.navbar-item:focus, .navbar-dropdown a.navbar-item:hover {
    color: #0a0a0a;
    background-color: #fafcfb;
  }

  .navbar-dropdown a.navbar-item.is-active {
    color: #ad7d59;
    background-color: #fafcfb;
  }

  .navbar.is-spaced .navbar-dropdown, .navbar-dropdown.is-boxed {
    opacity: 0;
    pointer-events: none;
    border-top: none;
    border-radius: 6px;
    transition-property: opacity, transform;
    transition-duration: 86ms;
    display: block;
    top: calc(100% - 4px);
    transform: translateY(-5px);
    box-shadow: 0 8px 8px #0a0a0a1a, 0 0 0 1px #0a0a0a1a;
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  .navbar-divider {
    display: block;
  }

  .navbar > .container .navbar-brand, .container > .navbar .navbar-brand {
    margin-left: -.75rem;
  }

  .navbar > .container .navbar-menu, .container > .navbar .navbar-menu {
    margin-right: -.75rem;
  }

  .navbar.is-fixed-bottom-desktop, .navbar.is-fixed-top-desktop {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-desktop {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-desktop.has-shadow {
    box-shadow: 0 -2px 3px #0a0a0a1a;
  }

  .navbar.is-fixed-top-desktop {
    top: 0;
  }

  html.has-navbar-fixed-top-desktop, body.has-navbar-fixed-top-desktop {
    padding-top: 3.25rem;
  }

  html.has-navbar-fixed-bottom-desktop, body.has-navbar-fixed-bottom-desktop {
    padding-bottom: 3.25rem;
  }

  html.has-spaced-navbar-fixed-top, body.has-spaced-navbar-fixed-top {
    padding-top: 5.25rem;
  }

  html.has-spaced-navbar-fixed-bottom, body.has-spaced-navbar-fixed-bottom {
    padding-bottom: 5.25rem;
  }

  a.navbar-item.is-active, .navbar-link.is-active {
    color: #0a0a0a;
  }

  a.navbar-item.is-active:not(:focus):not(:hover), .navbar-link.is-active:not(:focus):not(:hover) {
    background-color: #0000;
  }

  .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #fafafa;
  }
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 3.25rem);
}

.tabs {
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  justify-content: space-between;
  align-items: stretch;
  font-size: 1rem;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.tabs a {
  color: #4a4a4a;
  vertical-align: top;
  border-bottom: 1px solid #dbdbdb;
  justify-content: center;
  align-items: center;
  margin-bottom: -1px;
  padding: .5em 1em;
  display: flex;
}

.tabs a:hover {
  color: #363636;
  border-bottom-color: #363636;
}

.tabs li {
  display: block;
}

.tabs li.is-active a {
  color: #ad7d59;
  border-bottom-color: #ad7d59;
}

.tabs ul {
  border-bottom: 1px solid #dbdbdb;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tabs ul.is-left {
  padding-right: .75em;
}

.tabs ul.is-center {
  flex: none;
  justify-content: center;
  padding-left: .75em;
  padding-right: .75em;
}

.tabs ul.is-right {
  justify-content: flex-end;
  padding-left: .75em;
}

.tabs .icon:first-child {
  margin-right: .5em;
}

.tabs .icon:last-child {
  margin-left: .5em;
}

.tabs.is-centered ul {
  justify-content: center;
}

.tabs.is-right ul {
  justify-content: flex-end;
}

.tabs.is-boxed a {
  border: 1px solid #0000;
  border-radius: 4px 4px 0 0;
}

.tabs.is-boxed a:hover {
  background-color: #fafcfb;
  border-bottom-color: #dbdbdb;
}

.tabs.is-boxed li.is-active a {
  background-color: #fff;
  border-color: #dbdbdb;
  border-bottom-color: #0000 !important;
}

.tabs.is-fullwidth li {
  flex-grow: 1;
  flex-shrink: 0;
}

.tabs.is-toggle a {
  border: 1px solid #dbdbdb;
  margin-bottom: 0;
  position: relative;
}

.tabs.is-toggle a:hover {
  z-index: 2;
  background-color: #fafcfb;
  border-color: #b5b5b5;
}

.tabs.is-toggle li + li {
  margin-left: -1px;
}

.tabs.is-toggle li:first-child a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tabs.is-toggle li:last-child a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tabs.is-toggle li.is-active a {
  color: #fff;
  z-index: 1;
  background-color: #ad7d59;
  border-color: #ad7d59;
}

.tabs.is-toggle ul {
  border-bottom: none;
}

.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  padding-left: 1.25em;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  padding-right: 1.25em;
}

.tabs.is-small {
  font-size: .75rem;
}

.tabs.is-medium {
  font-size: 1.25rem;
}

.tabs.is-large {
  font-size: 1.5rem;
}

.menu {
  font-size: 1rem;
}

.menu.is-small {
  font-size: .75rem;
}

.menu.is-medium {
  font-size: 1.25rem;
}

.menu.is-large {
  font-size: 1.5rem;
}

.menu-list {
  line-height: 1.25;
}

.menu-list a {
  color: #4a4a4a;
  border-radius: 2px;
  padding: .5em .75em;
  display: block;
}

.menu-list a:hover {
  color: #363636;
  background-color: #fafcfb;
}

.menu-list a.is-active {
  color: #fff;
  background-color: #ad7d59;
}

.menu-list li ul {
  border-left: 1px solid #dbdbdb;
  margin: .75em;
  padding-left: .75em;
}

.menu-label {
  color: #7a7a7a;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: .75em;
}

.menu-label:not(:first-child) {
  margin-top: 1em;
}

.menu-label:not(:last-child) {
  margin-bottom: 1em;
}

.panel {
  border-radius: 6px;
  font-size: 1rem;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

.panel:not(:last-child) {
  margin-bottom: 1.5rem;
}

.panel.is-white .panel-heading {
  color: #0a0a0a;
  background-color: #fff;
}

.panel.is-white .panel-tabs a.is-active {
  border-bottom-color: #fff;
}

.panel.is-white .panel-block.is-active .panel-icon {
  color: #fff;
}

.panel.is-black .panel-heading {
  color: #fff;
  background-color: #0a0a0a;
}

.panel.is-black .panel-tabs a.is-active {
  border-bottom-color: #0a0a0a;
}

.panel.is-black .panel-block.is-active .panel-icon {
  color: #0a0a0a;
}

.panel.is-light .panel-heading {
  color: #000000b3;
  background-color: #f5f5f5;
}

.panel.is-light .panel-tabs a.is-active {
  border-bottom-color: #f5f5f5;
}

.panel.is-light .panel-block.is-active .panel-icon {
  color: #f5f5f5;
}

.panel.is-dark .panel-heading {
  color: #fff;
  background-color: #271f26;
}

.panel.is-dark .panel-tabs a.is-active {
  border-bottom-color: #271f26;
}

.panel.is-dark .panel-block.is-active .panel-icon {
  color: #271f26;
}

.panel.is-primary .panel-heading {
  color: #fff;
  background-color: #264d4a;
}

.panel.is-primary .panel-tabs a.is-active {
  border-bottom-color: #264d4a;
}

.panel.is-primary .panel-block.is-active .panel-icon {
  color: #264d4a;
}

.panel.is-link .panel-heading {
  color: #fff;
  background-color: #ad7d59;
}

.panel.is-link .panel-tabs a.is-active {
  border-bottom-color: #ad7d59;
}

.panel.is-link .panel-block.is-active .panel-icon {
  color: #ad7d59;
}

.panel.is-info .panel-heading {
  color: #fff;
  background-color: #3f3024;
}

.panel.is-info .panel-tabs a.is-active {
  border-bottom-color: #3f3024;
}

.panel.is-info .panel-block.is-active .panel-icon {
  color: #3f3024;
}

.panel.is-success .panel-heading {
  color: #000000b3;
  background-color: #86ce93;
}

.panel.is-success .panel-tabs a.is-active {
  border-bottom-color: #86ce93;
}

.panel.is-success .panel-block.is-active .panel-icon {
  color: #86ce93;
}

.panel.is-warning .panel-heading {
  color: #000000b3;
  background-color: #ffe08a;
}

.panel.is-warning .panel-tabs a.is-active {
  border-bottom-color: #ffe08a;
}

.panel.is-warning .panel-block.is-active .panel-icon {
  color: #ffe08a;
}

.panel.is-danger .panel-heading {
  color: #fff;
  background-color: #843e37;
}

.panel.is-danger .panel-tabs a.is-active {
  border-bottom-color: #843e37;
}

.panel.is-danger .panel-block.is-active .panel-icon {
  color: #843e37;
}

.panel-tabs:not(:last-child), .panel-block:not(:last-child) {
  border-bottom: 1px solid #ededed;
}

.panel-heading {
  color: #363636;
  background-color: #ededed;
  border-radius: 6px 6px 0 0;
  padding: .75em 1em;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
}

.panel-tabs {
  justify-content: center;
  align-items: flex-end;
  font-size: .875em;
  display: flex;
}

.panel-tabs a {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: -1px;
  padding: .5em;
}

.panel-tabs a.is-active {
  color: #363636;
  border-bottom-color: #4a4a4a;
}

.panel-list a {
  color: #4a4a4a;
}

.panel-list a:hover {
  color: #ad7d59;
}

.panel-block {
  color: #363636;
  justify-content: flex-start;
  align-items: center;
  padding: .5em .75em;
  display: flex;
}

.panel-block input[type="checkbox"] {
  margin-right: .75em;
}

.panel-block > .control {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

.panel-block.is-wrapped {
  flex-wrap: wrap;
}

.panel-block.is-active {
  color: #363636;
  border-left-color: #ad7d59;
}

.panel-block.is-active .panel-icon {
  color: #ad7d59;
}

.panel-block:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

a.panel-block, label.panel-block {
  cursor: pointer;
}

a.panel-block:hover, label.panel-block:hover {
  background-color: #fafcfb;
}

.panel-icon {
  height: 1em;
  text-align: center;
  vertical-align: top;
  width: 1em;
  color: #7a7a7a;
  margin-right: .75em;
  font-size: 14px;
  line-height: 1em;
  display: inline-block;
}

.panel-icon .fa {
  font-size: inherit;
  line-height: inherit;
}

.modal {
  z-index: 40;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  overflow: hidden;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: #0a0a0adb;
}

.modal-content, .modal-card {
  max-height: calc(100vh - 160px);
  width: 100%;
  margin: 0 20px;
  position: relative;
  overflow: auto;
}

@media screen and (min-width: 769px) {
  .modal-content, .modal-card {
    max-height: calc(100vh - 40px);
    width: 640px;
    margin: 0 auto;
  }
}

.modal-close {
  height: 40px;
  width: 40px;
  background: none;
  position: fixed;
  top: 20px;
  right: 20px;
}

.modal-card {
  max-height: calc(100vh - 40px);
  -ms-overflow-y: visible;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.modal-card-head, .modal-card-foot {
  background-color: #fafcfb;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  display: flex;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: #363636;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-top: 1px solid #dbdbdb;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: .5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px;
  overflow: auto;
}

.message {
  background-color: #fafcfb;
  border-radius: 4px;
  font-size: 1rem;
}

.message strong {
  color: currentColor;
}

.message a:not(.button):not(.tag):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.message.is-small {
  font-size: .75rem;
}

.message.is-medium {
  font-size: 1.25rem;
}

.message.is-large {
  font-size: 1.5rem;
}

.message.is-white {
  background-color: #fff;
}

.message.is-white .message-header {
  color: #0a0a0a;
  background-color: #fff;
}

.message.is-white .message-body {
  border-color: #fff;
}

.message.is-black {
  background-color: #fafafa;
}

.message.is-black .message-header {
  color: #fff;
  background-color: #0a0a0a;
}

.message.is-black .message-body {
  border-color: #0a0a0a;
}

.message.is-light {
  background-color: #fafafa;
}

.message.is-light .message-header {
  color: #000000b3;
  background-color: #f5f5f5;
}

.message.is-light .message-body {
  border-color: #f5f5f5;
}

.message.is-dark {
  background-color: #faf9fa;
}

.message.is-dark .message-header {
  color: #fff;
  background-color: #271f26;
}

.message.is-dark .message-body {
  border-color: #271f26;
}

.message.is-primary {
  background-color: #f1f8f8;
}

.message.is-primary .message-header {
  color: #fff;
  background-color: #264d4a;
}

.message.is-primary .message-body {
  color: #5baea8;
  border-color: #264d4a;
}

.message.is-link {
  background-color: #f8f4f1;
}

.message.is-link .message-header {
  color: #fff;
  background-color: #ad7d59;
}

.message.is-link .message-body {
  color: #8c6345;
  border-color: #ad7d59;
}

.message.is-info {
  background-color: #f8f4f2;
}

.message.is-info .message-header {
  color: #fff;
  background-color: #3f3024;
}

.message.is-info .message-body {
  color: #aa866a;
  border-color: #3f3024;
}

.message.is-success {
  background-color: #f0f9f2;
}

.message.is-success .message-header {
  color: #000000b3;
  background-color: #86ce93;
}

.message.is-success .message-body {
  color: #2b6936;
  border-color: #86ce93;
}

.message.is-warning {
  background-color: #fffaeb;
}

.message.is-warning .message-header {
  color: #000000b3;
  background-color: #ffe08a;
}

.message.is-warning .message-body {
  color: #946c00;
  border-color: #ffe08a;
}

.message.is-danger {
  background-color: #f9f1f1;
}

.message.is-danger .message-header {
  color: #fff;
  background-color: #843e37;
}

.message.is-danger .message-body {
  color: #b4554b;
  border-color: #843e37;
}

.message-header {
  color: #fff;
  background-color: #4a4a4a;
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: .75em 1em;
  font-weight: 700;
  line-height: 1.25;
  display: flex;
  position: relative;
}

.message-header .delete {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: .75em;
}

.message-header + .message-body {
  border-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.message-body {
  color: #4a4a4a;
  border: 0 solid #dbdbdb;
  border-left-width: 4px;
  border-radius: 4px;
  padding: 1.25em 1.5em;
}

.message-body code, .message-body pre {
  background-color: #fff;
}

.message-body pre code {
  background-color: #0000;
}

.box {
  color: #4a4a4a;
  background-color: #fff;
  border-radius: 6px;
  padding: 1.25rem;
  display: block;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

a.box:hover, a.box:focus {
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #ad7d59;
}

a.box:active {
  box-shadow: inset 0 1px 2px #0a0a0a33, 0 0 0 1px #ad7d59;
}

.button {
  color: #363636;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-width: 1px;
  border-color: #dbdbdb;
  justify-content: center;
  padding: calc(.5em - 1px) 1em;
}

.button strong {
  color: inherit;
}

.button .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
  height: 1.5em;
  width: 1.5em;
}

.button .icon:first-child:not(:last-child) {
  margin-left: calc(-.5em - 1px);
  margin-right: .25em;
}

.button .icon:last-child:not(:first-child) {
  margin-left: .25em;
  margin-right: calc(-.5em - 1px);
}

.button .icon:first-child:last-child {
  margin-left: calc(-.5em - 1px);
  margin-right: calc(-.5em - 1px);
}

.button:hover, .button.is-hovered {
  color: #363636;
  border-color: #b5b5b5;
}

.button:focus, .button.is-focused {
  color: #363636;
  border-color: #485fc7;
}

.button:focus:not(:active), .button.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #ad7d5940;
}

.button:active, .button.is-active {
  color: #363636;
  border-color: #4a4a4a;
}

.button.is-text {
  color: #4a4a4a;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.button.is-text:hover, .button.is-text.is-hovered, .button.is-text:focus, .button.is-text.is-focused {
  color: #363636;
  background-color: #fafcfb;
}

.button.is-text:active, .button.is-text.is-active {
  color: #363636;
  background-color: #eaf2ee;
}

.button.is-text[disabled], fieldset[disabled] .button.is-text {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.button.is-ghost {
  color: #ad7d59;
  background: none;
  border-color: #0000;
  text-decoration: none;
}

.button.is-ghost:hover, .button.is-ghost.is-hovered {
  color: #ad7d59;
  text-decoration: underline;
}

.button.is-white {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #0000;
}

.button.is-white:hover, .button.is-white.is-hovered {
  color: #0a0a0a;
  background-color: #f9f9f9;
  border-color: #0000;
}

.button.is-white:focus, .button.is-white.is-focused {
  color: #0a0a0a;
  border-color: #0000;
}

.button.is-white:focus:not(:active), .button.is-white.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.button.is-white:active, .button.is-white.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
  border-color: #0000;
}

.button.is-white[disabled], fieldset[disabled] .button.is-white {
  box-shadow: none;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-inverted {
  color: #fff;
  background-color: #0a0a0a;
}

.button.is-white.is-inverted:hover, .button.is-white.is-inverted.is-hovered {
  background-color: #000;
}

.button.is-white.is-inverted[disabled], fieldset[disabled] .button.is-white.is-inverted {
  box-shadow: none;
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0000;
}

.button.is-white.is-loading:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-white.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-outlined:hover, .button.is-white.is-outlined.is-hovered, .button.is-white.is-outlined:focus, .button.is-white.is-outlined.is-focused {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-outlined.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-outlined.is-loading:hover:after, .button.is-white.is-outlined.is-loading.is-hovered:after, .button.is-white.is-outlined.is-loading:focus:after, .button.is-white.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-white.is-outlined[disabled], fieldset[disabled] .button.is-white.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-inverted.is-outlined {
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-white.is-inverted.is-outlined:hover, .button.is-white.is-inverted.is-outlined.is-hovered, .button.is-white.is-inverted.is-outlined:focus, .button.is-white.is-inverted.is-outlined.is-focused {
  color: #fff;
  background-color: #0a0a0a;
}

.button.is-white.is-inverted.is-outlined.is-loading:hover:after, .button.is-white.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-white.is-inverted.is-outlined.is-loading:focus:after, .button.is-white.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-white.is-inverted.is-outlined {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-black {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0000;
}

.button.is-black:hover, .button.is-black.is-hovered {
  color: #fff;
  background-color: #040404;
  border-color: #0000;
}

.button.is-black:focus, .button.is-black.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-black:focus:not(:active), .button.is-black.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.button.is-black:active, .button.is-black.is-active {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.button.is-black[disabled], fieldset[disabled] .button.is-black {
  box-shadow: none;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.button.is-black.is-inverted {
  color: #0a0a0a;
  background-color: #fff;
}

.button.is-black.is-inverted:hover, .button.is-black.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-black.is-inverted[disabled], fieldset[disabled] .button.is-black.is-inverted {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #fff;
  border-color: #0000;
}

.button.is-black.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined {
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-black.is-outlined:hover, .button.is-black.is-outlined.is-hovered, .button.is-black.is-outlined:focus, .button.is-black.is-outlined.is-focused {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.button.is-black.is-outlined.is-loading:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-black.is-outlined.is-loading:hover:after, .button.is-black.is-outlined.is-loading.is-hovered:after, .button.is-black.is-outlined.is-loading:focus:after, .button.is-black.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined[disabled], fieldset[disabled] .button.is-black.is-outlined {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-black.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-black.is-inverted.is-outlined:hover, .button.is-black.is-inverted.is-outlined.is-hovered, .button.is-black.is-inverted.is-outlined:focus, .button.is-black.is-inverted.is-outlined.is-focused {
  color: #0a0a0a;
  background-color: #fff;
}

.button.is-black.is-inverted.is-outlined.is-loading:hover:after, .button.is-black.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-black.is-inverted.is-outlined.is-loading:focus:after, .button.is-black.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-black.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-black.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #0000;
}

.button.is-light:hover, .button.is-light.is-hovered {
  color: #000000b3;
  background-color: #eee;
  border-color: #0000;
}

.button.is-light:focus, .button.is-light.is-focused {
  color: #000000b3;
  border-color: #0000;
}

.button.is-light:focus:not(:active), .button.is-light.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.button.is-light:active, .button.is-light.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
  border-color: #0000;
}

.button.is-light[disabled], fieldset[disabled] .button.is-light {
  box-shadow: none;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.button.is-light.is-inverted {
  color: #f5f5f5;
  background-color: #000000b3;
}

.button.is-light.is-inverted:hover, .button.is-light.is-inverted.is-hovered {
  background-color: #000000b3;
}

.button.is-light.is-inverted[disabled], fieldset[disabled] .button.is-light.is-inverted {
  box-shadow: none;
  color: #f5f5f5;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-light.is-loading:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-light.is-outlined {
  color: #f5f5f5;
  background-color: #0000;
  border-color: #f5f5f5;
}

.button.is-light.is-outlined:hover, .button.is-light.is-outlined.is-hovered, .button.is-light.is-outlined:focus, .button.is-light.is-outlined.is-focused {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.button.is-light.is-outlined.is-loading:after {
  border-color: #0000 #0000 #f5f5f5 #f5f5f5 !important;
}

.button.is-light.is-outlined.is-loading:hover:after, .button.is-light.is-outlined.is-loading.is-hovered:after, .button.is-light.is-outlined.is-loading:focus:after, .button.is-light.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-light.is-outlined[disabled], fieldset[disabled] .button.is-light.is-outlined {
  box-shadow: none;
  color: #f5f5f5;
  background-color: #0000;
  border-color: #f5f5f5;
}

.button.is-light.is-inverted.is-outlined {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-light.is-inverted.is-outlined:hover, .button.is-light.is-inverted.is-outlined.is-hovered, .button.is-light.is-inverted.is-outlined:focus, .button.is-light.is-inverted.is-outlined.is-focused {
  color: #f5f5f5;
  background-color: #000000b3;
}

.button.is-light.is-inverted.is-outlined.is-loading:hover:after, .button.is-light.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-light.is-inverted.is-outlined.is-loading:focus:after, .button.is-light.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #f5f5f5 #f5f5f5 !important;
}

.button.is-light.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-light.is-inverted.is-outlined {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-dark {
  color: #fff;
  background-color: #271f26;
  border-color: #0000;
}

.button.is-dark:hover, .button.is-dark.is-hovered {
  color: #fff;
  background-color: #20191f;
  border-color: #0000;
}

.button.is-dark:focus, .button.is-dark.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-dark:focus:not(:active), .button.is-dark.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #271f2640;
}

.button.is-dark:active, .button.is-dark.is-active {
  color: #fff;
  background-color: #191418;
  border-color: #0000;
}

.button.is-dark[disabled], fieldset[disabled] .button.is-dark {
  box-shadow: none;
  background-color: #271f26;
  border-color: #271f26;
}

.button.is-dark.is-inverted {
  color: #271f26;
  background-color: #fff;
}

.button.is-dark.is-inverted:hover, .button.is-dark.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-dark.is-inverted[disabled], fieldset[disabled] .button.is-dark.is-inverted {
  box-shadow: none;
  color: #271f26;
  background-color: #fff;
  border-color: #0000;
}

.button.is-dark.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-dark.is-outlined {
  color: #271f26;
  background-color: #0000;
  border-color: #271f26;
}

.button.is-dark.is-outlined:hover, .button.is-dark.is-outlined.is-hovered, .button.is-dark.is-outlined:focus, .button.is-dark.is-outlined.is-focused {
  color: #fff;
  background-color: #271f26;
  border-color: #271f26;
}

.button.is-dark.is-outlined.is-loading:after {
  border-color: #0000 #0000 #271f26 #271f26 !important;
}

.button.is-dark.is-outlined.is-loading:hover:after, .button.is-dark.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-outlined.is-loading:focus:after, .button.is-dark.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-dark.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-outlined {
  box-shadow: none;
  color: #271f26;
  background-color: #0000;
  border-color: #271f26;
}

.button.is-dark.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-dark.is-inverted.is-outlined:hover, .button.is-dark.is-inverted.is-outlined.is-hovered, .button.is-dark.is-inverted.is-outlined:focus, .button.is-dark.is-inverted.is-outlined.is-focused {
  color: #271f26;
  background-color: #fff;
}

.button.is-dark.is-inverted.is-outlined.is-loading:hover:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-inverted.is-outlined.is-loading:focus:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #271f26 #271f26 !important;
}

.button.is-dark.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary {
  color: #fff;
  background-color: #264d4a;
  border-color: #0000;
}

.button.is-primary:hover, .button.is-primary.is-hovered {
  color: #fff;
  background-color: #224442;
  border-color: #0000;
}

.button.is-primary:focus, .button.is-primary.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-primary:focus:not(:active), .button.is-primary.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #264d4a40;
}

.button.is-primary:active, .button.is-primary.is-active {
  color: #fff;
  background-color: #1e3c3a;
  border-color: #0000;
}

.button.is-primary[disabled], fieldset[disabled] .button.is-primary {
  box-shadow: none;
  background-color: #264d4a;
  border-color: #264d4a;
}

.button.is-primary.is-inverted {
  color: #264d4a;
  background-color: #fff;
}

.button.is-primary.is-inverted:hover, .button.is-primary.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-primary.is-inverted[disabled], fieldset[disabled] .button.is-primary.is-inverted {
  box-shadow: none;
  color: #264d4a;
  background-color: #fff;
  border-color: #0000;
}

.button.is-primary.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined {
  color: #264d4a;
  background-color: #0000;
  border-color: #264d4a;
}

.button.is-primary.is-outlined:hover, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined.is-focused {
  color: #fff;
  background-color: #264d4a;
  border-color: #264d4a;
}

.button.is-primary.is-outlined.is-loading:after {
  border-color: #0000 #0000 #264d4a #264d4a !important;
}

.button.is-primary.is-outlined.is-loading:hover:after, .button.is-primary.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-outlined.is-loading:focus:after, .button.is-primary.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-outlined {
  box-shadow: none;
  color: #264d4a;
  background-color: #0000;
  border-color: #264d4a;
}

.button.is-primary.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-inverted.is-outlined:hover, .button.is-primary.is-inverted.is-outlined.is-hovered, .button.is-primary.is-inverted.is-outlined:focus, .button.is-primary.is-inverted.is-outlined.is-focused {
  color: #264d4a;
  background-color: #fff;
}

.button.is-primary.is-inverted.is-outlined.is-loading:hover:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-inverted.is-outlined.is-loading:focus:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #264d4a #264d4a !important;
}

.button.is-primary.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-light {
  color: #5baea8;
  background-color: #f1f8f8;
}

.button.is-primary.is-light:hover, .button.is-primary.is-light.is-hovered {
  color: #5baea8;
  background-color: #e9f4f3;
  border-color: #0000;
}

.button.is-primary.is-light:active, .button.is-primary.is-light.is-active {
  color: #5baea8;
  background-color: #e0f0ef;
  border-color: #0000;
}

.button.is-link {
  color: #fff;
  background-color: #ad7d59;
  border-color: #0000;
}

.button.is-link:hover, .button.is-link.is-hovered {
  color: #fff;
  background-color: #a77752;
  border-color: #0000;
}

.button.is-link:focus, .button.is-link.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-link:focus:not(:active), .button.is-link.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #ad7d5940;
}

.button.is-link:active, .button.is-link.is-active {
  color: #fff;
  background-color: #9e714e;
  border-color: #0000;
}

.button.is-link[disabled], fieldset[disabled] .button.is-link {
  box-shadow: none;
  background-color: #ad7d59;
  border-color: #ad7d59;
}

.button.is-link.is-inverted {
  color: #ad7d59;
  background-color: #fff;
}

.button.is-link.is-inverted:hover, .button.is-link.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-link.is-inverted[disabled], fieldset[disabled] .button.is-link.is-inverted {
  box-shadow: none;
  color: #ad7d59;
  background-color: #fff;
  border-color: #0000;
}

.button.is-link.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined {
  color: #ad7d59;
  background-color: #0000;
  border-color: #ad7d59;
}

.button.is-link.is-outlined:hover, .button.is-link.is-outlined.is-hovered, .button.is-link.is-outlined:focus, .button.is-link.is-outlined.is-focused {
  color: #fff;
  background-color: #ad7d59;
  border-color: #ad7d59;
}

.button.is-link.is-outlined.is-loading:after {
  border-color: #0000 #0000 #ad7d59 #ad7d59 !important;
}

.button.is-link.is-outlined.is-loading:hover:after, .button.is-link.is-outlined.is-loading.is-hovered:after, .button.is-link.is-outlined.is-loading:focus:after, .button.is-link.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined[disabled], fieldset[disabled] .button.is-link.is-outlined {
  box-shadow: none;
  color: #ad7d59;
  background-color: #0000;
  border-color: #ad7d59;
}

.button.is-link.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-inverted.is-outlined:hover, .button.is-link.is-inverted.is-outlined.is-hovered, .button.is-link.is-inverted.is-outlined:focus, .button.is-link.is-inverted.is-outlined.is-focused {
  color: #ad7d59;
  background-color: #fff;
}

.button.is-link.is-inverted.is-outlined.is-loading:hover:after, .button.is-link.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-link.is-inverted.is-outlined.is-loading:focus:after, .button.is-link.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #ad7d59 #ad7d59 !important;
}

.button.is-link.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-link.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-light {
  color: #8c6345;
  background-color: #f8f4f1;
}

.button.is-link.is-light:hover, .button.is-link.is-light.is-hovered {
  color: #8c6345;
  background-color: #f4eee9;
  border-color: #0000;
}

.button.is-link.is-light:active, .button.is-link.is-light.is-active {
  color: #8c6345;
  background-color: #f0e7e0;
  border-color: #0000;
}

.button.is-info {
  color: #fff;
  background-color: #3f3024;
  border-color: #0000;
}

.button.is-info:hover, .button.is-info.is-hovered {
  color: #fff;
  background-color: #372a1f;
  border-color: #0000;
}

.button.is-info:focus, .button.is-info.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-info:focus:not(:active), .button.is-info.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #3f302440;
}

.button.is-info:active, .button.is-info.is-active {
  color: #fff;
  background-color: #2f241b;
  border-color: #0000;
}

.button.is-info[disabled], fieldset[disabled] .button.is-info {
  box-shadow: none;
  background-color: #3f3024;
  border-color: #3f3024;
}

.button.is-info.is-inverted {
  color: #3f3024;
  background-color: #fff;
}

.button.is-info.is-inverted:hover, .button.is-info.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-info.is-inverted[disabled], fieldset[disabled] .button.is-info.is-inverted {
  box-shadow: none;
  color: #3f3024;
  background-color: #fff;
  border-color: #0000;
}

.button.is-info.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined {
  color: #3f3024;
  background-color: #0000;
  border-color: #3f3024;
}

.button.is-info.is-outlined:hover, .button.is-info.is-outlined.is-hovered, .button.is-info.is-outlined:focus, .button.is-info.is-outlined.is-focused {
  color: #fff;
  background-color: #3f3024;
  border-color: #3f3024;
}

.button.is-info.is-outlined.is-loading:after {
  border-color: #0000 #0000 #3f3024 #3f3024 !important;
}

.button.is-info.is-outlined.is-loading:hover:after, .button.is-info.is-outlined.is-loading.is-hovered:after, .button.is-info.is-outlined.is-loading:focus:after, .button.is-info.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined[disabled], fieldset[disabled] .button.is-info.is-outlined {
  box-shadow: none;
  color: #3f3024;
  background-color: #0000;
  border-color: #3f3024;
}

.button.is-info.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-inverted.is-outlined:hover, .button.is-info.is-inverted.is-outlined.is-hovered, .button.is-info.is-inverted.is-outlined:focus, .button.is-info.is-inverted.is-outlined.is-focused {
  color: #3f3024;
  background-color: #fff;
}

.button.is-info.is-inverted.is-outlined.is-loading:hover:after, .button.is-info.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-info.is-inverted.is-outlined.is-loading:focus:after, .button.is-info.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #3f3024 #3f3024 !important;
}

.button.is-info.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-info.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-light {
  color: #aa866a;
  background-color: #f8f4f2;
}

.button.is-info.is-light:hover, .button.is-info.is-light.is-hovered {
  color: #aa866a;
  background-color: #f3eeea;
  border-color: #0000;
}

.button.is-info.is-light:active, .button.is-info.is-light.is-active {
  color: #aa866a;
  background-color: #eee7e2;
  border-color: #0000;
}

.button.is-success {
  color: #000000b3;
  background-color: #86ce93;
  border-color: #0000;
}

.button.is-success:hover, .button.is-success.is-hovered {
  color: #000000b3;
  background-color: #7dca8b;
  border-color: #0000;
}

.button.is-success:focus, .button.is-success.is-focused {
  color: #000000b3;
  border-color: #0000;
}

.button.is-success:focus:not(:active), .button.is-success.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #86ce9340;
}

.button.is-success:active, .button.is-success.is-active {
  color: #000000b3;
  background-color: #74c783;
  border-color: #0000;
}

.button.is-success[disabled], fieldset[disabled] .button.is-success {
  box-shadow: none;
  background-color: #86ce93;
  border-color: #86ce93;
}

.button.is-success.is-inverted {
  color: #86ce93;
  background-color: #000000b3;
}

.button.is-success.is-inverted:hover, .button.is-success.is-inverted.is-hovered {
  background-color: #000000b3;
}

.button.is-success.is-inverted[disabled], fieldset[disabled] .button.is-success.is-inverted {
  box-shadow: none;
  color: #86ce93;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-success.is-loading:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-success.is-outlined {
  color: #86ce93;
  background-color: #0000;
  border-color: #86ce93;
}

.button.is-success.is-outlined:hover, .button.is-success.is-outlined.is-hovered, .button.is-success.is-outlined:focus, .button.is-success.is-outlined.is-focused {
  color: #000000b3;
  background-color: #86ce93;
  border-color: #86ce93;
}

.button.is-success.is-outlined.is-loading:after {
  border-color: #0000 #0000 #86ce93 #86ce93 !important;
}

.button.is-success.is-outlined.is-loading:hover:after, .button.is-success.is-outlined.is-loading.is-hovered:after, .button.is-success.is-outlined.is-loading:focus:after, .button.is-success.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-success.is-outlined[disabled], fieldset[disabled] .button.is-success.is-outlined {
  box-shadow: none;
  color: #86ce93;
  background-color: #0000;
  border-color: #86ce93;
}

.button.is-success.is-inverted.is-outlined {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-success.is-inverted.is-outlined:hover, .button.is-success.is-inverted.is-outlined.is-hovered, .button.is-success.is-inverted.is-outlined:focus, .button.is-success.is-inverted.is-outlined.is-focused {
  color: #86ce93;
  background-color: #000000b3;
}

.button.is-success.is-inverted.is-outlined.is-loading:hover:after, .button.is-success.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-success.is-inverted.is-outlined.is-loading:focus:after, .button.is-success.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #86ce93 #86ce93 !important;
}

.button.is-success.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-success.is-inverted.is-outlined {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-success.is-light {
  color: #2b6936;
  background-color: #f0f9f2;
}

.button.is-success.is-light:hover, .button.is-success.is-light.is-hovered {
  color: #2b6936;
  background-color: #e7f5ea;
  border-color: #0000;
}

.button.is-success.is-light:active, .button.is-success.is-light.is-active {
  color: #2b6936;
  background-color: #def2e2;
  border-color: #0000;
}

.button.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #0000;
}

.button.is-warning:hover, .button.is-warning.is-hovered {
  color: #000000b3;
  background-color: #ffdc7d;
  border-color: #0000;
}

.button.is-warning:focus, .button.is-warning.is-focused {
  color: #000000b3;
  border-color: #0000;
}

.button.is-warning:focus:not(:active), .button.is-warning.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #ffe08a40;
}

.button.is-warning:active, .button.is-warning.is-active {
  color: #000000b3;
  background-color: #ffd970;
  border-color: #0000;
}

.button.is-warning[disabled], fieldset[disabled] .button.is-warning {
  box-shadow: none;
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.button.is-warning.is-inverted {
  color: #ffe08a;
  background-color: #000000b3;
}

.button.is-warning.is-inverted:hover, .button.is-warning.is-inverted.is-hovered {
  background-color: #000000b3;
}

.button.is-warning.is-inverted[disabled], fieldset[disabled] .button.is-warning.is-inverted {
  box-shadow: none;
  color: #ffe08a;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-warning.is-loading:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-warning.is-outlined {
  color: #ffe08a;
  background-color: #0000;
  border-color: #ffe08a;
}

.button.is-warning.is-outlined:hover, .button.is-warning.is-outlined.is-hovered, .button.is-warning.is-outlined:focus, .button.is-warning.is-outlined.is-focused {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.button.is-warning.is-outlined.is-loading:after {
  border-color: #0000 #0000 #ffe08a #ffe08a !important;
}

.button.is-warning.is-outlined.is-loading:hover:after, .button.is-warning.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-outlined.is-loading:focus:after, .button.is-warning.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-warning.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-outlined {
  box-shadow: none;
  color: #ffe08a;
  background-color: #0000;
  border-color: #ffe08a;
}

.button.is-warning.is-inverted.is-outlined {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-warning.is-inverted.is-outlined:hover, .button.is-warning.is-inverted.is-outlined.is-hovered, .button.is-warning.is-inverted.is-outlined:focus, .button.is-warning.is-inverted.is-outlined.is-focused {
  color: #ffe08a;
  background-color: #000000b3;
}

.button.is-warning.is-inverted.is-outlined.is-loading:hover:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-inverted.is-outlined.is-loading:focus:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #ffe08a #ffe08a !important;
}

.button.is-warning.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-inverted.is-outlined {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-warning.is-light {
  color: #946c00;
  background-color: #fffaeb;
}

.button.is-warning.is-light:hover, .button.is-warning.is-light.is-hovered {
  color: #946c00;
  background-color: #fff6de;
  border-color: #0000;
}

.button.is-warning.is-light:active, .button.is-warning.is-light.is-active {
  color: #946c00;
  background-color: #fff3d1;
  border-color: #0000;
}

.button.is-danger {
  color: #fff;
  background-color: #843e37;
  border-color: #0000;
}

.button.is-danger:hover, .button.is-danger.is-hovered {
  color: #fff;
  background-color: #7b3a33;
  border-color: #0000;
}

.button.is-danger:focus, .button.is-danger.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-danger:focus:not(:active), .button.is-danger.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #843e3740;
}

.button.is-danger:active, .button.is-danger.is-active {
  color: #fff;
  background-color: #723630;
  border-color: #0000;
}

.button.is-danger[disabled], fieldset[disabled] .button.is-danger {
  box-shadow: none;
  background-color: #843e37;
  border-color: #843e37;
}

.button.is-danger.is-inverted {
  color: #843e37;
  background-color: #fff;
}

.button.is-danger.is-inverted:hover, .button.is-danger.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-danger.is-inverted[disabled], fieldset[disabled] .button.is-danger.is-inverted {
  box-shadow: none;
  color: #843e37;
  background-color: #fff;
  border-color: #0000;
}

.button.is-danger.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined {
  color: #843e37;
  background-color: #0000;
  border-color: #843e37;
}

.button.is-danger.is-outlined:hover, .button.is-danger.is-outlined.is-hovered, .button.is-danger.is-outlined:focus, .button.is-danger.is-outlined.is-focused {
  color: #fff;
  background-color: #843e37;
  border-color: #843e37;
}

.button.is-danger.is-outlined.is-loading:after {
  border-color: #0000 #0000 #843e37 #843e37 !important;
}

.button.is-danger.is-outlined.is-loading:hover:after, .button.is-danger.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-outlined.is-loading:focus:after, .button.is-danger.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-outlined {
  box-shadow: none;
  color: #843e37;
  background-color: #0000;
  border-color: #843e37;
}

.button.is-danger.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-inverted.is-outlined:hover, .button.is-danger.is-inverted.is-outlined.is-hovered, .button.is-danger.is-inverted.is-outlined:focus, .button.is-danger.is-inverted.is-outlined.is-focused {
  color: #843e37;
  background-color: #fff;
}

.button.is-danger.is-inverted.is-outlined.is-loading:hover:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-inverted.is-outlined.is-loading:focus:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #843e37 #843e37 !important;
}

.button.is-danger.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-light {
  color: #b4554b;
  background-color: #f9f1f1;
}

.button.is-danger.is-light:hover, .button.is-danger.is-light.is-hovered {
  color: #b4554b;
  background-color: #f5e9e8;
  border-color: #0000;
}

.button.is-danger.is-light:active, .button.is-danger.is-light.is-active {
  color: #b4554b;
  background-color: #f2e0df;
  border-color: #0000;
}

.button.is-small {
  font-size: .75rem;
}

.button.is-small:not(.is-rounded) {
  border-radius: 2px;
}

.button.is-normal {
  font-size: 1rem;
}

.button.is-medium {
  font-size: 1.25rem;
}

.button.is-large {
  font-size: 1.5rem;
}

.button[disabled], fieldset[disabled] .button {
  box-shadow: none;
  opacity: .5;
  background-color: #fff;
  border-color: #dbdbdb;
}

.button.is-fullwidth {
  width: 100%;
  display: flex;
}

.button.is-loading {
  pointer-events: none;
  color: #0000 !important;
}

.button.is-loading:after {
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
  position: absolute !important;
}

.button.is-static {
  color: #7a7a7a;
  box-shadow: none;
  pointer-events: none;
  background-color: #f5f5f5;
  border-color: #dbdbdb;
}

.button.is-rounded {
  border-radius: 9999px;
  padding-left: 1.25em;
  padding-right: 1.25em;
}

.buttons {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.buttons .button {
  margin-bottom: .5rem;
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
  margin-right: .5rem;
}

.buttons:last-child {
  margin-bottom: -.5rem;
}

.buttons:not(:last-child) {
  margin-bottom: 1rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large) {
  font-size: .75rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large):not(.is-rounded) {
  border-radius: 2px;
}

.buttons.are-medium .button:not(.is-small):not(.is-normal):not(.is-large) {
  font-size: 1.25rem;
}

.buttons.are-large .button:not(.is-small):not(.is-normal):not(.is-medium) {
  font-size: 1.5rem;
}

.buttons.has-addons .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttons.has-addons .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.buttons.has-addons .button:last-child {
  margin-right: 0;
}

.buttons.has-addons .button:hover, .buttons.has-addons .button.is-hovered {
  z-index: 2;
}

.buttons.has-addons .button:focus, .buttons.has-addons .button.is-focused, .buttons.has-addons .button:active, .buttons.has-addons .button.is-active, .buttons.has-addons .button.is-selected {
  z-index: 3;
}

.buttons.has-addons .button:focus:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .button.is-active:hover, .buttons.has-addons .button.is-selected:hover {
  z-index: 4;
}

.buttons.has-addons .button.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.buttons.is-centered {
  justify-content: center;
}

.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

.buttons.is-right {
  justify-content: flex-end;
}

.buttons.is-right:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

@media screen and (max-width: 768px) {
  .button.is-responsive.is-small {
    font-size: .5625rem;
  }

  .button.is-responsive, .button.is-responsive.is-normal {
    font-size: .65625rem;
  }

  .button.is-responsive.is-medium {
    font-size: .75rem;
  }

  .button.is-responsive.is-large {
    font-size: 1rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .button.is-responsive.is-small {
    font-size: .65625rem;
  }

  .button.is-responsive, .button.is-responsive.is-normal {
    font-size: .75rem;
  }

  .button.is-responsive.is-medium {
    font-size: 1rem;
  }

  .button.is-responsive.is-large {
    font-size: 1.25rem;
  }
}

.container {
  width: auto;
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
}

.container.is-fluid {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  max-width: none !important;
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (max-width: 1215px) {
  .container.is-widescreen:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (max-width: 1407px) {
  .container.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

@media screen and (min-width: 1216px) {
  .container:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (min-width: 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

.content li + li {
  margin-top: .25em;
}

.content p:not(:last-child), .content dl:not(:last-child), .content ol:not(:last-child), .content ul:not(:last-child), .content blockquote:not(:last-child), .content pre:not(:last-child), .content table:not(:last-child) {
  margin-bottom: 1em;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
}

.content h1 {
  margin-bottom: .5em;
  font-size: 2em;
}

.content h1:not(:first-child) {
  margin-top: 1em;
}

.content h2 {
  margin-bottom: .5714em;
  font-size: 1.75em;
}

.content h2:not(:first-child) {
  margin-top: 1.1428em;
}

.content h3 {
  margin-bottom: .6666em;
  font-size: 1.5em;
}

.content h3:not(:first-child) {
  margin-top: 1.3333em;
}

.content h4 {
  margin-bottom: .8em;
  font-size: 1.25em;
}

.content h5 {
  margin-bottom: .8888em;
  font-size: 1.125em;
}

.content h6 {
  margin-bottom: 1em;
  font-size: 1em;
}

.content blockquote {
  background-color: #fafcfb;
  border-left: 5px solid #dbdbdb;
  padding: 1.25em 1.5em;
}

.content ol {
  margin-top: 1em;
  margin-left: 2em;
  list-style-position: outside;
}

.content ol:not([type]) {
  list-style-type: decimal;
}

.content ol:not([type]).is-lower-alpha {
  list-style-type: lower-alpha;
}

.content ol:not([type]).is-lower-roman {
  list-style-type: lower-roman;
}

.content ol:not([type]).is-upper-alpha {
  list-style-type: upper-alpha;
}

.content ol:not([type]).is-upper-roman {
  list-style-type: upper-roman;
}

.content ul {
  margin-top: 1em;
  margin-left: 2em;
  list-style: disc;
}

.content ul ul {
  margin-top: .5em;
  list-style-type: circle;
}

.content ul ul ul {
  list-style-type: square;
}

.content dd {
  margin-left: 2em;
}

.content figure {
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
}

.content figure:not(:first-child) {
  margin-top: 2em;
}

.content figure:not(:last-child) {
  margin-bottom: 2em;
}

.content figure img {
  display: inline-block;
}

.content figure figcaption {
  font-style: italic;
}

.content pre {
  -webkit-overflow-scrolling: touch;
  white-space: pre;
  word-wrap: normal;
  padding: 1.25em 1.5em;
  overflow-x: auto;
}

.content sup, .content sub {
  font-size: 75%;
}

.content table {
  width: 100%;
}

.content table td, .content table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.content table th {
  color: #363636;
}

.content table th:not([align]) {
  text-align: inherit;
}

.content table thead td, .content table thead th {
  color: #363636;
  border-width: 0 0 2px;
}

.content table tfoot td, .content table tfoot th {
  color: #363636;
  border-width: 2px 0 0;
}

.content table tbody tr:last-child td, .content table tbody tr:last-child th {
  border-bottom-width: 0;
}

.content .tabs li + li {
  margin-top: 0;
}

.content.is-small {
  font-size: .75rem;
}

.content.is-normal {
  font-size: 1rem;
}

.content.is-medium {
  font-size: 1.25rem;
}

.content.is-large {
  font-size: 1.5rem;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.icon.is-small {
  height: 1rem;
  width: 1rem;
}

.icon.is-medium {
  height: 2rem;
  width: 2rem;
}

.icon.is-large {
  height: 3rem;
  width: 3rem;
}

.icon-text {
  color: inherit;
  vertical-align: top;
  flex-wrap: wrap;
  align-items: flex-start;
  line-height: 1.5rem;
  display: inline-flex;
}

.icon-text .icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.icon-text .icon:not(:last-child) {
  margin-right: .25em;
}

.icon-text .icon:not(:first-child) {
  margin-left: .25em;
}

div.icon-text {
  display: flex;
}

.image {
  display: block;
  position: relative;
}

.image img {
  height: auto;
  width: 100%;
  display: block;
}

.image img.is-rounded {
  border-radius: 9999px;
}

.image.is-fullwidth {
  width: 100%;
}

.image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio {
  height: 100%;
  width: 100%;
}

.image.is-square, .image.is-1by1 {
  padding-top: 100%;
}

.image.is-5by4 {
  padding-top: 80%;
}

.image.is-4by3 {
  padding-top: 75%;
}

.image.is-3by2 {
  padding-top: 66.6666%;
}

.image.is-5by3 {
  padding-top: 60%;
}

.image.is-16by9 {
  padding-top: 56.25%;
}

.image.is-2by1 {
  padding-top: 50%;
}

.image.is-3by1 {
  padding-top: 33.3333%;
}

.image.is-4by5 {
  padding-top: 125%;
}

.image.is-3by4 {
  padding-top: 133.333%;
}

.image.is-2by3 {
  padding-top: 150%;
}

.image.is-3by5 {
  padding-top: 166.667%;
}

.image.is-9by16 {
  padding-top: 177.778%;
}

.image.is-1by2 {
  padding-top: 200%;
}

.image.is-1by3 {
  padding-top: 300%;
}

.image.is-16x16 {
  height: 16px;
  width: 16px;
}

.image.is-24x24 {
  height: 24px;
  width: 24px;
}

.image.is-32x32 {
  height: 32px;
  width: 32px;
}

.image.is-48x48 {
  height: 48px;
  width: 48px;
}

.image.is-64x64 {
  height: 64px;
  width: 64px;
}

.image.is-96x96 {
  height: 96px;
  width: 96px;
}

.image.is-128x128 {
  height: 128px;
  width: 128px;
}

.media {
  text-align: inherit;
  align-items: flex-start;
  display: flex;
}

.media .content:not(:last-child) {
  margin-bottom: .75rem;
}

.media .media {
  border-top: 1px solid #dbdbdb80;
  padding-top: .75rem;
  display: flex;
}

.media .media .content:not(:last-child), .media .media .control:not(:last-child) {
  margin-bottom: .5rem;
}

.media .media .media {
  padding-top: .5rem;
}

.media .media .media + .media {
  margin-top: .5rem;
}

.media + .media {
  border-top: 1px solid #dbdbdb80;
  margin-top: 1rem;
  padding-top: 1rem;
}

.media.is-large + .media {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.media-left, .media-right {
  flex: none;
}

.media-left {
  margin-right: 1rem;
}

.media-right {
  margin-left: 1rem;
}

.media-content {
  text-align: inherit;
  flex: auto;
}

@media screen and (max-width: 768px) {
  .media-content {
    overflow-x: auto;
  }
}

.tags {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tags .tag {
  margin-bottom: .5rem;
}

.tags .tag:not(:last-child) {
  margin-right: .5rem;
}

.tags:last-child {
  margin-bottom: -.5rem;
}

.tags:not(:last-child) {
  margin-bottom: 1rem;
}

.tags.are-medium .tag:not(.is-normal):not(.is-large) {
  font-size: 1rem;
}

.tags.are-large .tag:not(.is-normal):not(.is-medium) {
  font-size: 1.25rem;
}

.tags.is-centered {
  justify-content: center;
}

.tags.is-centered .tag {
  margin-left: .25rem;
  margin-right: .25rem;
}

.tags.is-right {
  justify-content: flex-end;
}

.tags.is-right .tag:not(:first-child) {
  margin-left: .5rem;
}

.tags.is-right .tag:not(:last-child), .tags.has-addons .tag {
  margin-right: 0;
}

.tags.has-addons .tag:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.tags.has-addons .tag:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tag:not(body) {
  color: #4a4a4a;
  height: 2em;
  white-space: nowrap;
  background-color: #fafcfb;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding-left: .75em;
  padding-right: .75em;
  font-size: .75rem;
  line-height: 1.5;
  display: inline-flex;
}

.tag:not(body) .delete {
  margin-left: .25rem;
  margin-right: -.375rem;
}

.tag:not(body).is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.tag:not(body).is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.tag:not(body).is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.tag:not(body).is-dark {
  color: #fff;
  background-color: #271f26;
}

.tag:not(body).is-primary {
  color: #fff;
  background-color: #264d4a;
}

.tag:not(body).is-primary.is-light {
  color: #5baea8;
  background-color: #f1f8f8;
}

.tag:not(body).is-link {
  color: #fff;
  background-color: #ad7d59;
}

.tag:not(body).is-link.is-light {
  color: #8c6345;
  background-color: #f8f4f1;
}

.tag:not(body).is-info {
  color: #fff;
  background-color: #3f3024;
}

.tag:not(body).is-info.is-light {
  color: #aa866a;
  background-color: #f8f4f2;
}

.tag:not(body).is-success {
  color: #000000b3;
  background-color: #86ce93;
}

.tag:not(body).is-success.is-light {
  color: #2b6936;
  background-color: #f0f9f2;
}

.tag:not(body).is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.tag:not(body).is-warning.is-light {
  color: #946c00;
  background-color: #fffaeb;
}

.tag:not(body).is-danger {
  color: #fff;
  background-color: #843e37;
}

.tag:not(body).is-danger.is-light {
  color: #b4554b;
  background-color: #f9f1f1;
}

.tag:not(body).is-normal {
  font-size: .75rem;
}

.tag:not(body).is-medium {
  font-size: 1rem;
}

.tag:not(body).is-large {
  font-size: 1.25rem;
}

.tag:not(body) .icon:first-child:not(:last-child) {
  margin-left: -.375em;
  margin-right: .1875em;
}

.tag:not(body) .icon:last-child:not(:first-child) {
  margin-left: .1875em;
  margin-right: -.375em;
}

.tag:not(body) .icon:first-child:last-child {
  margin-left: -.375em;
  margin-right: -.375em;
}

.tag:not(body).is-delete {
  width: 2em;
  margin-left: 1px;
  padding: 0;
  position: relative;
}

.tag:not(body).is-delete:before, .tag:not(body).is-delete:after {
  content: "";
  transform-origin: center;
  background-color: currentColor;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.tag:not(body).is-delete:before {
  height: 1px;
  width: 50%;
}

.tag:not(body).is-delete:after {
  height: 50%;
  width: 1px;
}

.tag:not(body).is-delete:hover, .tag:not(body).is-delete:focus {
  background-color: #eaf2ee;
}

.tag:not(body).is-delete:active {
  background-color: #dae9e2;
}

.tag:not(body).is-rounded {
  border-radius: 9999px;
}

a.tag:hover {
  text-decoration: underline;
}

.notification {
  background-color: #fafcfb;
  border-radius: 4px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
}

.notification a:not(.button):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.notification strong {
  color: currentColor;
}

.notification code, .notification pre {
  background: #fff;
}

.notification pre code {
  background: none;
}

.notification > .delete {
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.notification .title, .notification .subtitle, .notification .content {
  color: currentColor;
}

.notification.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.notification.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.notification.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.notification.is-dark {
  color: #fff;
  background-color: #271f26;
}

.notification.is-primary {
  color: #fff;
  background-color: #264d4a;
}

.notification.is-primary.is-light {
  color: #5baea8;
  background-color: #f1f8f8;
}

.notification.is-link {
  color: #fff;
  background-color: #ad7d59;
}

.notification.is-link.is-light {
  color: #8c6345;
  background-color: #f8f4f1;
}

.notification.is-info {
  color: #fff;
  background-color: #3f3024;
}

.notification.is-info.is-light {
  color: #aa866a;
  background-color: #f8f4f2;
}

.notification.is-success {
  color: #000000b3;
  background-color: #86ce93;
}

.notification.is-success.is-light {
  color: #2b6936;
  background-color: #f0f9f2;
}

.notification.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.notification.is-warning.is-light {
  color: #946c00;
  background-color: #fffaeb;
}

.notification.is-danger {
  color: #fff;
  background-color: #843e37;
}

.notification.is-danger.is-light {
  color: #b4554b;
  background-color: #f9f1f1;
}

.heading {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 11px;
  display: block;
}

.number {
  height: 2em;
  min-width: 2.5em;
  text-align: center;
  vertical-align: top;
  background-color: #fafcfb;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  padding: .25rem .5rem;
  font-size: 1.25rem;
  display: inline-flex;
}

.title, .subtitle {
  word-break: break-word;
}

.title em, .title span, .subtitle em, .subtitle span {
  font-weight: inherit;
}

.title sub, .subtitle sub, .title sup, .subtitle sup {
  font-size: .75em;
}

.title .tag, .subtitle .tag {
  vertical-align: middle;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}

.title strong {
  color: inherit;
  font-weight: inherit;
}

.title:not(.is-spaced) + .subtitle {
  margin-top: -1.25rem;
}

.title.is-1 {
  font-size: 3rem;
}

.title.is-2 {
  font-size: 2.5rem;
}

.title.is-3 {
  font-size: 2rem;
}

.title.is-4 {
  font-size: 1.5rem;
}

.title.is-5 {
  font-size: 1.25rem;
}

.title.is-6 {
  font-size: 1rem;
}

.title.is-7 {
  font-size: .75rem;
}

.subtitle {
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
  font-weight: 600;
}

.subtitle:not(.is-spaced) + .title {
  margin-top: -1.25rem;
}

.subtitle.is-1 {
  font-size: 3rem;
}

.subtitle.is-2 {
  font-size: 2.5rem;
}

.subtitle.is-3 {
  font-size: 2rem;
}

.subtitle.is-4 {
  font-size: 1.5rem;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle.is-6 {
  font-size: 1rem;
}

.subtitle.is-7 {
  font-size: .75rem;
}

.table {
  color: #363636;
  background-color: #fff;
}

.table td, .table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.table td.is-white, .table th.is-white {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.table td.is-black, .table th.is-black {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.table td.is-light, .table th.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.table td.is-dark, .table th.is-dark {
  color: #fff;
  background-color: #271f26;
  border-color: #271f26;
}

.table td.is-primary, .table th.is-primary {
  color: #fff;
  background-color: #264d4a;
  border-color: #264d4a;
}

.table td.is-link, .table th.is-link {
  color: #fff;
  background-color: #ad7d59;
  border-color: #ad7d59;
}

.table td.is-info, .table th.is-info {
  color: #fff;
  background-color: #3f3024;
  border-color: #3f3024;
}

.table td.is-success, .table th.is-success {
  color: #000000b3;
  background-color: #86ce93;
  border-color: #86ce93;
}

.table td.is-warning, .table th.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.table td.is-danger, .table th.is-danger {
  color: #fff;
  background-color: #843e37;
  border-color: #843e37;
}

.table td.is-narrow, .table th.is-narrow {
  white-space: nowrap;
  width: 1%;
}

.table td.is-selected, .table th.is-selected {
  color: #fff;
  background-color: #264d4a;
}

.table td.is-selected a, .table td.is-selected strong, .table th.is-selected a, .table th.is-selected strong {
  color: currentColor;
}

.table td.is-vcentered, .table th.is-vcentered {
  vertical-align: middle;
}

.table th {
  color: #363636;
}

.table th:not([align]) {
  text-align: left;
}

.table tr.is-selected {
  color: #fff;
  background-color: #264d4a;
}

.table tr.is-selected a, .table tr.is-selected strong {
  color: currentColor;
}

.table tr.is-selected td, .table tr.is-selected th {
  color: currentColor;
  border-color: #fff;
}

.table thead {
  background-color: #0000;
}

.table thead td, .table thead th {
  color: #363636;
  border-width: 0 0 2px;
}

.table tfoot {
  background-color: #0000;
}

.table tfoot td, .table tfoot th {
  color: #363636;
  border-width: 2px 0 0;
}

.table tbody {
  background-color: #0000;
}

.table tbody tr:last-child td, .table tbody tr:last-child th {
  border-bottom-width: 0;
}

.table.is-bordered td, .table.is-bordered th {
  border-width: 1px;
}

.table.is-bordered tr:last-child td, .table.is-bordered tr:last-child th {
  border-bottom-width: 1px;
}

.table.is-fullwidth {
  width: 100%;
}

.table.is-hoverable tbody tr:not(.is-selected):hover, .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: #fafafa;
}

.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(2n) {
  background-color: #f5f5f5;
}

.table.is-narrow td, .table.is-narrow th {
  padding: .25em .5em;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
  background-color: #fafafa;
}

.table-container {
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.select select, .textarea, .input {
  color: #363636;
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 4px;
}

.select select::-webkit-input-placeholder, .textarea::-webkit-input-placeholder, .input::-webkit-input-placeholder {
  color: #3636364d;
}

.select select::-moz-placeholder, .textarea::-moz-placeholder, .input::-moz-placeholder {
  color: #3636364d;
}

.select select:-moz-placeholder, .textarea:-moz-placeholder, .input:-moz-placeholder {
  color: #3636364d;
}

.select select:-ms-input-placeholder, .textarea:-ms-input-placeholder, .input:-ms-input-placeholder {
  color: #3636364d;
}

.select select:hover, .textarea:hover, .input:hover, .select select.is-hovered, .is-hovered.textarea, .is-hovered.input {
  border-color: #b5b5b5;
}

.select select:focus, .textarea:focus, .input:focus, .select select.is-focused, .is-focused.textarea, .is-focused.input, .select select:active, .textarea:active, .input:active, .select select.is-active, .is-active.textarea, .is-active.input {
  border-color: #ad7d59;
  box-shadow: 0 0 0 .125em #ad7d5940;
}

.select select[disabled], [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input {
  box-shadow: none;
  color: #7a7a7a;
  background-color: #fafcfb;
  border-color: #fafcfb;
}

.select select[disabled]::-webkit-input-placeholder, [disabled].textarea::-webkit-input-placeholder, [disabled].input::-webkit-input-placeholder, fieldset[disabled] .select select::-webkit-input-placeholder, .select fieldset[disabled] select::-webkit-input-placeholder, fieldset[disabled] .textarea::-webkit-input-placeholder, fieldset[disabled] .input::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

.select select[disabled]::-moz-placeholder, [disabled].textarea::-moz-placeholder, [disabled].input::-moz-placeholder, fieldset[disabled] .select select::-moz-placeholder, .select fieldset[disabled] select::-moz-placeholder, fieldset[disabled] .textarea::-moz-placeholder, fieldset[disabled] .input::-moz-placeholder {
  color: #7a7a7a4d;
}

.select select[disabled]:-moz-placeholder, [disabled].textarea:-moz-placeholder, [disabled].input:-moz-placeholder, fieldset[disabled] .select select:-moz-placeholder, .select fieldset[disabled] select:-moz-placeholder, fieldset[disabled] .textarea:-moz-placeholder, fieldset[disabled] .input:-moz-placeholder {
  color: #7a7a7a4d;
}

.select select[disabled]:-ms-input-placeholder, [disabled].textarea:-ms-input-placeholder, [disabled].input:-ms-input-placeholder, fieldset[disabled] .select select:-ms-input-placeholder, .select fieldset[disabled] select:-ms-input-placeholder, fieldset[disabled] .textarea:-ms-input-placeholder, fieldset[disabled] .input:-ms-input-placeholder {
  color: #7a7a7a4d;
}

.textarea, .input {
  max-width: 100%;
  width: 100%;
  box-shadow: inset 0 .0625em .125em #0a0a0a0d;
}

[readonly].textarea, [readonly].input {
  box-shadow: none;
}

.is-white.textarea, .is-white.input {
  border-color: #fff;
}

.is-white.textarea:focus, .is-white.input:focus, .is-white.is-focused.textarea, .is-white.is-focused.input, .is-white.textarea:active, .is-white.input:active, .is-white.is-active.textarea, .is-white.is-active.input {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.is-black.textarea, .is-black.input {
  border-color: #0a0a0a;
}

.is-black.textarea:focus, .is-black.input:focus, .is-black.is-focused.textarea, .is-black.is-focused.input, .is-black.textarea:active, .is-black.input:active, .is-black.is-active.textarea, .is-black.is-active.input {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.is-light.textarea, .is-light.input {
  border-color: #f5f5f5;
}

.is-light.textarea:focus, .is-light.input:focus, .is-light.is-focused.textarea, .is-light.is-focused.input, .is-light.textarea:active, .is-light.input:active, .is-light.is-active.textarea, .is-light.is-active.input {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.is-dark.textarea, .is-dark.input {
  border-color: #271f26;
}

.is-dark.textarea:focus, .is-dark.input:focus, .is-dark.is-focused.textarea, .is-dark.is-focused.input, .is-dark.textarea:active, .is-dark.input:active, .is-dark.is-active.textarea, .is-dark.is-active.input {
  box-shadow: 0 0 0 .125em #271f2640;
}

.is-primary.textarea, .is-primary.input {
  border-color: #264d4a;
}

.is-primary.textarea:focus, .is-primary.input:focus, .is-primary.is-focused.textarea, .is-primary.is-focused.input, .is-primary.textarea:active, .is-primary.input:active, .is-primary.is-active.textarea, .is-primary.is-active.input {
  box-shadow: 0 0 0 .125em #264d4a40;
}

.is-link.textarea, .is-link.input {
  border-color: #ad7d59;
}

.is-link.textarea:focus, .is-link.input:focus, .is-link.is-focused.textarea, .is-link.is-focused.input, .is-link.textarea:active, .is-link.input:active, .is-link.is-active.textarea, .is-link.is-active.input {
  box-shadow: 0 0 0 .125em #ad7d5940;
}

.is-info.textarea, .is-info.input {
  border-color: #3f3024;
}

.is-info.textarea:focus, .is-info.input:focus, .is-info.is-focused.textarea, .is-info.is-focused.input, .is-info.textarea:active, .is-info.input:active, .is-info.is-active.textarea, .is-info.is-active.input {
  box-shadow: 0 0 0 .125em #3f302440;
}

.is-success.textarea, .is-success.input {
  border-color: #86ce93;
}

.is-success.textarea:focus, .is-success.input:focus, .is-success.is-focused.textarea, .is-success.is-focused.input, .is-success.textarea:active, .is-success.input:active, .is-success.is-active.textarea, .is-success.is-active.input {
  box-shadow: 0 0 0 .125em #86ce9340;
}

.is-warning.textarea, .is-warning.input {
  border-color: #ffe08a;
}

.is-warning.textarea:focus, .is-warning.input:focus, .is-warning.is-focused.textarea, .is-warning.is-focused.input, .is-warning.textarea:active, .is-warning.input:active, .is-warning.is-active.textarea, .is-warning.is-active.input {
  box-shadow: 0 0 0 .125em #ffe08a40;
}

.is-danger.textarea, .is-danger.input {
  border-color: #843e37;
}

.is-danger.textarea:focus, .is-danger.input:focus, .is-danger.is-focused.textarea, .is-danger.is-focused.input, .is-danger.textarea:active, .is-danger.input:active, .is-danger.is-active.textarea, .is-danger.is-active.input {
  box-shadow: 0 0 0 .125em #843e3740;
}

.is-small.textarea, .is-small.input {
  border-radius: 2px;
  font-size: .75rem;
}

.is-medium.textarea, .is-medium.input {
  font-size: 1.25rem;
}

.is-large.textarea, .is-large.input {
  font-size: 1.5rem;
}

.is-fullwidth.textarea, .is-fullwidth.input {
  width: 100%;
  display: block;
}

.is-inline.textarea, .is-inline.input {
  width: auto;
  display: inline;
}

.input.is-rounded {
  border-radius: 9999px;
  padding-left: calc(1.125em - 1px);
  padding-right: calc(1.125em - 1px);
}

.input.is-static {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  max-width: 100%;
  min-width: 100%;
  resize: vertical;
  padding: calc(.75em - 1px);
  display: block;
}

.textarea:not([rows]) {
  max-height: 40em;
  min-height: 8em;
}

.textarea[rows] {
  height: initial;
}

.textarea.has-fixed-size {
  resize: none;
}

.radio, .checkbox {
  cursor: pointer;
  line-height: 1.25;
  display: inline-block;
  position: relative;
}

.radio input, .checkbox input {
  cursor: pointer;
}

.radio:hover, .checkbox:hover {
  color: #363636;
}

[disabled].radio, [disabled].checkbox, fieldset[disabled] .radio, fieldset[disabled] .checkbox, .radio input[disabled], .checkbox input[disabled] {
  color: #7a7a7a;
  cursor: not-allowed;
}

.radio + .radio {
  margin-left: .5em;
}

.select {
  max-width: 100%;
  vertical-align: top;
  display: inline-block;
  position: relative;
}

.select:not(.is-multiple) {
  height: 2.5em;
}

.select:not(.is-multiple):not(.is-loading):after {
  z-index: 4;
  border-color: #ad7d59;
  right: 1.125em;
}

.select.is-rounded select {
  border-radius: 9999px;
  padding-left: 1em;
}

.select select {
  cursor: pointer;
  max-width: 100%;
  outline: none;
  font-size: 1em;
  display: block;
}

.select select::-ms-expand {
  display: none;
}

.select select[disabled]:hover, fieldset[disabled] .select select:hover {
  border-color: #fafcfb;
}

.select select:not([multiple]) {
  padding-right: 2.5em;
}

.select select[multiple] {
  height: auto;
  padding: 0;
}

.select select[multiple] option {
  padding: .5em 1em;
}

.select:not(.is-multiple):not(.is-loading):hover:after {
  border-color: #363636;
}

.select.is-white:not(:hover):after, .select.is-white select {
  border-color: #fff;
}

.select.is-white select:hover, .select.is-white select.is-hovered {
  border-color: #f2f2f2;
}

.select.is-white select:focus, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select.is-active {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.select.is-black:not(:hover):after, .select.is-black select {
  border-color: #0a0a0a;
}

.select.is-black select:hover, .select.is-black select.is-hovered {
  border-color: #000;
}

.select.is-black select:focus, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select.is-active {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.select.is-light:not(:hover):after, .select.is-light select {
  border-color: #f5f5f5;
}

.select.is-light select:hover, .select.is-light select.is-hovered {
  border-color: #e8e8e8;
}

.select.is-light select:focus, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select.is-active {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.select.is-dark:not(:hover):after, .select.is-dark select {
  border-color: #271f26;
}

.select.is-dark select:hover, .select.is-dark select.is-hovered {
  border-color: #191418;
}

.select.is-dark select:focus, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select.is-active {
  box-shadow: 0 0 0 .125em #271f2640;
}

.select.is-primary:not(:hover):after, .select.is-primary select {
  border-color: #264d4a;
}

.select.is-primary select:hover, .select.is-primary select.is-hovered {
  border-color: #1e3c3a;
}

.select.is-primary select:focus, .select.is-primary select.is-focused, .select.is-primary select:active, .select.is-primary select.is-active {
  box-shadow: 0 0 0 .125em #264d4a40;
}

.select.is-link:not(:hover):after, .select.is-link select {
  border-color: #ad7d59;
}

.select.is-link select:hover, .select.is-link select.is-hovered {
  border-color: #9e714e;
}

.select.is-link select:focus, .select.is-link select.is-focused, .select.is-link select:active, .select.is-link select.is-active {
  box-shadow: 0 0 0 .125em #ad7d5940;
}

.select.is-info:not(:hover):after, .select.is-info select {
  border-color: #3f3024;
}

.select.is-info select:hover, .select.is-info select.is-hovered {
  border-color: #2f241b;
}

.select.is-info select:focus, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select.is-active {
  box-shadow: 0 0 0 .125em #3f302440;
}

.select.is-success:not(:hover):after, .select.is-success select {
  border-color: #86ce93;
}

.select.is-success select:hover, .select.is-success select.is-hovered {
  border-color: #74c783;
}

.select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
  box-shadow: 0 0 0 .125em #86ce9340;
}

.select.is-warning:not(:hover):after, .select.is-warning select {
  border-color: #ffe08a;
}

.select.is-warning select:hover, .select.is-warning select.is-hovered {
  border-color: #ffd970;
}

.select.is-warning select:focus, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select.is-active {
  box-shadow: 0 0 0 .125em #ffe08a40;
}

.select.is-danger:not(:hover):after, .select.is-danger select {
  border-color: #843e37;
}

.select.is-danger select:hover, .select.is-danger select.is-hovered {
  border-color: #723630;
}

.select.is-danger select:focus, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select.is-active {
  box-shadow: 0 0 0 .125em #843e3740;
}

.select.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.select.is-medium {
  font-size: 1.25rem;
}

.select.is-large {
  font-size: 1.5rem;
}

.select.is-disabled:after {
  opacity: .5;
  border-color: #7a7a7a !important;
}

.select.is-fullwidth, .select.is-fullwidth select {
  width: 100%;
}

.select.is-loading:after {
  margin-top: 0;
  position: absolute;
  top: .625em;
  right: .625em;
  transform: none;
}

.select.is-loading.is-small:after {
  font-size: .75rem;
}

.select.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.select.is-loading.is-large:after {
  font-size: 1.5rem;
}

.file {
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
}

.file.is-white .file-cta {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #0000;
}

.file.is-white:hover .file-cta, .file.is-white.is-hovered .file-cta {
  color: #0a0a0a;
  background-color: #f9f9f9;
  border-color: #0000;
}

.file.is-white:focus .file-cta, .file.is-white.is-focused .file-cta {
  color: #0a0a0a;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffffff40;
}

.file.is-white:active .file-cta, .file.is-white.is-active .file-cta {
  color: #0a0a0a;
  background-color: #f2f2f2;
  border-color: #0000;
}

.file.is-black .file-cta {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0000;
}

.file.is-black:hover .file-cta, .file.is-black.is-hovered .file-cta {
  color: #fff;
  background-color: #040404;
  border-color: #0000;
}

.file.is-black:focus .file-cta, .file.is-black.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #0a0a0a40;
}

.file.is-black:active .file-cta, .file.is-black.is-active .file-cta {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.file.is-light .file-cta {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #0000;
}

.file.is-light:hover .file-cta, .file.is-light.is-hovered .file-cta {
  color: #000000b3;
  background-color: #eee;
  border-color: #0000;
}

.file.is-light:focus .file-cta, .file.is-light.is-focused .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #f5f5f540;
}

.file.is-light:active .file-cta, .file.is-light.is-active .file-cta {
  color: #000000b3;
  background-color: #e8e8e8;
  border-color: #0000;
}

.file.is-dark .file-cta {
  color: #fff;
  background-color: #271f26;
  border-color: #0000;
}

.file.is-dark:hover .file-cta, .file.is-dark.is-hovered .file-cta {
  color: #fff;
  background-color: #20191f;
  border-color: #0000;
}

.file.is-dark:focus .file-cta, .file.is-dark.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #271f2640;
}

.file.is-dark:active .file-cta, .file.is-dark.is-active .file-cta {
  color: #fff;
  background-color: #191418;
  border-color: #0000;
}

.file.is-primary .file-cta {
  color: #fff;
  background-color: #264d4a;
  border-color: #0000;
}

.file.is-primary:hover .file-cta, .file.is-primary.is-hovered .file-cta {
  color: #fff;
  background-color: #224442;
  border-color: #0000;
}

.file.is-primary:focus .file-cta, .file.is-primary.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #264d4a40;
}

.file.is-primary:active .file-cta, .file.is-primary.is-active .file-cta {
  color: #fff;
  background-color: #1e3c3a;
  border-color: #0000;
}

.file.is-link .file-cta {
  color: #fff;
  background-color: #ad7d59;
  border-color: #0000;
}

.file.is-link:hover .file-cta, .file.is-link.is-hovered .file-cta {
  color: #fff;
  background-color: #a77752;
  border-color: #0000;
}

.file.is-link:focus .file-cta, .file.is-link.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #ad7d5940;
}

.file.is-link:active .file-cta, .file.is-link.is-active .file-cta {
  color: #fff;
  background-color: #9e714e;
  border-color: #0000;
}

.file.is-info .file-cta {
  color: #fff;
  background-color: #3f3024;
  border-color: #0000;
}

.file.is-info:hover .file-cta, .file.is-info.is-hovered .file-cta {
  color: #fff;
  background-color: #372a1f;
  border-color: #0000;
}

.file.is-info:focus .file-cta, .file.is-info.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #3f302440;
}

.file.is-info:active .file-cta, .file.is-info.is-active .file-cta {
  color: #fff;
  background-color: #2f241b;
  border-color: #0000;
}

.file.is-success .file-cta {
  color: #000000b3;
  background-color: #86ce93;
  border-color: #0000;
}

.file.is-success:hover .file-cta, .file.is-success.is-hovered .file-cta {
  color: #000000b3;
  background-color: #7dca8b;
  border-color: #0000;
}

.file.is-success:focus .file-cta, .file.is-success.is-focused .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #86ce9340;
}

.file.is-success:active .file-cta, .file.is-success.is-active .file-cta {
  color: #000000b3;
  background-color: #74c783;
  border-color: #0000;
}

.file.is-warning .file-cta {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #0000;
}

.file.is-warning:hover .file-cta, .file.is-warning.is-hovered .file-cta {
  color: #000000b3;
  background-color: #ffdc7d;
  border-color: #0000;
}

.file.is-warning:focus .file-cta, .file.is-warning.is-focused .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffe08a40;
}

.file.is-warning:active .file-cta, .file.is-warning.is-active .file-cta {
  color: #000000b3;
  background-color: #ffd970;
  border-color: #0000;
}

.file.is-danger .file-cta {
  color: #fff;
  background-color: #843e37;
  border-color: #0000;
}

.file.is-danger:hover .file-cta, .file.is-danger.is-hovered .file-cta {
  color: #fff;
  background-color: #7b3a33;
  border-color: #0000;
}

.file.is-danger:focus .file-cta, .file.is-danger.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #843e3740;
}

.file.is-danger:active .file-cta, .file.is-danger.is-active .file-cta {
  color: #fff;
  background-color: #723630;
  border-color: #0000;
}

.file.is-small {
  font-size: .75rem;
}

.file.is-normal {
  font-size: 1rem;
}

.file.is-medium {
  font-size: 1.25rem;
}

.file.is-medium .file-icon .fa {
  font-size: 21px;
}

.file.is-large {
  font-size: 1.5rem;
}

.file.is-large .file-icon .fa {
  font-size: 28px;
}

.file.has-name .file-cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.file.has-name .file-name {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.file.has-name.is-empty .file-cta {
  border-radius: 4px;
}

.file.has-name.is-empty .file-name {
  display: none;
}

.file.is-boxed .file-label {
  flex-direction: column;
}

.file.is-boxed .file-cta {
  height: auto;
  flex-direction: column;
  padding: 1em 3em;
}

.file.is-boxed .file-name {
  border-width: 0 1px 1px;
}

.file.is-boxed .file-icon {
  height: 1.5em;
  width: 1.5em;
}

.file.is-boxed .file-icon .fa {
  font-size: 21px;
}

.file.is-boxed.is-small .file-icon .fa {
  font-size: 14px;
}

.file.is-boxed.is-medium .file-icon .fa {
  font-size: 28px;
}

.file.is-boxed.is-large .file-icon .fa {
  font-size: 35px;
}

.file.is-boxed.has-name .file-cta {
  border-radius: 4px 4px 0 0;
}

.file.is-boxed.has-name .file-name {
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;
}

.file.is-centered {
  justify-content: center;
}

.file.is-fullwidth .file-label {
  width: 100%;
}

.file.is-fullwidth .file-name {
  max-width: none;
  flex-grow: 1;
}

.file.is-right {
  justify-content: flex-end;
}

.file.is-right .file-cta {
  border-radius: 0 4px 4px 0;
}

.file.is-right .file-name {
  border-width: 1px 0 1px 1px;
  border-radius: 4px 0 0 4px;
  order: -1;
}

.file-label {
  cursor: pointer;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.file-label:hover .file-cta {
  color: #363636;
  background-color: #eee;
}

.file-label:hover .file-name {
  border-color: #d5d5d5;
}

.file-label:active .file-cta {
  color: #363636;
  background-color: #e8e8e8;
}

.file-label:active .file-name {
  border-color: #cfcfcf;
}

.file-input {
  height: 100%;
  opacity: 0;
  width: 100%;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
}

.file-cta, .file-name {
  white-space: nowrap;
  border-color: #dbdbdb;
  border-radius: 4px;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1em;
}

.file-cta {
  color: #4a4a4a;
  background-color: #f5f5f5;
}

.file-name {
  max-width: 16em;
  text-align: inherit;
  text-overflow: ellipsis;
  border: 1px solid #dbdbdb;
  border-left-width: 0;
  display: block;
  overflow: hidden;
}

.file-icon {
  height: 1em;
  width: 1em;
  justify-content: center;
  align-items: center;
  margin-right: .5em;
  display: flex;
}

.file-icon .fa {
  font-size: 14px;
}

.label {
  color: #363636;
  font-size: 1rem;
  font-weight: 700;
  display: block;
}

.label:not(:last-child) {
  margin-bottom: .5em;
}

.label.is-small {
  font-size: .75rem;
}

.label.is-medium {
  font-size: 1.25rem;
}

.label.is-large {
  font-size: 1.5rem;
}

.help {
  margin-top: .25rem;
  font-size: .75rem;
  display: block;
}

.help.is-white {
  color: #fff;
}

.help.is-black {
  color: #0a0a0a;
}

.help.is-light {
  color: #f5f5f5;
}

.help.is-dark {
  color: #271f26;
}

.help.is-primary {
  color: #264d4a;
}

.help.is-link {
  color: #ad7d59;
}

.help.is-info {
  color: #3f3024;
}

.help.is-success {
  color: #86ce93;
}

.help.is-warning {
  color: #ffe08a;
}

.help.is-danger {
  color: #843e37;
}

.field:not(:last-child) {
  margin-bottom: .75rem;
}

.field.has-addons {
  justify-content: flex-start;
  display: flex;
}

.field.has-addons .control:not(:last-child) {
  margin-right: -1px;
}

.field.has-addons .control:not(:first-child):not(:last-child) .button, .field.has-addons .control:not(:first-child):not(:last-child) .input, .field.has-addons .control:not(:first-child):not(:last-child) .select select {
  border-radius: 0;
}

.field.has-addons .control:first-child:not(:only-child) .button, .field.has-addons .control:first-child:not(:only-child) .input, .field.has-addons .control:first-child:not(:only-child) .select select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.field.has-addons .control:last-child:not(:only-child) .button, .field.has-addons .control:last-child:not(:only-child) .input, .field.has-addons .control:last-child:not(:only-child) .select select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered, .field.has-addons .control .input:not([disabled]):hover, .field.has-addons .control .input:not([disabled]).is-hovered, .field.has-addons .control .select select:not([disabled]):hover, .field.has-addons .control .select select:not([disabled]).is-hovered {
  z-index: 2;
}

.field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active, .field.has-addons .control .input:not([disabled]):focus, .field.has-addons .control .input:not([disabled]).is-focused, .field.has-addons .control .input:not([disabled]):active, .field.has-addons .control .input:not([disabled]).is-active, .field.has-addons .control .select select:not([disabled]):focus, .field.has-addons .control .select select:not([disabled]).is-focused, .field.has-addons .control .select select:not([disabled]):active, .field.has-addons .control .select select:not([disabled]).is-active {
  z-index: 3;
}

.field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover, .field.has-addons .control .input:not([disabled]):focus:hover, .field.has-addons .control .input:not([disabled]).is-focused:hover, .field.has-addons .control .input:not([disabled]):active:hover, .field.has-addons .control .input:not([disabled]).is-active:hover, .field.has-addons .control .select select:not([disabled]):focus:hover, .field.has-addons .control .select select:not([disabled]).is-focused:hover, .field.has-addons .control .select select:not([disabled]):active:hover, .field.has-addons .control .select select:not([disabled]).is-active:hover {
  z-index: 4;
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.has-addons.has-addons-centered {
  justify-content: center;
}

.field.has-addons.has-addons-right {
  justify-content: flex-end;
}

.field.has-addons.has-addons-fullwidth .control {
  flex-grow: 1;
  flex-shrink: 0;
}

.field.is-grouped {
  justify-content: flex-start;
  display: flex;
}

.field.is-grouped > .control {
  flex-shrink: 0;
}

.field.is-grouped > .control:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.field.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.is-grouped.is-grouped-centered {
  justify-content: center;
}

.field.is-grouped.is-grouped-right {
  justify-content: flex-end;
}

.field.is-grouped.is-grouped-multiline {
  flex-wrap: wrap;
}

.field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
  margin-bottom: .75rem;
}

.field.is-grouped.is-grouped-multiline:last-child {
  margin-bottom: -.75rem;
}

.field.is-grouped.is-grouped-multiline:not(:last-child) {
  margin-bottom: 0;
}

@media screen and (min-width: 769px), print {
  .field.is-horizontal {
    display: flex;
  }
}

.field-label .label {
  font-size: inherit;
}

@media screen and (max-width: 768px) {
  .field-label {
    margin-bottom: .5rem;
  }
}

@media screen and (min-width: 769px), print {
  .field-label {
    text-align: right;
    flex: 1 0 0;
    margin-right: 1.5rem;
  }

  .field-label.is-small {
    padding-top: .375em;
    font-size: .75rem;
  }

  .field-label.is-normal {
    padding-top: .375em;
  }

  .field-label.is-medium {
    padding-top: .375em;
    font-size: 1.25rem;
  }

  .field-label.is-large {
    padding-top: .375em;
    font-size: 1.5rem;
  }
}

.field-body .field .field {
  margin-bottom: 0;
}

@media screen and (min-width: 769px), print {
  .field-body {
    flex: 5 1 0;
    display: flex;
  }

  .field-body .field {
    margin-bottom: 0;
  }

  .field-body > .field {
    flex-shrink: 1;
  }

  .field-body > .field:not(.is-narrow) {
    flex-grow: 1;
  }

  .field-body > .field:not(:last-child) {
    margin-right: .75rem;
  }
}

.control {
  box-sizing: border-box;
  clear: both;
  text-align: inherit;
  font-size: 1rem;
  position: relative;
}

.control.has-icons-left .input:focus ~ .icon, .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon, .control.has-icons-right .select:focus ~ .icon {
  color: #4a4a4a;
}

.control.has-icons-left .input.is-small ~ .icon, .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon, .control.has-icons-right .select.is-small ~ .icon {
  font-size: .75rem;
}

.control.has-icons-left .input.is-medium ~ .icon, .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon, .control.has-icons-right .select.is-medium ~ .icon {
  font-size: 1.25rem;
}

.control.has-icons-left .input.is-large ~ .icon, .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon, .control.has-icons-right .select.is-large ~ .icon {
  font-size: 1.5rem;
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: #dbdbdb;
  height: 2.5em;
  pointer-events: none;
  width: 2.5em;
  z-index: 4;
  position: absolute;
  top: 0;
}

.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: 2.5em;
}

.control.has-icons-left .icon.is-left {
  left: 0;
}

.control.has-icons-right .input, .control.has-icons-right .select select {
  padding-right: 2.5em;
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.control.is-loading:after {
  z-index: 4;
  top: .625em;
  right: .625em;
  position: absolute !important;
}

.control.is-loading.is-small:after {
  font-size: .75rem;
}

.control.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.control.is-loading.is-large:after {
  font-size: 1.5rem;
}

.column {
  flex: 1 1 0;
  padding: .75rem;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  width: unset;
  flex: none;
}

.columns.is-mobile > .column.is-full {
  width: 100%;
  flex: none;
}

.columns.is-mobile > .column.is-three-quarters {
  width: 75%;
  flex: none;
}

.columns.is-mobile > .column.is-two-thirds {
  width: 66.6666%;
  flex: none;
}

.columns.is-mobile > .column.is-half {
  width: 50%;
  flex: none;
}

.columns.is-mobile > .column.is-one-third {
  width: 33.3333%;
  flex: none;
}

.columns.is-mobile > .column.is-one-quarter {
  width: 25%;
  flex: none;
}

.columns.is-mobile > .column.is-one-fifth {
  width: 20%;
  flex: none;
}

.columns.is-mobile > .column.is-two-fifths {
  width: 40%;
  flex: none;
}

.columns.is-mobile > .column.is-three-fifths {
  width: 60%;
  flex: none;
}

.columns.is-mobile > .column.is-four-fifths {
  width: 80%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-0 {
  width: 0%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}

.columns.is-mobile > .column.is-1 {
  width: 8.33333%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  width: 16.6667%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  width: 25%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  width: 33.3333%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  width: 41.6667%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  width: 50%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  width: 58.3333%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  width: 66.6667%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  width: 75%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  width: 83.3333%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  width: 91.6667%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  width: 100%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (max-width: 768px) {
  .column.is-narrow-mobile {
    width: unset;
    flex: none;
  }

  .column.is-full-mobile {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-mobile {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-mobile {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-mobile {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-mobile {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-mobile {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-mobile {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-mobile {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-mobile {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-mobile {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-0-mobile {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-mobile {
    margin-left: 0%;
  }

  .column.is-1-mobile {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (min-width: 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    width: unset;
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half, .column.is-half-tablet {
    width: 50%;
    flex: none;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-0, .column.is-0-tablet {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }

  .column.is-1, .column.is-1-tablet {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .column.is-narrow-touch {
    width: unset;
    flex: none;
  }

  .column.is-full-touch {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-touch {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-touch {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-touch {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-touch {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-touch {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-touch {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-touch {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-touch {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-touch {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-0-touch {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-touch {
    margin-left: 0%;
  }

  .column.is-1-touch {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .column.is-narrow-desktop {
    width: unset;
    flex: none;
  }

  .column.is-full-desktop {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-desktop {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-desktop {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-desktop {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-desktop {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-desktop {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-desktop {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-desktop {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-desktop {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-desktop {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-0-desktop {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-desktop {
    margin-left: 0%;
  }

  .column.is-1-desktop {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1216px) {
  .column.is-narrow-widescreen {
    width: unset;
    flex: none;
  }

  .column.is-full-widescreen {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-widescreen {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-widescreen {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-widescreen {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-widescreen {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-widescreen {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-widescreen {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-widescreen {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-widescreen {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-widescreen {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-0-widescreen {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }

  .column.is-1-widescreen {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1408px) {
  .column.is-narrow-fullhd {
    width: unset;
    flex: none;
  }

  .column.is-full-fullhd {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-fullhd {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-fullhd {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-fullhd {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-fullhd {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-fullhd {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-fullhd {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-fullhd {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-fullhd {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-fullhd {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-0-fullhd {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }

  .column.is-1-fullhd {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.columns:last-child {
  margin-bottom: -.75rem;
}

.columns:not(:last-child) {
  margin-bottom: .75rem;
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (min-width: 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable > .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}

.columns.is-variable.is-1 {
  --columnGap: .25rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: .25rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-1-touch {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: .25rem;
  }
}

.columns.is-variable.is-2 {
  --columnGap: .5rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: .5rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-2-touch {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: .5rem;
  }
}

.columns.is-variable.is-3 {
  --columnGap: .75rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: .75rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-3-touch {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: .75rem;
  }
}

.columns.is-variable.is-4 {
  --columnGap: 1rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}

.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}

.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}

.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}

.columns.is-variable.is-8 {
  --columnGap: 2rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

.is-flex-direction-row {
  flex-direction: row !important;
}

.is-flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.is-flex-direction-column {
  flex-direction: column !important;
}

.is-flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.is-flex-wrap-nowrap {
  flex-wrap: nowrap !important;
}

.is-flex-wrap-wrap {
  flex-wrap: wrap !important;
}

.is-flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.is-justify-content-flex-start {
  justify-content: flex-start !important;
}

.is-justify-content-flex-end {
  justify-content: flex-end !important;
}

.is-justify-content-center {
  justify-content: center !important;
}

.is-justify-content-space-between {
  justify-content: space-between !important;
}

.is-justify-content-space-around {
  justify-content: space-around !important;
}

.is-justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.is-justify-content-start {
  justify-content: start !important;
}

.is-justify-content-end {
  justify-content: end !important;
}

.is-justify-content-left {
  justify-content: left !important;
}

.is-justify-content-right {
  justify-content: right !important;
}

.is-align-content-flex-start {
  align-content: flex-start !important;
}

.is-align-content-flex-end {
  align-content: flex-end !important;
}

.is-align-content-center {
  align-content: center !important;
}

.is-align-content-space-between {
  align-content: space-between !important;
}

.is-align-content-space-around {
  align-content: space-around !important;
}

.is-align-content-space-evenly {
  align-content: space-evenly !important;
}

.is-align-content-stretch {
  align-content: stretch !important;
}

.is-align-content-start {
  align-content: start !important;
}

.is-align-content-end {
  align-content: end !important;
}

.is-align-content-baseline {
  align-content: baseline !important;
}

.is-align-items-stretch {
  align-items: stretch !important;
}

.is-align-items-flex-start {
  align-items: flex-start !important;
}

.is-align-items-flex-end {
  align-items: flex-end !important;
}

.is-align-items-center {
  align-items: center !important;
}

.is-align-items-baseline {
  align-items: baseline !important;
}

.is-align-items-start {
  align-items: start !important;
}

.is-align-items-end {
  align-items: end !important;
}

.is-align-items-self-start {
  align-items: self-start !important;
}

.is-align-items-self-end {
  align-items: self-end !important;
}

.is-align-self-auto {
  align-self: auto !important;
}

.is-align-self-flex-start {
  align-self: flex-start !important;
}

.is-align-self-flex-end {
  align-self: flex-end !important;
}

.is-align-self-center {
  align-self: center !important;
}

.is-align-self-baseline {
  align-self: baseline !important;
}

.is-align-self-stretch {
  align-self: stretch !important;
}

.is-flex-grow-0 {
  flex-grow: 0 !important;
}

.is-flex-grow-1 {
  flex-grow: 1 !important;
}

.is-flex-grow-2 {
  flex-grow: 2 !important;
}

.is-flex-grow-3 {
  flex-grow: 3 !important;
}

.is-flex-grow-4 {
  flex-grow: 4 !important;
}

.is-flex-grow-5 {
  flex-grow: 5 !important;
}

.is-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.is-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.is-flex-shrink-2 {
  flex-shrink: 2 !important;
}

.is-flex-shrink-3 {
  flex-shrink: 3 !important;
}

.is-flex-shrink-4 {
  flex-shrink: 4 !important;
}

.is-flex-shrink-5 {
  flex-shrink: 5 !important;
}

.is-marginless {
  margin: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.m-3 {
  margin: .75rem !important;
}

.mt-3 {
  margin-top: .75rem !important;
}

.mr-3 {
  margin-right: .75rem !important;
}

.mb-3 {
  margin-bottom: .75rem !important;
}

.ml-3 {
  margin-left: .75rem !important;
}

.mx-3 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.my-3 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.mt-5 {
  margin-top: 1.5rem !important;
}

.mr-5 {
  margin-right: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 1.5rem !important;
}

.ml-5 {
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-5 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.p-3 {
  padding: .75rem !important;
}

.pt-3 {
  padding-top: .75rem !important;
}

.pr-3 {
  padding-right: .75rem !important;
}

.pb-3 {
  padding-bottom: .75rem !important;
}

.pl-3 {
  padding-left: .75rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.pt-5 {
  padding-top: 1.5rem !important;
}

.pr-5 {
  padding-right: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 1.5rem !important;
}

.pl-5 {
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-5 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.p-auto {
  padding: auto !important;
}

.pt-auto {
  padding-top: auto !important;
}

.pr-auto {
  padding-right: auto !important;
}

.pb-auto {
  padding-bottom: auto !important;
}

.pl-auto {
  padding-left: auto !important;
}

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.is-size-1 {
  font-size: 3rem !important;
}

.is-size-2 {
  font-size: 2.5rem !important;
}

.is-size-3 {
  font-size: 2rem !important;
}

.is-size-4 {
  font-size: 1.5rem !important;
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.is-size-6 {
  font-size: 1rem !important;
}

.is-size-7 {
  font-size: .75rem !important;
}

@media screen and (max-width: 768px) {
  .is-size-1-mobile {
    font-size: 3rem !important;
  }

  .is-size-2-mobile {
    font-size: 2.5rem !important;
  }

  .is-size-3-mobile {
    font-size: 2rem !important;
  }

  .is-size-4-mobile {
    font-size: 1.5rem !important;
  }

  .is-size-5-mobile {
    font-size: 1.25rem !important;
  }

  .is-size-6-mobile {
    font-size: 1rem !important;
  }

  .is-size-7-mobile {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-size-1-tablet {
    font-size: 3rem !important;
  }

  .is-size-2-tablet {
    font-size: 2.5rem !important;
  }

  .is-size-3-tablet {
    font-size: 2rem !important;
  }

  .is-size-4-tablet {
    font-size: 1.5rem !important;
  }

  .is-size-5-tablet {
    font-size: 1.25rem !important;
  }

  .is-size-6-tablet {
    font-size: 1rem !important;
  }

  .is-size-7-tablet {
    font-size: .75rem !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-size-1-touch {
    font-size: 3rem !important;
  }

  .is-size-2-touch {
    font-size: 2.5rem !important;
  }

  .is-size-3-touch {
    font-size: 2rem !important;
  }

  .is-size-4-touch {
    font-size: 1.5rem !important;
  }

  .is-size-5-touch {
    font-size: 1.25rem !important;
  }

  .is-size-6-touch {
    font-size: 1rem !important;
  }

  .is-size-7-touch {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-size-1-desktop {
    font-size: 3rem !important;
  }

  .is-size-2-desktop {
    font-size: 2.5rem !important;
  }

  .is-size-3-desktop {
    font-size: 2rem !important;
  }

  .is-size-4-desktop {
    font-size: 1.5rem !important;
  }

  .is-size-5-desktop {
    font-size: 1.25rem !important;
  }

  .is-size-6-desktop {
    font-size: 1rem !important;
  }

  .is-size-7-desktop {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-size-1-widescreen {
    font-size: 3rem !important;
  }

  .is-size-2-widescreen {
    font-size: 2.5rem !important;
  }

  .is-size-3-widescreen {
    font-size: 2rem !important;
  }

  .is-size-4-widescreen {
    font-size: 1.5rem !important;
  }

  .is-size-5-widescreen {
    font-size: 1.25rem !important;
  }

  .is-size-6-widescreen {
    font-size: 1rem !important;
  }

  .is-size-7-widescreen {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-size-1-fullhd {
    font-size: 3rem !important;
  }

  .is-size-2-fullhd {
    font-size: 2.5rem !important;
  }

  .is-size-3-fullhd {
    font-size: 2rem !important;
  }

  .is-size-4-fullhd {
    font-size: 1.5rem !important;
  }

  .is-size-5-fullhd {
    font-size: 1.25rem !important;
  }

  .is-size-6-fullhd {
    font-size: 1rem !important;
  }

  .is-size-7-fullhd {
    font-size: .75rem !important;
  }
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (max-width: 768px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.is-underlined {
  text-decoration: underline !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary, .is-family-secondary, .is-family-sans-serif {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.is-family-monospace, .is-family-code {
  font-family: monospace !important;
}

.is-relative {
  position: relative !important;
}

.is-block {
  display: block !important;
}

@media screen and (max-width: 768px) {
  .is-block-mobile {
    display: block !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-block-tablet {
    display: block !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-block-touch {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-block-desktop {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-block-fullhd {
    display: block !important;
  }
}

.is-flex {
  display: flex !important;
}

@media screen and (max-width: 768px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-inline {
  display: inline !important;
}

@media screen and (max-width: 768px) {
  .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-fullhd {
    display: inline !important;
  }
}

.is-inline-block {
  display: inline-block !important;
}

@media screen and (max-width: 768px) {
  .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-inline-flex {
  display: inline-flex !important;
}

@media screen and (max-width: 768px) {
  .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-hidden {
  display: none !important;
}

.is-sr-only {
  clip: rect(0, 0, 0, 0) !important;
  height: .01em !important;
  white-space: nowrap !important;
  width: .01em !important;
  border: none !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media screen and (max-width: 768px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-hidden-fullhd {
    display: none !important;
  }
}

.is-invisible {
  visibility: hidden !important;
}

@media screen and (max-width: 768px) {
  .is-invisible-mobile {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-invisible-tablet {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-invisible-tablet-only {
    visibility: hidden !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-invisible-touch {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-invisible-desktop {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-invisible-desktop-only {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-invisible-widescreen {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-invisible-widescreen-only {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-invisible-fullhd {
    visibility: hidden !important;
  }
}

.has-text-white {
  color: #fff !important;
}

a.has-text-white:hover, a.has-text-white:focus {
  color: #e6e6e6 !important;
}

.has-background-white {
  background-color: #fff !important;
}

.has-text-black {
  color: #0a0a0a !important;
}

a.has-text-black:hover, a.has-text-black:focus {
  color: #000 !important;
}

.has-background-black {
  background-color: #0a0a0a !important;
}

.has-text-light {
  color: #f5f5f5 !important;
}

a.has-text-light:hover, a.has-text-light:focus {
  color: #dbdbdb !important;
}

.has-background-light {
  background-color: #f5f5f5 !important;
}

.has-text-dark {
  color: #271f26 !important;
}

a.has-text-dark:hover, a.has-text-dark:focus {
  color: #0b080a !important;
}

.has-background-dark {
  background-color: #271f26 !important;
}

.has-text-primary {
  color: #264d4a !important;
}

a.has-text-primary:hover, a.has-text-primary:focus {
  color: #152b29 !important;
}

.has-background-primary {
  background-color: #264d4a !important;
}

.has-text-primary-light {
  color: #f1f8f8 !important;
}

a.has-text-primary-light:hover, a.has-text-primary-light:focus {
  color: #cfe7e6 !important;
}

.has-background-primary-light {
  background-color: #f1f8f8 !important;
}

.has-text-primary-dark {
  color: #5baea8 !important;
}

a.has-text-primary-dark:hover, a.has-text-primary-dark:focus {
  color: #7dbfba !important;
}

.has-background-primary-dark {
  background-color: #5baea8 !important;
}

.has-text-link {
  color: #ad7d59 !important;
}

a.has-text-link:hover, a.has-text-link:focus {
  color: #8d6446 !important;
}

.has-background-link {
  background-color: #ad7d59 !important;
}

.has-text-link-light {
  color: #f8f4f1 !important;
}

a.has-text-link-light:hover, a.has-text-link-light:focus {
  color: #e7dacf !important;
}

.has-background-link-light {
  background-color: #f8f4f1 !important;
}

.has-text-link-dark {
  color: #8c6345 !important;
}

a.has-text-link-dark:hover, a.has-text-link-dark:focus {
  color: #ac7c58 !important;
}

.has-background-link-dark {
  background-color: #8c6345 !important;
}

.has-text-info {
  color: #3f3024 !important;
}

a.has-text-info:hover, a.has-text-info:focus {
  color: #1f1711 !important;
}

.has-background-info {
  background-color: #3f3024 !important;
}

.has-text-info-light {
  color: #f8f4f2 !important;
}

a.has-text-info-light:hover, a.has-text-info-light:focus {
  color: #e5dad2 !important;
}

.has-background-info-light {
  background-color: #f8f4f2 !important;
}

.has-text-info-dark {
  color: #aa866a !important;
}

a.has-text-info-dark:hover, a.has-text-info-dark:focus {
  color: #bca08a !important;
}

.has-background-info-dark {
  background-color: #aa866a !important;
}

.has-text-success {
  color: #86ce93 !important;
}

a.has-text-success:hover, a.has-text-success:focus {
  color: #62bf73 !important;
}

.has-background-success {
  background-color: #86ce93 !important;
}

.has-text-success-light {
  color: #f0f9f2 !important;
}

a.has-text-success-light:hover, a.has-text-success-light:focus {
  color: #ccead2 !important;
}

.has-background-success-light {
  background-color: #f0f9f2 !important;
}

.has-text-success-dark {
  color: #2b6936 !important;
}

a.has-text-success-dark:hover, a.has-text-success-dark:focus {
  color: #398e49 !important;
}

.has-background-success-dark {
  background-color: #2b6936 !important;
}

.has-text-warning {
  color: #ffe08a !important;
}

a.has-text-warning:hover, a.has-text-warning:focus {
  color: #ffd257 !important;
}

.has-background-warning {
  background-color: #ffe08a !important;
}

.has-text-warning-light {
  color: #fffaeb !important;
}

a.has-text-warning-light:hover, a.has-text-warning-light:focus {
  color: #ffecb8 !important;
}

.has-background-warning-light {
  background-color: #fffaeb !important;
}

.has-text-warning-dark {
  color: #946c00 !important;
}

a.has-text-warning-dark:hover, a.has-text-warning-dark:focus {
  color: #c79200 !important;
}

.has-background-warning-dark {
  background-color: #946c00 !important;
}

.has-text-danger {
  color: #843e37 !important;
}

a.has-text-danger:hover, a.has-text-danger:focus {
  color: #602d28 !important;
}

.has-background-danger {
  background-color: #843e37 !important;
}

.has-text-danger-light {
  color: #f9f1f1 !important;
}

a.has-text-danger-light:hover, a.has-text-danger-light:focus {
  color: #eacfcd !important;
}

.has-background-danger-light {
  background-color: #f9f1f1 !important;
}

.has-text-danger-dark {
  color: #b4554b !important;
}

a.has-text-danger-dark:hover, a.has-text-danger-dark:focus {
  color: #c3776f !important;
}

.has-background-danger-dark {
  background-color: #b4554b !important;
}

.has-text-black-bis {
  color: #121212 !important;
}

.has-background-black-bis {
  background-color: #121212 !important;
}

.has-text-black-ter {
  color: #242424 !important;
}

.has-background-black-ter {
  background-color: #242424 !important;
}

.has-text-grey-darker {
  color: #363636 !important;
}

.has-background-grey-darker {
  background-color: #363636 !important;
}

.has-text-grey-dark {
  color: #4a4a4a !important;
}

.has-background-grey-dark {
  background-color: #4a4a4a !important;
}

.has-text-grey {
  color: #7a7a7a !important;
}

.has-background-grey {
  background-color: #7a7a7a !important;
}

.has-text-grey-light {
  color: #b5b5b5 !important;
}

.has-background-grey-light {
  background-color: #b5b5b5 !important;
}

.has-text-grey-lighter {
  color: #dbdbdb !important;
}

.has-background-grey-lighter {
  background-color: #dbdbdb !important;
}

.has-text-white-ter {
  color: #f5f5f5 !important;
}

.has-background-white-ter {
  background-color: #f5f5f5 !important;
}

.has-text-white-bis {
  color: #fafafa !important;
}

.has-background-white-bis {
  background-color: #fafafa !important;
}

.footer {
  background-color: #fafafa;
  padding: 3rem 1.5rem 6rem;
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero .navbar {
  background: none;
}

.hero .tabs ul {
  border-bottom: none;
}

.hero.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.hero.is-white a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-white strong {
  color: inherit;
}

.hero.is-white .title {
  color: #0a0a0a;
}

.hero.is-white .subtitle {
  color: #0a0a0ae6;
}

.hero.is-white .subtitle a:not(.button), .hero.is-white .subtitle strong {
  color: #0a0a0a;
}

@media screen and (max-width: 1023px) {
  .hero.is-white .navbar-menu {
    background-color: #fff;
  }
}

.hero.is-white .navbar-item, .hero.is-white .navbar-link {
  color: #0a0a0ab3;
}

.hero.is-white a.navbar-item:hover, .hero.is-white a.navbar-item.is-active, .hero.is-white .navbar-link:hover, .hero.is-white .navbar-link.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
}

.hero.is-white .tabs a {
  color: #0a0a0a;
  opacity: .9;
}

.hero.is-white .tabs a:hover {
  opacity: 1;
}

.hero.is-white .tabs li.is-active a {
  opacity: 1;
  color: #fff !important;
}

.hero.is-white .tabs.is-boxed a, .hero.is-white .tabs.is-toggle a {
  color: #0a0a0a;
}

.hero.is-white .tabs.is-boxed a:hover, .hero.is-white .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-white .tabs.is-boxed li.is-active a, .hero.is-white .tabs.is-boxed li.is-active a:hover, .hero.is-white .tabs.is-toggle li.is-active a, .hero.is-white .tabs.is-toggle li.is-active a:hover {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.hero.is-white.is-bold {
  background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-white.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
  }
}

.hero.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.hero.is-black a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-black strong {
  color: inherit;
}

.hero.is-black .title {
  color: #fff;
}

.hero.is-black .subtitle {
  color: #ffffffe6;
}

.hero.is-black .subtitle a:not(.button), .hero.is-black .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-black .navbar-menu {
    background-color: #0a0a0a;
  }
}

.hero.is-black .navbar-item, .hero.is-black .navbar-link {
  color: #ffffffb3;
}

.hero.is-black a.navbar-item:hover, .hero.is-black a.navbar-item.is-active, .hero.is-black .navbar-link:hover, .hero.is-black .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.hero.is-black .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-black .tabs a:hover {
  opacity: 1;
}

.hero.is-black .tabs li.is-active a {
  opacity: 1;
  color: #0a0a0a !important;
}

.hero.is-black .tabs.is-boxed a, .hero.is-black .tabs.is-toggle a {
  color: #fff;
}

.hero.is-black .tabs.is-boxed a:hover, .hero.is-black .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-black .tabs.is-boxed li.is-active a, .hero.is-black .tabs.is-boxed li.is-active a:hover, .hero.is-black .tabs.is-toggle li.is-active a, .hero.is-black .tabs.is-toggle li.is-active a:hover {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-black.is-bold {
  background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-black.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
  }
}

.hero.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.hero.is-light a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-light strong {
  color: inherit;
}

.hero.is-light .title {
  color: #000000b3;
}

.hero.is-light .subtitle {
  color: #000000e6;
}

.hero.is-light .subtitle a:not(.button), .hero.is-light .subtitle strong {
  color: #000000b3;
}

@media screen and (max-width: 1023px) {
  .hero.is-light .navbar-menu {
    background-color: #f5f5f5;
  }
}

.hero.is-light .navbar-item, .hero.is-light .navbar-link {
  color: #000000b3;
}

.hero.is-light a.navbar-item:hover, .hero.is-light a.navbar-item.is-active, .hero.is-light .navbar-link:hover, .hero.is-light .navbar-link.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
}

.hero.is-light .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-light .tabs a:hover {
  opacity: 1;
}

.hero.is-light .tabs li.is-active a {
  opacity: 1;
  color: #f5f5f5 !important;
}

.hero.is-light .tabs.is-boxed a, .hero.is-light .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-light .tabs.is-boxed a:hover, .hero.is-light .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-light .tabs.is-boxed li.is-active a, .hero.is-light .tabs.is-boxed li.is-active a:hover, .hero.is-light .tabs.is-toggle li.is-active a, .hero.is-light .tabs.is-toggle li.is-active a:hover {
  color: #f5f5f5;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-light.is-bold {
  background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
  }
}

.hero.is-dark {
  color: #fff;
  background-color: #271f26;
}

.hero.is-dark a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-dark strong {
  color: inherit;
}

.hero.is-dark .title {
  color: #fff;
}

.hero.is-dark .subtitle {
  color: #ffffffe6;
}

.hero.is-dark .subtitle a:not(.button), .hero.is-dark .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-dark .navbar-menu {
    background-color: #271f26;
  }
}

.hero.is-dark .navbar-item, .hero.is-dark .navbar-link {
  color: #ffffffb3;
}

.hero.is-dark a.navbar-item:hover, .hero.is-dark a.navbar-item.is-active, .hero.is-dark .navbar-link:hover, .hero.is-dark .navbar-link.is-active {
  color: #fff;
  background-color: #191418;
}

.hero.is-dark .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-dark .tabs a:hover {
  opacity: 1;
}

.hero.is-dark .tabs li.is-active a {
  opacity: 1;
  color: #271f26 !important;
}

.hero.is-dark .tabs.is-boxed a, .hero.is-dark .tabs.is-toggle a {
  color: #fff;
}

.hero.is-dark .tabs.is-boxed a:hover, .hero.is-dark .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-dark .tabs.is-boxed li.is-active a, .hero.is-dark .tabs.is-boxed li.is-active a:hover, .hero.is-dark .tabs.is-toggle li.is-active a, .hero.is-dark .tabs.is-toggle li.is-active a:hover {
  color: #271f26;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-dark.is-bold {
  background-image: linear-gradient(141deg, #0b070c 0%, #271f26 71%, #382833 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #0b070c 0%, #271f26 71%, #382833 100%);
  }
}

.hero.is-primary {
  color: #fff;
  background-color: #264d4a;
}

.hero.is-primary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-primary strong {
  color: inherit;
}

.hero.is-primary .title {
  color: #fff;
}

.hero.is-primary .subtitle {
  color: #ffffffe6;
}

.hero.is-primary .subtitle a:not(.button), .hero.is-primary .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-primary .navbar-menu {
    background-color: #264d4a;
  }
}

.hero.is-primary .navbar-item, .hero.is-primary .navbar-link {
  color: #ffffffb3;
}

.hero.is-primary a.navbar-item:hover, .hero.is-primary a.navbar-item.is-active, .hero.is-primary .navbar-link:hover, .hero.is-primary .navbar-link.is-active {
  color: #fff;
  background-color: #1e3c3a;
}

.hero.is-primary .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-primary .tabs a:hover {
  opacity: 1;
}

.hero.is-primary .tabs li.is-active a {
  opacity: 1;
  color: #264d4a !important;
}

.hero.is-primary .tabs.is-boxed a, .hero.is-primary .tabs.is-toggle a {
  color: #fff;
}

.hero.is-primary .tabs.is-boxed a:hover, .hero.is-primary .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-primary .tabs.is-boxed li.is-active a, .hero.is-primary .tabs.is-boxed li.is-active a:hover, .hero.is-primary .tabs.is-toggle li.is-active a, .hero.is-primary .tabs.is-toggle li.is-active a:hover {
  color: #264d4a;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-primary.is-bold {
  background-image: linear-gradient(141deg, #122e27 0%, #264d4a 71%, #2b5d62 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-primary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #122e27 0%, #264d4a 71%, #2b5d62 100%);
  }
}

.hero.is-link {
  color: #fff;
  background-color: #ad7d59;
}

.hero.is-link a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-link strong {
  color: inherit;
}

.hero.is-link .title {
  color: #fff;
}

.hero.is-link .subtitle {
  color: #ffffffe6;
}

.hero.is-link .subtitle a:not(.button), .hero.is-link .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-link .navbar-menu {
    background-color: #ad7d59;
  }
}

.hero.is-link .navbar-item, .hero.is-link .navbar-link {
  color: #ffffffb3;
}

.hero.is-link a.navbar-item:hover, .hero.is-link a.navbar-item.is-active, .hero.is-link .navbar-link:hover, .hero.is-link .navbar-link.is-active {
  color: #fff;
  background-color: #9e714e;
}

.hero.is-link .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-link .tabs a:hover {
  opacity: 1;
}

.hero.is-link .tabs li.is-active a {
  opacity: 1;
  color: #ad7d59 !important;
}

.hero.is-link .tabs.is-boxed a, .hero.is-link .tabs.is-toggle a {
  color: #fff;
}

.hero.is-link .tabs.is-boxed a:hover, .hero.is-link .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-link .tabs.is-boxed li.is-active a, .hero.is-link .tabs.is-boxed li.is-active a:hover, .hero.is-link .tabs.is-toggle li.is-active a, .hero.is-link .tabs.is-toggle li.is-active a:hover {
  color: #ad7d59;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-link.is-bold {
  background-image: linear-gradient(141deg, #98533b 0%, #ad7d59 71%, #bb9865 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-link.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #98533b 0%, #ad7d59 71%, #bb9865 100%);
  }
}

.hero.is-info {
  color: #fff;
  background-color: #3f3024;
}

.hero.is-info a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-info strong {
  color: inherit;
}

.hero.is-info .title {
  color: #fff;
}

.hero.is-info .subtitle {
  color: #ffffffe6;
}

.hero.is-info .subtitle a:not(.button), .hero.is-info .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-info .navbar-menu {
    background-color: #3f3024;
  }
}

.hero.is-info .navbar-item, .hero.is-info .navbar-link {
  color: #ffffffb3;
}

.hero.is-info a.navbar-item:hover, .hero.is-info a.navbar-item.is-active, .hero.is-info .navbar-link:hover, .hero.is-info .navbar-link.is-active {
  color: #fff;
  background-color: #2f241b;
}

.hero.is-info .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-info .tabs a:hover {
  opacity: 1;
}

.hero.is-info .tabs li.is-active a {
  opacity: 1;
  color: #3f3024 !important;
}

.hero.is-info .tabs.is-boxed a, .hero.is-info .tabs.is-toggle a {
  color: #fff;
}

.hero.is-info .tabs.is-boxed a:hover, .hero.is-info .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-info .tabs.is-boxed li.is-active a, .hero.is-info .tabs.is-boxed li.is-active a:hover, .hero.is-info .tabs.is-toggle li.is-active a, .hero.is-info .tabs.is-toggle li.is-active a:hover {
  color: #3f3024;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-info.is-bold {
  background-image: linear-gradient(141deg, #21140f 0%, #3f3024 71%, #52432a 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-info.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #21140f 0%, #3f3024 71%, #52432a 100%);
  }
}

.hero.is-success {
  color: #000000b3;
  background-color: #86ce93;
}

.hero.is-success a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-success strong {
  color: inherit;
}

.hero.is-success .title {
  color: #000000b3;
}

.hero.is-success .subtitle {
  color: #000000e6;
}

.hero.is-success .subtitle a:not(.button), .hero.is-success .subtitle strong {
  color: #000000b3;
}

@media screen and (max-width: 1023px) {
  .hero.is-success .navbar-menu {
    background-color: #86ce93;
  }
}

.hero.is-success .navbar-item, .hero.is-success .navbar-link {
  color: #000000b3;
}

.hero.is-success a.navbar-item:hover, .hero.is-success a.navbar-item.is-active, .hero.is-success .navbar-link:hover, .hero.is-success .navbar-link.is-active {
  color: #000000b3;
  background-color: #74c783;
}

.hero.is-success .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-success .tabs a:hover {
  opacity: 1;
}

.hero.is-success .tabs li.is-active a {
  opacity: 1;
  color: #86ce93 !important;
}

.hero.is-success .tabs.is-boxed a, .hero.is-success .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-success .tabs.is-boxed a:hover, .hero.is-success .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-success .tabs.is-boxed li.is-active a, .hero.is-success .tabs.is-boxed li.is-active a:hover, .hero.is-success .tabs.is-toggle li.is-active a, .hero.is-success .tabs.is-toggle li.is-active a:hover {
  color: #86ce93;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-success.is-bold {
  background-image: linear-gradient(141deg, #57ca58 0%, #86ce93 71%, #95d9ac 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-success.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #57ca58 0%, #86ce93 71%, #95d9ac 100%);
  }
}

.hero.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.hero.is-warning a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-warning strong {
  color: inherit;
}

.hero.is-warning .title {
  color: #000000b3;
}

.hero.is-warning .subtitle {
  color: #000000e6;
}

.hero.is-warning .subtitle a:not(.button), .hero.is-warning .subtitle strong {
  color: #000000b3;
}

@media screen and (max-width: 1023px) {
  .hero.is-warning .navbar-menu {
    background-color: #ffe08a;
  }
}

.hero.is-warning .navbar-item, .hero.is-warning .navbar-link {
  color: #000000b3;
}

.hero.is-warning a.navbar-item:hover, .hero.is-warning a.navbar-item.is-active, .hero.is-warning .navbar-link:hover, .hero.is-warning .navbar-link.is-active {
  color: #000000b3;
  background-color: #ffd970;
}

.hero.is-warning .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-warning .tabs a:hover {
  opacity: 1;
}

.hero.is-warning .tabs li.is-active a {
  opacity: 1;
  color: #ffe08a !important;
}

.hero.is-warning .tabs.is-boxed a, .hero.is-warning .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-warning .tabs.is-boxed a:hover, .hero.is-warning .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-warning .tabs.is-boxed li.is-active a, .hero.is-warning .tabs.is-boxed li.is-active a:hover, .hero.is-warning .tabs.is-toggle li.is-active a, .hero.is-warning .tabs.is-toggle li.is-active a:hover {
  color: #ffe08a;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-warning.is-bold {
  background-image: linear-gradient(141deg, #ffb657 0%, #ffe08a 71%, #fff6a3 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-warning.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ffb657 0%, #ffe08a 71%, #fff6a3 100%);
  }
}

.hero.is-danger {
  color: #fff;
  background-color: #843e37;
}

.hero.is-danger a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-danger strong {
  color: inherit;
}

.hero.is-danger .title {
  color: #fff;
}

.hero.is-danger .subtitle {
  color: #ffffffe6;
}

.hero.is-danger .subtitle a:not(.button), .hero.is-danger .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-danger .navbar-menu {
    background-color: #843e37;
  }
}

.hero.is-danger .navbar-item, .hero.is-danger .navbar-link {
  color: #ffffffb3;
}

.hero.is-danger a.navbar-item:hover, .hero.is-danger a.navbar-item.is-active, .hero.is-danger .navbar-link:hover, .hero.is-danger .navbar-link.is-active {
  color: #fff;
  background-color: #723630;
}

.hero.is-danger .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-danger .tabs a:hover {
  opacity: 1;
}

.hero.is-danger .tabs li.is-active a {
  opacity: 1;
  color: #843e37 !important;
}

.hero.is-danger .tabs.is-boxed a, .hero.is-danger .tabs.is-toggle a {
  color: #fff;
}

.hero.is-danger .tabs.is-boxed a:hover, .hero.is-danger .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-danger .tabs.is-boxed li.is-active a, .hero.is-danger .tabs.is-boxed li.is-active a:hover, .hero.is-danger .tabs.is-toggle li.is-active a, .hero.is-danger .tabs.is-toggle li.is-active a:hover {
  color: #843e37;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-danger.is-bold {
  background-image: linear-gradient(141deg, #672126 0%, #843e37 71%, #9b5239 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-danger.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #672126 0%, #843e37 71%, #9b5239 100%);
  }
}

.hero.is-small .hero-body {
  padding: 1.5rem;
}

@media screen and (min-width: 769px), print {
  .hero.is-medium .hero-body {
    padding: 9rem 4.5rem;
  }

  .hero.is-large .hero-body {
    padding: 18rem 6rem;
  }
}

.hero.is-halfheight .hero-body, .hero.is-fullheight .hero-body, .hero.is-fullheight-with-navbar .hero-body {
  align-items: center;
  display: flex;
}

.hero.is-halfheight .hero-body > .container, .hero.is-fullheight .hero-body > .container, .hero.is-fullheight-with-navbar .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}

.hero.is-halfheight {
  min-height: 50vh;
}

.hero.is-fullheight {
  min-height: 100vh;
}

.hero-video {
  overflow: hidden;
}

.hero-video video {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.hero-video.is-transparent {
  opacity: .3;
}

@media screen and (max-width: 768px) {
  .hero-video {
    display: none;
  }
}

.hero-buttons {
  margin-top: 1.5rem;
}

@media screen and (max-width: 768px) {
  .hero-buttons .button {
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-bottom: .75rem;
  }
}

@media screen and (min-width: 769px), print {
  .hero-buttons {
    justify-content: center;
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.hero-head, .hero-foot {
  flex-grow: 0;
  flex-shrink: 0;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
}

@media screen and (min-width: 769px), print {
  .hero-body {
    padding: 3rem;
  }
}

.section {
  padding: 3rem 1.5rem;
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 3rem;
  }

  .section.is-medium {
    padding: 9rem 4.5rem;
  }

  .section.is-large {
    padding: 18rem 6rem;
  }
}

.pageloader {
  z-index: 999998;
  will-change: transform;
  background: #264d4a;
  padding-top: 2em;
  transition: transform .35s ease-out, -webkit-transform .35s ease-out;
  position: fixed;
  inset: 0;
}

.pageloader.is-white {
  background: #fff;
}

.pageloader.is-white:after {
  border-color: #0a0a0a;
  animation: 1.15s cubic-bezier(.215, .61, .355, 1) infinite loader-figure-white;
}

.pageloader.is-white .title {
  color: #0a0a0a;
}

.pageloader.is-black {
  background: #0a0a0a;
}

.pageloader.is-black:after {
  border-color: #fff;
  animation: 1.15s cubic-bezier(.215, .61, .355, 1) infinite loader-figure-black;
}

.pageloader.is-black .title {
  color: #fff;
}

.pageloader.is-light {
  background: #f5f5f5;
}

.pageloader.is-light:after {
  border-color: #000000b3;
  animation: 1.15s cubic-bezier(.215, .61, .355, 1) infinite loader-figure-light;
}

.pageloader.is-light .title {
  color: #000000b3;
}

.pageloader.is-dark {
  background: #271f26;
}

.pageloader.is-dark:after {
  border-color: #fff;
  animation: 1.15s cubic-bezier(.215, .61, .355, 1) infinite loader-figure-dark;
}

.pageloader.is-dark .title {
  color: #fff;
}

.pageloader.is-primary {
  background: #264d4a;
}

.pageloader.is-primary:after {
  border-color: #fff;
  animation: 1.15s cubic-bezier(.215, .61, .355, 1) infinite loader-figure-primary;
}

.pageloader.is-primary .title {
  color: #fff;
}

.pageloader.is-link {
  background: #ad7d59;
}

.pageloader.is-link:after {
  border-color: #fff;
  animation: 1.15s cubic-bezier(.215, .61, .355, 1) infinite loader-figure-link;
}

.pageloader.is-link .title {
  color: #fff;
}

.pageloader.is-info {
  background: #3f3024;
}

.pageloader.is-info:after {
  border-color: #fff;
  animation: 1.15s cubic-bezier(.215, .61, .355, 1) infinite loader-figure-info;
}

.pageloader.is-info .title {
  color: #fff;
}

.pageloader.is-success {
  background: #86ce93;
}

.pageloader.is-success:after {
  border-color: #000000b3;
  animation: 1.15s cubic-bezier(.215, .61, .355, 1) infinite loader-figure-success;
}

.pageloader.is-success .title {
  color: #000000b3;
}

.pageloader.is-warning {
  background: #ffe08a;
}

.pageloader.is-warning:after {
  border-color: #000000b3;
  animation: 1.15s cubic-bezier(.215, .61, .355, 1) infinite loader-figure-warning;
}

.pageloader.is-warning .title {
  color: #000000b3;
}

.pageloader.is-danger {
  background: #843e37;
}

.pageloader.is-danger:after {
  border-color: #fff;
  animation: 1.15s cubic-bezier(.215, .61, .355, 1) infinite loader-figure-danger;
}

.pageloader.is-danger .title {
  color: #fff;
}

.pageloader:not(.is-bottom-to-top) {
  transform: translateY(-100%);
}

.pageloader.is-bottom-to-top {
  transform: translateY(100%);
}

.pageloader.is-left-to-right {
  transform: translateX(-100%);
}

.pageloader.is-right-to-left {
  transform: translateX(100%);
}

.pageloader.is-active:not(.is-left-to-right), .pageloader.is-active:not(.is-right-to-left) {
  transform: translateY(0);
}

.pageloader.is-active.is-left-to-right, .pageloader.is-active.is-right-to-left {
  transform: translateX(0);
}

.pageloader:after {
  content: "";
  z-index: 9999;
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 0 solid #fff;
  border-radius: 100%;
  animation: 1.15s cubic-bezier(.215, .61, .355, 1) infinite loader-figure;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pageloader .title {
  letter-spacing: .1em;
  color: #fff;
  white-space: nowrap;
  margin: 2em 0 0;
  font-size: .875em;
  line-height: 1.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    opacity: 1;
    background-color: #0000;
    border-width: 1em;
  }

  100% {
    height: 2em;
    width: 2em;
    opacity: 0;
    background-color: #0000;
    border-width: 0;
  }
}

@keyframes loader-figure-white {
  0% {
    height: 0;
    width: 0;
    background-color: #0a0a0a;
  }

  29% {
    background-color: #0a0a0a;
  }

  30% {
    height: 2em;
    width: 2em;
    opacity: 1;
    background-color: #0000;
    border-width: 1em;
  }

  100% {
    height: 2em;
    width: 2em;
    opacity: 0;
    background-color: #0000;
    border-width: 0;
  }
}

@keyframes loader-figure-black {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    opacity: 1;
    background-color: #0000;
    border-width: 1em;
  }

  100% {
    height: 2em;
    width: 2em;
    opacity: 0;
    background-color: #0000;
    border-width: 0;
  }
}

@keyframes loader-figure-light {
  0% {
    height: 0;
    width: 0;
    background-color: #000000b3;
  }

  29% {
    background-color: #000000b3;
  }

  30% {
    height: 2em;
    width: 2em;
    opacity: 1;
    background-color: #0000;
    border-width: 1em;
  }

  100% {
    height: 2em;
    width: 2em;
    opacity: 0;
    background-color: #0000;
    border-width: 0;
  }
}

@keyframes loader-figure-dark {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    opacity: 1;
    background-color: #0000;
    border-width: 1em;
  }

  100% {
    height: 2em;
    width: 2em;
    opacity: 0;
    background-color: #0000;
    border-width: 0;
  }
}

@keyframes loader-figure-primary {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    opacity: 1;
    background-color: #0000;
    border-width: 1em;
  }

  100% {
    height: 2em;
    width: 2em;
    opacity: 0;
    background-color: #0000;
    border-width: 0;
  }
}

@keyframes loader-figure-link {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    opacity: 1;
    background-color: #0000;
    border-width: 1em;
  }

  100% {
    height: 2em;
    width: 2em;
    opacity: 0;
    background-color: #0000;
    border-width: 0;
  }
}

@keyframes loader-figure-info {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    opacity: 1;
    background-color: #0000;
    border-width: 1em;
  }

  100% {
    height: 2em;
    width: 2em;
    opacity: 0;
    background-color: #0000;
    border-width: 0;
  }
}

@keyframes loader-figure-success {
  0% {
    height: 0;
    width: 0;
    background-color: #000000b3;
  }

  29% {
    background-color: #000000b3;
  }

  30% {
    height: 2em;
    width: 2em;
    opacity: 1;
    background-color: #0000;
    border-width: 1em;
  }

  100% {
    height: 2em;
    width: 2em;
    opacity: 0;
    background-color: #0000;
    border-width: 0;
  }
}

@keyframes loader-figure-warning {
  0% {
    height: 0;
    width: 0;
    background-color: #000000b3;
  }

  29% {
    background-color: #000000b3;
  }

  30% {
    height: 2em;
    width: 2em;
    opacity: 1;
    background-color: #0000;
    border-width: 1em;
  }

  100% {
    height: 2em;
    width: 2em;
    opacity: 0;
    background-color: #0000;
    border-width: 0;
  }
}

@keyframes loader-figure-danger {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    opacity: 1;
    background-color: #0000;
    border-width: 1em;
  }

  100% {
    height: 2em;
    width: 2em;
    opacity: 0;
    background-color: #0000;
    border-width: 0;
  }
}

.steps:not(:last-child) {
  margin-bottom: 1.5rem;
}

.steps .steps-segment {
  position: relative;
}

.steps .steps-segment:not(:last-child):after {
  content: " ";
  display: block;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .steps:not(.is-horizontal) .steps-segment {
    display: block;
  }

  .steps:not(.is-horizontal) .steps-segment:not(:last-child) {
    min-height: 4em;
  }
}

.steps.is-vertical .steps-segment {
  display: block;
}

.steps.is-vertical .steps-segment:not(:last-child) {
  min-height: 4em;
}

@media screen and (min-width: 769px), print {
  .steps:not(.is-vertical) {
    display: flex;
  }

  .steps:not(.is-vertical) .steps-segment:not(:last-child) {
    flex: 1rem;
  }

  .steps:not(.is-vertical).is-narrow .steps-segment {
    flex-grow: 0;
  }

  .steps:not(.is-vertical).is-narrow .steps-segment:not(:last-child) {
    min-width: 10em;
  }

  .steps:not(.is-vertical).is-narrow.is-centered {
    justify-content: center;
  }

  .steps:not(.is-vertical).is-narrow.is-right {
    justify-content: flex-end;
  }

  .steps:not(.is-vertical).has-content-centered {
    text-align: center;
  }

  .steps:not(.is-vertical).is-balanced .steps-segment:last-child, .steps:not(.is-vertical).has-content-centered .steps-segment:last-child {
    flex: 1rem;
  }

  .steps:not(.is-vertical).is-narrow.has-content-centered .steps-segment:last-child {
    min-width: 10em;
    flex-grow: 0;
  }

  .steps:not(.is-vertical) .steps-content.is-divider-content {
    text-align: center;
  }
}

.steps.is-horizontal {
  display: flex;
}

.steps.is-horizontal .steps-segment:not(:last-child) {
  flex: 1rem;
}

.steps.is-horizontal.is-narrow .steps-segment {
  flex-grow: 0;
}

.steps.is-horizontal.is-narrow .steps-segment:not(:last-child) {
  min-width: 10em;
}

.steps.is-horizontal.is-narrow.is-centered {
  justify-content: center;
}

.steps.is-horizontal.is-narrow.is-right {
  justify-content: flex-end;
}

.steps.is-horizontal.has-content-centered {
  text-align: center;
}

.steps.is-horizontal.is-balanced .steps-segment:last-child, .steps.is-horizontal.has-content-centered .steps-segment:last-child {
  flex: 1rem;
}

.steps.is-horizontal.is-narrow.has-content-centered .steps-segment:last-child {
  min-width: 10em;
  flex-grow: 0;
}

.steps.is-horizontal .steps-content.is-divider-content {
  text-align: center;
}

.steps .steps-marker {
  z-index: 10;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  display: flex;
  position: relative;
}

.steps {
  font-size: 1rem;
}

.steps .steps-marker {
  height: 2rem;
  width: 2rem;
}

@media screen and (max-width: 768px) {
  .steps:not(.is-horizontal) .steps-segment:not(:last-child):after {
    width: .4em;
    top: 1.76rem;
    bottom: -.24rem;
    left: calc(1rem - .2em);
  }

  .steps:not(.is-horizontal) .steps-content {
    margin-top: -2rem;
    margin-left: calc(1rem + .5em);
    padding-bottom: 1em;
    padding-left: 1em;
  }

  .steps:not(.is-horizontal) .steps-content.is-divider-content {
    margin-top: 0;
    padding-bottom: 0;
  }

  .steps:not(.is-horizontal).has-content-centered .steps-content {
    padding-top: calc(1rem - .5em);
  }

  .steps:not(.is-horizontal):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps:not(.is-horizontal):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    top: 2.3rem;
    bottom: .3rem;
  }
}

.steps.is-vertical .steps-segment:not(:last-child):after {
  width: .4em;
  top: 1.76rem;
  bottom: -.24rem;
  left: calc(1rem - .2em);
}

.steps.is-vertical .steps-content {
  margin-top: -2rem;
  margin-left: calc(1rem + .5em);
  padding-bottom: 1em;
  padding-left: 1em;
}

.steps.is-vertical .steps-content.is-divider-content {
  margin-top: 0;
  padding-bottom: 0;
}

.steps.is-vertical.has-content-centered .steps-content {
  padding-top: calc(1rem - .5em);
}

.steps.is-vertical:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-vertical:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  top: 2.3rem;
  bottom: .3rem;
}

@media screen and (min-width: 769px), print {
  .steps:not(.is-vertical) .steps-segment:not(:last-child):after {
    height: .4em;
    top: calc(1rem - .2em);
    left: 1.76rem;
    right: -.24rem;
  }

  .steps:not(.is-vertical) .steps-content {
    margin-left: 1rem;
  }

  .steps:not(.is-vertical) .steps-content:not(:last-child) {
    margin-right: -1rem;
  }

  .steps:not(.is-vertical) .steps-content.is-divider-content {
    margin-right: -1rem;
    padding-left: 2em;
    padding-right: 2em;
  }

  .steps:not(.is-vertical).has-content-centered .steps-segment:not(:last-child):after {
    left: 50%;
    right: -50%;
  }

  .steps:not(.is-vertical).has-content-centered .steps-marker {
    position: absolute;
    left: calc(50% - 1rem);
  }

  .steps:not(.is-vertical).has-content-centered .steps-content {
    margin-top: 2rem;
    margin-left: .5em;
    margin-right: .5em;
    padding-top: .2em;
  }

  .steps:not(.is-vertical):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps:not(.is-vertical):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    left: 2.3rem;
    right: .3rem;
  }

  .steps:not(.is-vertical):not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps:not(.is-vertical):not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
    left: calc(50% + 1.3rem);
    right: calc(1.3rem - 50%);
  }
}

.steps.is-horizontal .steps-segment:not(:last-child):after {
  height: .4em;
  top: calc(1rem - .2em);
  left: 1.76rem;
  right: -.24rem;
}

.steps.is-horizontal .steps-content {
  margin-left: 1rem;
}

.steps.is-horizontal .steps-content:not(:last-child) {
  margin-right: -1rem;
}

.steps.is-horizontal .steps-content.is-divider-content {
  margin-right: -1rem;
  padding-left: 2em;
  padding-right: 2em;
}

.steps.is-horizontal.has-content-centered .steps-segment:not(:last-child):after {
  left: 50%;
  right: -50%;
}

.steps.is-horizontal.has-content-centered .steps-marker {
  position: absolute;
  left: calc(50% - 1rem);
}

.steps.is-horizontal.has-content-centered .steps-content {
  margin-top: 2rem;
  margin-left: .5em;
  margin-right: .5em;
  padding-top: .2em;
}

.steps.is-horizontal:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-horizontal:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  left: 2.3rem;
  right: .3rem;
}

.steps.is-horizontal:not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-horizontal:not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
  left: calc(50% + 1.3rem);
  right: calc(1.3rem - 50%);
}

.steps.is-small {
  font-size: .75rem;
}

.steps.is-small .steps-marker {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (max-width: 768px) {
  .steps.is-small:not(.is-horizontal) .steps-segment:not(:last-child):after {
    width: .4em;
    top: 1.26rem;
    bottom: -.24rem;
    left: calc(.75rem - .2em);
  }

  .steps.is-small:not(.is-horizontal) .steps-content {
    margin-top: -1.5rem;
    margin-left: calc(.75rem + .5em);
    padding-bottom: 1em;
    padding-left: 1em;
  }

  .steps.is-small:not(.is-horizontal) .steps-content.is-divider-content {
    margin-top: 0;
    padding-bottom: 0;
  }

  .steps.is-small:not(.is-horizontal).has-content-centered .steps-content {
    padding-top: calc(.75rem - .5em);
  }

  .steps.is-small:not(.is-horizontal):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-small:not(.is-horizontal):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    top: 1.8rem;
    bottom: .3rem;
  }
}

.steps.is-small.is-vertical .steps-segment:not(:last-child):after {
  width: .4em;
  top: 1.26rem;
  bottom: -.24rem;
  left: calc(.75rem - .2em);
}

.steps.is-small.is-vertical .steps-content {
  margin-top: -1.5rem;
  margin-left: calc(.75rem + .5em);
  padding-bottom: 1em;
  padding-left: 1em;
}

.steps.is-small.is-vertical .steps-content.is-divider-content {
  margin-top: 0;
  padding-bottom: 0;
}

.steps.is-small.is-vertical.has-content-centered .steps-content {
  padding-top: calc(.75rem - .5em);
}

.steps.is-small.is-vertical:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-small.is-vertical:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  top: 1.8rem;
  bottom: .3rem;
}

@media screen and (min-width: 769px), print {
  .steps.is-small:not(.is-vertical) .steps-segment:not(:last-child):after {
    height: .4em;
    top: calc(.75rem - .2em);
    left: 1.26rem;
    right: -.24rem;
  }

  .steps.is-small:not(.is-vertical) .steps-content {
    margin-left: .75rem;
  }

  .steps.is-small:not(.is-vertical) .steps-content:not(:last-child) {
    margin-right: -.75rem;
  }

  .steps.is-small:not(.is-vertical) .steps-content.is-divider-content {
    margin-right: -.75rem;
    padding-left: 2em;
    padding-right: 2em;
  }

  .steps.is-small:not(.is-vertical).has-content-centered .steps-segment:not(:last-child):after {
    left: 50%;
    right: -50%;
  }

  .steps.is-small:not(.is-vertical).has-content-centered .steps-marker {
    position: absolute;
    left: calc(50% - .75rem);
  }

  .steps.is-small:not(.is-vertical).has-content-centered .steps-content {
    margin-top: 1.5rem;
    margin-left: .5em;
    margin-right: .5em;
    padding-top: .2em;
  }

  .steps.is-small:not(.is-vertical):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-small:not(.is-vertical):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    left: 1.8rem;
    right: .3rem;
  }

  .steps.is-small:not(.is-vertical):not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-small:not(.is-vertical):not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
    left: calc(50% + 1.05rem);
    right: calc(1.05rem - 50%);
  }
}

.steps.is-small.is-horizontal .steps-segment:not(:last-child):after {
  height: .4em;
  top: calc(.75rem - .2em);
  left: 1.26rem;
  right: -.24rem;
}

.steps.is-small.is-horizontal .steps-content {
  margin-left: .75rem;
}

.steps.is-small.is-horizontal .steps-content:not(:last-child) {
  margin-right: -.75rem;
}

.steps.is-small.is-horizontal .steps-content.is-divider-content {
  margin-right: -.75rem;
  padding-left: 2em;
  padding-right: 2em;
}

.steps.is-small.is-horizontal.has-content-centered .steps-segment:not(:last-child):after {
  left: 50%;
  right: -50%;
}

.steps.is-small.is-horizontal.has-content-centered .steps-marker {
  position: absolute;
  left: calc(50% - .75rem);
}

.steps.is-small.is-horizontal.has-content-centered .steps-content {
  margin-top: 1.5rem;
  margin-left: .5em;
  margin-right: .5em;
  padding-top: .2em;
}

.steps.is-small.is-horizontal:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-small.is-horizontal:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  left: 1.8rem;
  right: .3rem;
}

.steps.is-small.is-horizontal:not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-small.is-horizontal:not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
  left: calc(50% + 1.05rem);
  right: calc(1.05rem - 50%);
}

.steps.is-medium {
  font-size: 1.25rem;
}

.steps.is-medium .steps-marker {
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (max-width: 768px) {
  .steps.is-medium:not(.is-horizontal) .steps-segment:not(:last-child):after {
    width: .4em;
    top: 2.26rem;
    bottom: -.24rem;
    left: calc(1.25rem - .2em);
  }

  .steps.is-medium:not(.is-horizontal) .steps-content {
    margin-top: -2.5rem;
    margin-left: calc(1.25rem + .5em);
    padding-bottom: 1em;
    padding-left: 1em;
  }

  .steps.is-medium:not(.is-horizontal) .steps-content.is-divider-content {
    margin-top: 0;
    padding-bottom: 0;
  }

  .steps.is-medium:not(.is-horizontal).has-content-centered .steps-content {
    padding-top: calc(1.25rem - .5em);
  }

  .steps.is-medium:not(.is-horizontal):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-medium:not(.is-horizontal):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    top: 2.8rem;
    bottom: .3rem;
  }
}

.steps.is-medium.is-vertical .steps-segment:not(:last-child):after {
  width: .4em;
  top: 2.26rem;
  bottom: -.24rem;
  left: calc(1.25rem - .2em);
}

.steps.is-medium.is-vertical .steps-content {
  margin-top: -2.5rem;
  margin-left: calc(1.25rem + .5em);
  padding-bottom: 1em;
  padding-left: 1em;
}

.steps.is-medium.is-vertical .steps-content.is-divider-content {
  margin-top: 0;
  padding-bottom: 0;
}

.steps.is-medium.is-vertical.has-content-centered .steps-content {
  padding-top: calc(1.25rem - .5em);
}

.steps.is-medium.is-vertical:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-medium.is-vertical:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  top: 2.8rem;
  bottom: .3rem;
}

@media screen and (min-width: 769px), print {
  .steps.is-medium:not(.is-vertical) .steps-segment:not(:last-child):after {
    height: .4em;
    top: calc(1.25rem - .2em);
    left: 2.26rem;
    right: -.24rem;
  }

  .steps.is-medium:not(.is-vertical) .steps-content {
    margin-left: 1.25rem;
  }

  .steps.is-medium:not(.is-vertical) .steps-content:not(:last-child) {
    margin-right: -1.25rem;
  }

  .steps.is-medium:not(.is-vertical) .steps-content.is-divider-content {
    margin-right: -1.25rem;
    padding-left: 2em;
    padding-right: 2em;
  }

  .steps.is-medium:not(.is-vertical).has-content-centered .steps-segment:not(:last-child):after {
    left: 50%;
    right: -50%;
  }

  .steps.is-medium:not(.is-vertical).has-content-centered .steps-marker {
    position: absolute;
    left: calc(50% - 1.25rem);
  }

  .steps.is-medium:not(.is-vertical).has-content-centered .steps-content {
    margin-top: 2.5rem;
    margin-left: .5em;
    margin-right: .5em;
    padding-top: .2em;
  }

  .steps.is-medium:not(.is-vertical):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-medium:not(.is-vertical):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    left: 2.8rem;
    right: .3rem;
  }

  .steps.is-medium:not(.is-vertical):not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-medium:not(.is-vertical):not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
    left: calc(50% + 1.55rem);
    right: calc(1.55rem - 50%);
  }
}

.steps.is-medium.is-horizontal .steps-segment:not(:last-child):after {
  height: .4em;
  top: calc(1.25rem - .2em);
  left: 2.26rem;
  right: -.24rem;
}

.steps.is-medium.is-horizontal .steps-content {
  margin-left: 1.25rem;
}

.steps.is-medium.is-horizontal .steps-content:not(:last-child) {
  margin-right: -1.25rem;
}

.steps.is-medium.is-horizontal .steps-content.is-divider-content {
  margin-right: -1.25rem;
  padding-left: 2em;
  padding-right: 2em;
}

.steps.is-medium.is-horizontal.has-content-centered .steps-segment:not(:last-child):after {
  left: 50%;
  right: -50%;
}

.steps.is-medium.is-horizontal.has-content-centered .steps-marker {
  position: absolute;
  left: calc(50% - 1.25rem);
}

.steps.is-medium.is-horizontal.has-content-centered .steps-content {
  margin-top: 2.5rem;
  margin-left: .5em;
  margin-right: .5em;
  padding-top: .2em;
}

.steps.is-medium.is-horizontal:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-medium.is-horizontal:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  left: 2.8rem;
  right: .3rem;
}

.steps.is-medium.is-horizontal:not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-medium.is-horizontal:not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
  left: calc(50% + 1.55rem);
  right: calc(1.55rem - 50%);
}

.steps.is-large {
  font-size: 1.5rem;
}

.steps.is-large .steps-marker {
  height: 3rem;
  width: 3rem;
}

@media screen and (max-width: 768px) {
  .steps.is-large:not(.is-horizontal) .steps-segment:not(:last-child):after {
    width: .4em;
    top: 2.76rem;
    bottom: -.24rem;
    left: calc(1.5rem - .2em);
  }

  .steps.is-large:not(.is-horizontal) .steps-content {
    margin-top: -3rem;
    margin-left: calc(1.5rem + .5em);
    padding-bottom: 1em;
    padding-left: 1em;
  }

  .steps.is-large:not(.is-horizontal) .steps-content.is-divider-content {
    margin-top: 0;
    padding-bottom: 0;
  }

  .steps.is-large:not(.is-horizontal).has-content-centered .steps-content {
    padding-top: calc(1.5rem - .5em);
  }

  .steps.is-large:not(.is-horizontal):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-large:not(.is-horizontal):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    top: 3.3rem;
    bottom: .3rem;
  }
}

.steps.is-large.is-vertical .steps-segment:not(:last-child):after {
  width: .4em;
  top: 2.76rem;
  bottom: -.24rem;
  left: calc(1.5rem - .2em);
}

.steps.is-large.is-vertical .steps-content {
  margin-top: -3rem;
  margin-left: calc(1.5rem + .5em);
  padding-bottom: 1em;
  padding-left: 1em;
}

.steps.is-large.is-vertical .steps-content.is-divider-content {
  margin-top: 0;
  padding-bottom: 0;
}

.steps.is-large.is-vertical.has-content-centered .steps-content {
  padding-top: calc(1.5rem - .5em);
}

.steps.is-large.is-vertical:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-large.is-vertical:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  top: 3.3rem;
  bottom: .3rem;
}

@media screen and (min-width: 769px), print {
  .steps.is-large:not(.is-vertical) .steps-segment:not(:last-child):after {
    height: .4em;
    top: calc(1.5rem - .2em);
    left: 2.76rem;
    right: -.24rem;
  }

  .steps.is-large:not(.is-vertical) .steps-content {
    margin-left: 1.5rem;
  }

  .steps.is-large:not(.is-vertical) .steps-content:not(:last-child) {
    margin-right: -1.5rem;
  }

  .steps.is-large:not(.is-vertical) .steps-content.is-divider-content {
    margin-right: -1.5rem;
    padding-left: 2em;
    padding-right: 2em;
  }

  .steps.is-large:not(.is-vertical).has-content-centered .steps-segment:not(:last-child):after {
    left: 50%;
    right: -50%;
  }

  .steps.is-large:not(.is-vertical).has-content-centered .steps-marker {
    position: absolute;
    left: calc(50% - 1.5rem);
  }

  .steps.is-large:not(.is-vertical).has-content-centered .steps-content {
    margin-top: 3rem;
    margin-left: .5em;
    margin-right: .5em;
    padding-top: .2em;
  }

  .steps.is-large:not(.is-vertical):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-large:not(.is-vertical):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    left: 3.3rem;
    right: .3rem;
  }

  .steps.is-large:not(.is-vertical):not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-large:not(.is-vertical):not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
    left: calc(50% + 1.8rem);
    right: calc(1.8rem - 50%);
  }
}

.steps.is-large.is-horizontal .steps-segment:not(:last-child):after {
  height: .4em;
  top: calc(1.5rem - .2em);
  left: 2.76rem;
  right: -.24rem;
}

.steps.is-large.is-horizontal .steps-content {
  margin-left: 1.5rem;
}

.steps.is-large.is-horizontal .steps-content:not(:last-child) {
  margin-right: -1.5rem;
}

.steps.is-large.is-horizontal .steps-content.is-divider-content {
  margin-right: -1.5rem;
  padding-left: 2em;
  padding-right: 2em;
}

.steps.is-large.is-horizontal.has-content-centered .steps-segment:not(:last-child):after {
  left: 50%;
  right: -50%;
}

.steps.is-large.is-horizontal.has-content-centered .steps-marker {
  position: absolute;
  left: calc(50% - 1.5rem);
}

.steps.is-large.is-horizontal.has-content-centered .steps-content {
  margin-top: 3rem;
  margin-left: .5em;
  margin-right: .5em;
  padding-top: .2em;
}

.steps.is-large.is-horizontal:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-large.is-horizontal:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  left: 3.3rem;
  right: .3rem;
}

.steps.is-large.is-horizontal:not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-large.is-horizontal:not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
  left: calc(50% + 1.8rem);
  right: calc(1.8rem - 50%);
}

.steps-segment:after {
  background-color: #264d4a;
}

.steps-segment.is-active:after, .steps-segment.is-active ~ .steps-segment:after {
  background-color: #dbdbdb;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow), .steps:not(.is-hollow) .steps-segment.is-active .steps-marker:not(.is-hollow) {
  color: #fff;
  background-color: #264d4a;
}

.steps:not(.is-hollow) .steps-segment.is-active ~ .steps-segment .steps-marker:not(.is-hollow) {
  color: #000000b3;
  background-color: #dbdbdb;
}

.steps.is-hollow .steps-marker, .steps-marker.is-hollow {
  border: .3em solid #264d4a;
}

.steps.is-hollow .is-active .steps-marker, .steps .is-active .steps-marker.is-hollow {
  border-color: #264d4a;
}

.steps.is-hollow .steps-segment.is-active ~ .steps-segment .steps-marker, .steps-segment.is-active ~ .steps-segment .steps-marker.is-hollow {
  border-color: #dbdbdb;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-white {
  color: #0a0a0a !important;
  background-color: #fff !important;
}

.steps.is-hollow .steps-marker.is-white, .steps .steps-marker.is-hollow.is-white {
  border-color: #fff !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-black {
  color: #fff !important;
  background-color: #0a0a0a !important;
}

.steps.is-hollow .steps-marker.is-black, .steps .steps-marker.is-hollow.is-black {
  border-color: #0a0a0a !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-light {
  color: #000000b3 !important;
  background-color: #f5f5f5 !important;
}

.steps.is-hollow .steps-marker.is-light, .steps .steps-marker.is-hollow.is-light {
  border-color: #f5f5f5 !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-dark {
  color: #fff !important;
  background-color: #271f26 !important;
}

.steps.is-hollow .steps-marker.is-dark, .steps .steps-marker.is-hollow.is-dark {
  border-color: #271f26 !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-primary {
  color: #fff !important;
  background-color: #264d4a !important;
}

.steps.is-hollow .steps-marker.is-primary, .steps .steps-marker.is-hollow.is-primary {
  border-color: #264d4a !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-link {
  color: #fff !important;
  background-color: #ad7d59 !important;
}

.steps.is-hollow .steps-marker.is-link, .steps .steps-marker.is-hollow.is-link {
  border-color: #ad7d59 !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-info {
  color: #fff !important;
  background-color: #3f3024 !important;
}

.steps.is-hollow .steps-marker.is-info, .steps .steps-marker.is-hollow.is-info {
  border-color: #3f3024 !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-success {
  color: #000000b3 !important;
  background-color: #86ce93 !important;
}

.steps.is-hollow .steps-marker.is-success, .steps .steps-marker.is-hollow.is-success {
  border-color: #86ce93 !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-warning {
  color: #000000b3 !important;
  background-color: #ffe08a !important;
}

.steps.is-hollow .steps-marker.is-warning, .steps .steps-marker.is-hollow.is-warning {
  border-color: #ffe08a !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-danger {
  color: #fff !important;
  background-color: #843e37 !important;
}

.steps.is-hollow .steps-marker.is-danger, .steps .steps-marker.is-hollow.is-danger {
  border-color: #843e37 !important;
}

@media screen and (max-width: 768px) {
  .steps:not(.is-horizontal).is-dashed .steps-segment:after, .steps:not(.is-horizontal) .steps-segment.is-dashed:after {
    background: repeating-linear-gradient(0deg, #264d4a, #264d4a 5px, #0000 5px 10px);
  }

  .steps:not(.is-horizontal).is-dashed .steps-segment.is-active:after, .steps:not(.is-horizontal).is-dashed .steps-segment.is-active ~ .steps-segment:after, .steps:not(.is-horizontal) .steps-segment.is-active.is-dashed:after, .steps:not(.is-horizontal) .steps-segment.is-active ~ .steps-segment.is-dashed:after {
    background: repeating-linear-gradient(0deg, #dbdbdb, #dbdbdb 5px, #0000 5px 10px);
  }
}

.steps.is-vertical.is-dashed .steps-segment:after, .steps.is-vertical .steps-segment.is-dashed:after {
  background: repeating-linear-gradient(0deg, #264d4a, #264d4a 5px, #0000 5px 10px);
}

.steps.is-vertical.is-dashed .steps-segment.is-active:after, .steps.is-vertical.is-dashed .steps-segment.is-active ~ .steps-segment:after, .steps.is-vertical .steps-segment.is-active.is-dashed:after, .steps.is-vertical .steps-segment.is-active ~ .steps-segment.is-dashed:after {
  background: repeating-linear-gradient(0deg, #dbdbdb, #dbdbdb 5px, #0000 5px 10px);
}

@media screen and (min-width: 769px), print {
  .steps:not(.is-vertical).is-dashed .steps-segment:after, .steps:not(.is-vertical) .steps-segment.is-dashed:after {
    background: repeating-linear-gradient(90deg, #264d4a, #264d4a 10px, #0000 10px 20px);
  }

  .steps:not(.is-vertical).is-dashed .steps-segment.is-active:after, .steps:not(.is-vertical).is-dashed .steps-segment.is-active ~ .steps-segment:after, .steps:not(.is-vertical) .steps-segment.is-active.is-dashed:after, .steps:not(.is-vertical) .steps-segment.is-active ~ .steps-segment.is-dashed:after {
    background: repeating-linear-gradient(90deg, #dbdbdb, #dbdbdb 10px, #0000 10px 20px);
  }
}

.steps.is-horizontal.is-dashed .steps-segment:after, .steps.is-horizontal .steps-segment.is-dashed:after {
  background: repeating-linear-gradient(90deg, #264d4a, #264d4a 10px, #0000 10px 20px);
}

.steps.is-horizontal.is-dashed .steps-segment.is-active:after, .steps.is-horizontal.is-dashed .steps-segment.is-active ~ .steps-segment:after, .steps.is-horizontal .steps-segment.is-active.is-dashed:after, .steps.is-horizontal .steps-segment.is-active ~ .steps-segment.is-dashed:after {
  background: repeating-linear-gradient(90deg, #dbdbdb, #dbdbdb 10px, #0000 10px 20px);
}

.steps.is-thin .steps-marker {
  width: .8em;
  height: .8em;
}

.steps.is-thin.is-hollow .steps-marker, .steps.is-thin .steps-marker.is-hollow {
  height: calc(.8em + 1px);
  width: calc(.8em + 1px);
  border-width: 1px;
}

@media screen and (max-width: 768px) {
  .steps.is-thin:not(.is-horizontal) .steps-segment:not(:last-child):after {
    width: 1px;
    top: .8em;
    bottom: 0;
    left: calc(.4em - .5px);
  }

  .steps.is-thin:not(.is-horizontal) .steps-content {
    margin-top: -1.2em;
    margin-left: .8em;
  }

  .steps.is-thin:not(.is-horizontal).has-gaps .steps-segment:not(:last-child):after, .steps.is-thin:not(.is-horizontal) .steps-segment.has-gaps:not(:last-child):after {
    top: 1.2em;
    bottom: .4em;
  }

  .steps.is-thin:not(.is-horizontal).has-content-centered .steps-content {
    padding-top: 1.6em;
  }
}

.steps.is-thin.is-vertical .steps-segment:not(:last-child):after {
  width: 1px;
  top: .8em;
  bottom: 0;
  left: calc(.4em - .5px);
}

.steps.is-thin.is-vertical .steps-content {
  margin-top: -1.2em;
  margin-left: .8em;
}

.steps.is-thin.is-vertical.has-gaps .steps-segment:not(:last-child):after, .steps.is-thin.is-vertical .steps-segment.has-gaps:not(:last-child):after {
  top: 1.2em;
  bottom: .4em;
}

.steps.is-thin.is-vertical.has-content-centered .steps-content {
  padding-top: 1.6em;
}

@media screen and (min-width: 769px), print {
  .steps.is-thin:not(.is-vertical) .steps-segment:not(:last-child):after {
    height: 1px;
    top: calc(.4em - .5px);
    left: .8em;
    right: 0;
  }

  .steps.is-thin:not(.is-vertical) .steps-content {
    margin-top: .8em;
  }

  .steps.is-thin:not(.is-vertical).has-content-centered .steps-segment:not(:last-child):after {
    left: calc(50% + .4em);
    right: calc(.4em - 50%);
  }

  .steps.is-thin:not(.is-vertical).has-content-centered .steps-marker {
    position: absolute;
    left: calc(50% - .4em);
  }

  .steps.is-thin:not(.is-vertical).has-gaps .steps-segment:not(:last-child):after, .steps.is-thin:not(.is-vertical) .steps-segment.has-gaps:not(:last-child):after {
    left: 1.2em;
    right: .4em;
  }

  .steps.is-thin:not(.is-vertical).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-thin:not(.is-vertical).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
    left: calc(50% + .8em);
    right: calc(.8em - 50%);
  }
}

.steps.is-thin.is-horizontal .steps-segment:not(:last-child):after {
  height: 1px;
  top: calc(.4em - .5px);
  left: .8em;
  right: 0;
}

.steps.is-thin.is-horizontal .steps-content {
  margin-top: .8em;
}

.steps.is-thin.is-horizontal.has-content-centered .steps-segment:not(:last-child):after {
  left: calc(50% + .4em);
  right: calc(.4em - 50%);
}

.steps.is-thin.is-horizontal.has-content-centered .steps-marker {
  position: absolute;
  left: calc(50% - .4em);
}

.steps.is-thin.is-horizontal.has-gaps .steps-segment:not(:last-child):after, .steps.is-thin.is-horizontal .steps-segment.has-gaps:not(:last-child):after {
  left: 1.2em;
  right: .4em;
}

.steps.is-thin.is-horizontal.has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-thin.is-horizontal.has-content-centered .steps-segment.has-gaps:not(:last-child):after {
  left: calc(50% + .8em);
  right: calc(.8em - 50%);
}

[data-tooltip]:not(.is-loading), [data-tooltip]:not(.is-disabled), [data-tooltip]:not([disabled]) {
  cursor: pointer;
  position: relative;
  overflow: visible;
}

[data-tooltip]:not(.is-loading):before, [data-tooltip]:not(.is-disabled):before, [data-tooltip]:not([disabled]):before {
  box-sizing: border-box;
  color: #fff;
  -webkit-hyphens: auto;
  hyphens: auto;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: 1020;
  content: attr(data-tooltip);
  text-overflow: ellipsis;
  white-space: pre;
  background: #4a4a4ae6;
  border-radius: 2px;
  margin-top: -5px;
  margin-bottom: auto;
  padding: .5rem 1rem;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: .75rem;
  display: inline-block;
  position: absolute;
  inset: 0 auto auto 50%;
  overflow: hidden;
  transform: translate(-50%, -100%);
}

[data-tooltip]:not(.is-loading).has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-arrow:after {
  box-sizing: border-box;
  color: #fff;
  -webkit-hyphens: auto;
  hyphens: auto;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: 1020;
  content: "";
  border: 6px solid #0000;
  border-top-color: #4a4a4ae6;
  margin-bottom: -5px;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: .75rem;
  display: inline-block;
  position: absolute;
  overflow: hidden;
}

[data-tooltip]:not(.is-loading).has-tooltip-arrow.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-arrow.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-arrow.has-tooltip-arrow:after {
  border-color: #4a4a4ae6 #0000 #0000;
  margin: -5px auto auto -5px;
  inset: 0 auto auto 50%;
}

[data-tooltip]:not(.is-loading).has-tooltip-bottom.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-bottom.has-tooltip-arrow:after {
  border-color: #0000 #0000 #4a4a4ae6;
  margin: auto auto -5px -5px;
  inset: auto auto -1px 50%;
}

[data-tooltip]:not(.is-loading).has-tooltip-bottom:before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom:before, [data-tooltip]:not([disabled]).has-tooltip-bottom:before {
  margin-top: auto;
  margin-bottom: -5px;
  inset: auto auto 0 50%;
  transform: translate(-50%, 100%);
}

[data-tooltip]:not(.is-loading).has-tooltip-left.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-left.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-left.has-tooltip-arrow:after {
  border-color: #0000 #0000 #0000 #4a4a4ae6;
  margin: auto auto -6px -5px;
  inset: auto auto 50% 0;
}

[data-tooltip]:not(.is-loading).has-tooltip-left:before, [data-tooltip]:not(.is-disabled).has-tooltip-left:before, [data-tooltip]:not([disabled]).has-tooltip-left:before {
  inset: auto auto 50% -5px;
  transform: translate(-100%, 50%);
}

[data-tooltip]:not(.is-loading).has-tooltip-right.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-right.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-right.has-tooltip-arrow:after {
  border-color: #0000 #4a4a4ae6 #0000 #0000;
  margin: auto -6px -6px auto;
  inset: auto 0 50% auto;
}

[data-tooltip]:not(.is-loading).has-tooltip-right:before, [data-tooltip]:not(.is-disabled).has-tooltip-right:before, [data-tooltip]:not([disabled]).has-tooltip-right:before {
  margin-top: auto;
  inset: auto -5px 50% auto;
  transform: translate(100%, 50%);
}

[data-tooltip]:not(.is-loading).has-tooltip-multiline:before, [data-tooltip]:not(.is-disabled).has-tooltip-multiline:before, [data-tooltip]:not([disabled]).has-tooltip-multiline:before {
  height: auto;
  width: 15rem;
  max-width: 15rem;
  text-overflow: clip;
  white-space: normal;
  word-break: keep-all;
}

[data-tooltip]:not(.is-loading).has-tooltip-text-left:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left:before, [data-tooltip]:not([disabled]).has-tooltip-text-left:before {
  text-align: left;
}

[data-tooltip]:not(.is-loading).has-tooltip-text-centered:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered:before, [data-tooltip]:not([disabled]).has-tooltip-text-centered:before {
  text-align: center;
}

[data-tooltip]:not(.is-loading).has-tooltip-text-right:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right:before, [data-tooltip]:not([disabled]).has-tooltip-text-right:before {
  text-align: right;
}

[data-tooltip]:not(.is-loading).has-tooltip-white:after, [data-tooltip]:not(.is-disabled).has-tooltip-white:after, [data-tooltip]:not([disabled]).has-tooltip-white:after {
  border-color: #ffffffe6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-white.has-tooltip-bottom:after, [data-tooltip]:not(.is-disabled).has-tooltip-white.has-tooltip-bottom:after, [data-tooltip]:not([disabled]).has-tooltip-white.has-tooltip-bottom:after {
  border-color: #0000 #0000 #ffffffe6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-white.has-tooltip-left:after, [data-tooltip]:not(.is-disabled).has-tooltip-white.has-tooltip-left:after, [data-tooltip]:not([disabled]).has-tooltip-white.has-tooltip-left:after {
  border-color: #0000 #0000 #0000 #ffffffe6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-white.has-tooltip-right:after, [data-tooltip]:not(.is-disabled).has-tooltip-white.has-tooltip-right:after, [data-tooltip]:not([disabled]).has-tooltip-white.has-tooltip-right:after {
  border-color: #0000 #ffffffe6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-white:before, [data-tooltip]:not(.is-disabled).has-tooltip-white:before, [data-tooltip]:not([disabled]).has-tooltip-white:before {
  color: #0a0a0a;
  background-color: #ffffffe6;
}

[data-tooltip]:not(.is-loading).has-tooltip-black:after, [data-tooltip]:not(.is-disabled).has-tooltip-black:after, [data-tooltip]:not([disabled]).has-tooltip-black:after {
  border-color: #0a0a0ae6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-black.has-tooltip-bottom:after, [data-tooltip]:not(.is-disabled).has-tooltip-black.has-tooltip-bottom:after, [data-tooltip]:not([disabled]).has-tooltip-black.has-tooltip-bottom:after {
  border-color: #0000 #0000 #0a0a0ae6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-black.has-tooltip-left:after, [data-tooltip]:not(.is-disabled).has-tooltip-black.has-tooltip-left:after, [data-tooltip]:not([disabled]).has-tooltip-black.has-tooltip-left:after {
  border-color: #0000 #0000 #0000 #0a0a0ae6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-black.has-tooltip-right:after, [data-tooltip]:not(.is-disabled).has-tooltip-black.has-tooltip-right:after, [data-tooltip]:not([disabled]).has-tooltip-black.has-tooltip-right:after {
  border-color: #0000 #0a0a0ae6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-black:before, [data-tooltip]:not(.is-disabled).has-tooltip-black:before, [data-tooltip]:not([disabled]).has-tooltip-black:before {
  color: #fff;
  background-color: #0a0a0ae6;
}

[data-tooltip]:not(.is-loading).has-tooltip-light:after, [data-tooltip]:not(.is-disabled).has-tooltip-light:after, [data-tooltip]:not([disabled]).has-tooltip-light:after {
  border-color: #f5f5f5e6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-light.has-tooltip-bottom:after, [data-tooltip]:not(.is-disabled).has-tooltip-light.has-tooltip-bottom:after, [data-tooltip]:not([disabled]).has-tooltip-light.has-tooltip-bottom:after {
  border-color: #0000 #0000 #f5f5f5e6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-light.has-tooltip-left:after, [data-tooltip]:not(.is-disabled).has-tooltip-light.has-tooltip-left:after, [data-tooltip]:not([disabled]).has-tooltip-light.has-tooltip-left:after {
  border-color: #0000 #0000 #0000 #f5f5f5e6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-light.has-tooltip-right:after, [data-tooltip]:not(.is-disabled).has-tooltip-light.has-tooltip-right:after, [data-tooltip]:not([disabled]).has-tooltip-light.has-tooltip-right:after {
  border-color: #0000 #f5f5f5e6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-light:before, [data-tooltip]:not(.is-disabled).has-tooltip-light:before, [data-tooltip]:not([disabled]).has-tooltip-light:before {
  color: #000000b3;
  background-color: #f5f5f5e6;
}

[data-tooltip]:not(.is-loading).has-tooltip-dark:after, [data-tooltip]:not(.is-disabled).has-tooltip-dark:after, [data-tooltip]:not([disabled]).has-tooltip-dark:after {
  border-color: #271f26e6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-dark.has-tooltip-bottom:after, [data-tooltip]:not(.is-disabled).has-tooltip-dark.has-tooltip-bottom:after, [data-tooltip]:not([disabled]).has-tooltip-dark.has-tooltip-bottom:after {
  border-color: #0000 #0000 #271f26e6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-dark.has-tooltip-left:after, [data-tooltip]:not(.is-disabled).has-tooltip-dark.has-tooltip-left:after, [data-tooltip]:not([disabled]).has-tooltip-dark.has-tooltip-left:after {
  border-color: #0000 #0000 #0000 #271f26e6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-dark.has-tooltip-right:after, [data-tooltip]:not(.is-disabled).has-tooltip-dark.has-tooltip-right:after, [data-tooltip]:not([disabled]).has-tooltip-dark.has-tooltip-right:after {
  border-color: #0000 #271f26e6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-dark:before, [data-tooltip]:not(.is-disabled).has-tooltip-dark:before, [data-tooltip]:not([disabled]).has-tooltip-dark:before {
  color: #fff;
  background-color: #271f26e6;
}

[data-tooltip]:not(.is-loading).has-tooltip-primary:after, [data-tooltip]:not(.is-disabled).has-tooltip-primary:after, [data-tooltip]:not([disabled]).has-tooltip-primary:after {
  border-color: #264d4ae6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-primary.has-tooltip-bottom:after, [data-tooltip]:not(.is-disabled).has-tooltip-primary.has-tooltip-bottom:after, [data-tooltip]:not([disabled]).has-tooltip-primary.has-tooltip-bottom:after {
  border-color: #0000 #0000 #264d4ae6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-primary.has-tooltip-left:after, [data-tooltip]:not(.is-disabled).has-tooltip-primary.has-tooltip-left:after, [data-tooltip]:not([disabled]).has-tooltip-primary.has-tooltip-left:after {
  border-color: #0000 #0000 #0000 #264d4ae6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-primary.has-tooltip-right:after, [data-tooltip]:not(.is-disabled).has-tooltip-primary.has-tooltip-right:after, [data-tooltip]:not([disabled]).has-tooltip-primary.has-tooltip-right:after {
  border-color: #0000 #264d4ae6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-primary:before, [data-tooltip]:not(.is-disabled).has-tooltip-primary:before, [data-tooltip]:not([disabled]).has-tooltip-primary:before {
  color: #fff;
  background-color: #264d4ae6;
}

[data-tooltip]:not(.is-loading).has-tooltip-link:after, [data-tooltip]:not(.is-disabled).has-tooltip-link:after, [data-tooltip]:not([disabled]).has-tooltip-link:after {
  border-color: #ad7d59e6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-link.has-tooltip-bottom:after, [data-tooltip]:not(.is-disabled).has-tooltip-link.has-tooltip-bottom:after, [data-tooltip]:not([disabled]).has-tooltip-link.has-tooltip-bottom:after {
  border-color: #0000 #0000 #ad7d59e6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-link.has-tooltip-left:after, [data-tooltip]:not(.is-disabled).has-tooltip-link.has-tooltip-left:after, [data-tooltip]:not([disabled]).has-tooltip-link.has-tooltip-left:after {
  border-color: #0000 #0000 #0000 #ad7d59e6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-link.has-tooltip-right:after, [data-tooltip]:not(.is-disabled).has-tooltip-link.has-tooltip-right:after, [data-tooltip]:not([disabled]).has-tooltip-link.has-tooltip-right:after {
  border-color: #0000 #ad7d59e6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-link:before, [data-tooltip]:not(.is-disabled).has-tooltip-link:before, [data-tooltip]:not([disabled]).has-tooltip-link:before {
  color: #fff;
  background-color: #ad7d59e6;
}

[data-tooltip]:not(.is-loading).has-tooltip-info:after, [data-tooltip]:not(.is-disabled).has-tooltip-info:after, [data-tooltip]:not([disabled]).has-tooltip-info:after {
  border-color: #3f3024e6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-info.has-tooltip-bottom:after, [data-tooltip]:not(.is-disabled).has-tooltip-info.has-tooltip-bottom:after, [data-tooltip]:not([disabled]).has-tooltip-info.has-tooltip-bottom:after {
  border-color: #0000 #0000 #3f3024e6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-info.has-tooltip-left:after, [data-tooltip]:not(.is-disabled).has-tooltip-info.has-tooltip-left:after, [data-tooltip]:not([disabled]).has-tooltip-info.has-tooltip-left:after {
  border-color: #0000 #0000 #0000 #3f3024e6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-info.has-tooltip-right:after, [data-tooltip]:not(.is-disabled).has-tooltip-info.has-tooltip-right:after, [data-tooltip]:not([disabled]).has-tooltip-info.has-tooltip-right:after {
  border-color: #0000 #3f3024e6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-info:before, [data-tooltip]:not(.is-disabled).has-tooltip-info:before, [data-tooltip]:not([disabled]).has-tooltip-info:before {
  color: #fff;
  background-color: #3f3024e6;
}

[data-tooltip]:not(.is-loading).has-tooltip-success:after, [data-tooltip]:not(.is-disabled).has-tooltip-success:after, [data-tooltip]:not([disabled]).has-tooltip-success:after {
  border-color: #86ce93e6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-success.has-tooltip-bottom:after, [data-tooltip]:not(.is-disabled).has-tooltip-success.has-tooltip-bottom:after, [data-tooltip]:not([disabled]).has-tooltip-success.has-tooltip-bottom:after {
  border-color: #0000 #0000 #86ce93e6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-success.has-tooltip-left:after, [data-tooltip]:not(.is-disabled).has-tooltip-success.has-tooltip-left:after, [data-tooltip]:not([disabled]).has-tooltip-success.has-tooltip-left:after {
  border-color: #0000 #0000 #0000 #86ce93e6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-success.has-tooltip-right:after, [data-tooltip]:not(.is-disabled).has-tooltip-success.has-tooltip-right:after, [data-tooltip]:not([disabled]).has-tooltip-success.has-tooltip-right:after {
  border-color: #0000 #86ce93e6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-success:before, [data-tooltip]:not(.is-disabled).has-tooltip-success:before, [data-tooltip]:not([disabled]).has-tooltip-success:before {
  color: #000000b3;
  background-color: #86ce93e6;
}

[data-tooltip]:not(.is-loading).has-tooltip-warning:after, [data-tooltip]:not(.is-disabled).has-tooltip-warning:after, [data-tooltip]:not([disabled]).has-tooltip-warning:after {
  border-color: #ffe08ae6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-warning.has-tooltip-bottom:after, [data-tooltip]:not(.is-disabled).has-tooltip-warning.has-tooltip-bottom:after, [data-tooltip]:not([disabled]).has-tooltip-warning.has-tooltip-bottom:after {
  border-color: #0000 #0000 #ffe08ae6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-warning.has-tooltip-left:after, [data-tooltip]:not(.is-disabled).has-tooltip-warning.has-tooltip-left:after, [data-tooltip]:not([disabled]).has-tooltip-warning.has-tooltip-left:after {
  border-color: #0000 #0000 #0000 #ffe08ae6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-warning.has-tooltip-right:after, [data-tooltip]:not(.is-disabled).has-tooltip-warning.has-tooltip-right:after, [data-tooltip]:not([disabled]).has-tooltip-warning.has-tooltip-right:after {
  border-color: #0000 #ffe08ae6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-warning:before, [data-tooltip]:not(.is-disabled).has-tooltip-warning:before, [data-tooltip]:not([disabled]).has-tooltip-warning:before {
  color: #000000b3;
  background-color: #ffe08ae6;
}

[data-tooltip]:not(.is-loading).has-tooltip-danger:after, [data-tooltip]:not(.is-disabled).has-tooltip-danger:after, [data-tooltip]:not([disabled]).has-tooltip-danger:after {
  border-color: #843e37e6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-danger.has-tooltip-bottom:after, [data-tooltip]:not(.is-disabled).has-tooltip-danger.has-tooltip-bottom:after, [data-tooltip]:not([disabled]).has-tooltip-danger.has-tooltip-bottom:after {
  border-color: #0000 #0000 #843e37e6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-danger.has-tooltip-left:after, [data-tooltip]:not(.is-disabled).has-tooltip-danger.has-tooltip-left:after, [data-tooltip]:not([disabled]).has-tooltip-danger.has-tooltip-left:after {
  border-color: #0000 #0000 #0000 #843e37e6 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-danger.has-tooltip-right:after, [data-tooltip]:not(.is-disabled).has-tooltip-danger.has-tooltip-right:after, [data-tooltip]:not([disabled]).has-tooltip-danger.has-tooltip-right:after {
  border-color: #0000 #843e37e6 #0000 #0000 !important;
}

[data-tooltip]:not(.is-loading).has-tooltip-danger:before, [data-tooltip]:not(.is-disabled).has-tooltip-danger:before, [data-tooltip]:not([disabled]).has-tooltip-danger:before {
  color: #fff;
  background-color: #843e37e6;
}

[data-tooltip]:not(.is-loading):hover:before, [data-tooltip]:not(.is-loading):hover:after, [data-tooltip]:not(.is-loading).has-tooltip-active:before, [data-tooltip]:not(.is-loading).has-tooltip-active:after, [data-tooltip]:not(.is-disabled):hover:before, [data-tooltip]:not(.is-disabled):hover:after, [data-tooltip]:not(.is-disabled).has-tooltip-active:before, [data-tooltip]:not(.is-disabled).has-tooltip-active:after, [data-tooltip]:not([disabled]):hover:before, [data-tooltip]:not([disabled]):hover:after, [data-tooltip]:not([disabled]).has-tooltip-active:before, [data-tooltip]:not([disabled]).has-tooltip-active:after {
  opacity: 1;
  visibility: visible;
}

[data-tooltip]:not(.is-loading).has-tooltip-fade:before, [data-tooltip]:not(.is-loading).has-tooltip-fade:after, [data-tooltip]:not(.is-disabled).has-tooltip-fade:before, [data-tooltip]:not(.is-disabled).has-tooltip-fade:after, [data-tooltip]:not([disabled]).has-tooltip-fade:before, [data-tooltip]:not([disabled]).has-tooltip-fade:after {
  transition: opacity .3s linear, visibility .3s linear;
}

@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-mobile.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-top-mobile.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-top-mobile.has-tooltip-arrow:after {
    border-color: #4a4a4ae6 #0000 #0000;
    margin: -5px auto auto -5px;
    inset: 0 auto auto 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-top-mobile:before, [data-tooltip]:not(.is-disabled).has-tooltip-top-mobile:before, [data-tooltip]:not([disabled]).has-tooltip-top-mobile:before {
    margin-top: -5px;
    margin-bottom: auto;
    inset: 0 auto auto 50%;
    transform: translate(-50%, -100%);
  }
}

@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-top-tablet.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-top-tablet.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-top-tablet.has-tooltip-arrow:after {
    border-color: #4a4a4ae6 #0000 #0000;
    margin: -5px auto auto -5px;
    inset: 0 auto auto 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-top-tablet:before, [data-tooltip]:not(.is-disabled).has-tooltip-top-tablet:before, [data-tooltip]:not([disabled]).has-tooltip-top-tablet:before {
    margin-top: -5px;
    margin-bottom: auto;
    inset: 0 auto auto 50%;
    transform: translate(-50%, -100%);
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-tablet-only.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-top-tablet-only.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-top-tablet-only.has-tooltip-arrow:after {
    border-color: #4a4a4ae6 #0000 #0000;
    margin: -5px auto auto -5px;
    inset: 0 auto auto 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-top-tablet-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-top-tablet-only:before, [data-tooltip]:not([disabled]).has-tooltip-top-tablet-only:before {
    margin-top: -5px;
    margin-bottom: auto;
    inset: 0 auto auto 50%;
    transform: translate(-50%, -100%);
  }
}

@media screen and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-touch.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-top-touch.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-top-touch.has-tooltip-arrow:after {
    border-color: #4a4a4ae6 #0000 #0000;
    margin: -5px auto auto -5px;
    inset: 0 auto auto 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-top-touch:before, [data-tooltip]:not(.is-disabled).has-tooltip-top-touch:before, [data-tooltip]:not([disabled]).has-tooltip-top-touch:before {
    margin-top: -5px;
    margin-bottom: auto;
    inset: 0 auto auto 50%;
    transform: translate(-50%, -100%);
  }
}

@media screen and (min-width: 1024px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-desktop.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-top-desktop.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-top-desktop.has-tooltip-arrow:after {
    border-color: #4a4a4ae6 #0000 #0000;
    margin: -5px auto auto -5px;
    inset: 0 auto auto 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-top-desktop:before, [data-tooltip]:not(.is-disabled).has-tooltip-top-desktop:before, [data-tooltip]:not([disabled]).has-tooltip-top-desktop:before {
    margin-top: -5px;
    margin-bottom: auto;
    inset: 0 auto auto 50%;
    transform: translate(-50%, -100%);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-desktop-only.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-top-desktop-only.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-top-desktop-only.has-tooltip-arrow:after {
    border-color: #4a4a4ae6 #0000 #0000;
    margin: -5px auto auto -5px;
    inset: 0 auto auto 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-top-desktop-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-top-desktop-only:before, [data-tooltip]:not([disabled]).has-tooltip-top-desktop-only:before {
    margin-top: -5px;
    margin-bottom: auto;
    inset: 0 auto auto 50%;
    transform: translate(-50%, -100%);
  }
}

@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-until-widescreen.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-top-until-widescreen.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-top-until-widescreen.has-tooltip-arrow:after {
    border-color: #4a4a4ae6 #0000 #0000;
    margin: -5px auto auto -5px;
    inset: 0 auto auto 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-top-until-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-top-until-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-top-until-widescreen:before {
    margin-top: -5px;
    margin-bottom: auto;
    inset: 0 auto auto 50%;
    transform: translate(-50%, -100%);
  }
}

@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-widescreen.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-top-widescreen.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-top-widescreen.has-tooltip-arrow:after {
    border-color: #4a4a4ae6 #0000 #0000;
    margin: -5px auto auto -5px;
    inset: 0 auto auto 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-top-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-top-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-top-widescreen:before {
    margin-top: -5px;
    margin-bottom: auto;
    inset: 0 auto auto 50%;
    transform: translate(-50%, -100%);
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-widescreen-only.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-top-widescreen-only.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-top-widescreen-only.has-tooltip-arrow:after {
    border-color: #4a4a4ae6 #0000 #0000;
    margin: -5px auto auto -5px;
    inset: 0 auto auto 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-top-widescreen-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-top-widescreen-only:before, [data-tooltip]:not([disabled]).has-tooltip-top-widescreen-only:before {
    margin-top: -5px;
    margin-bottom: auto;
    inset: 0 auto auto 50%;
    transform: translate(-50%, -100%);
  }
}

@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-until-fullhd.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-top-until-fullhd.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-top-until-fullhd.has-tooltip-arrow:after {
    border-color: #4a4a4ae6 #0000 #0000;
    margin: -5px auto auto -5px;
    inset: 0 auto auto 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-top-until-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-top-until-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-top-until-fullhd:before {
    margin-top: -5px;
    margin-bottom: auto;
    inset: 0 auto auto 50%;
    transform: translate(-50%, -100%);
  }
}

@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-fullhd.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-top-fullhd.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-top-fullhd.has-tooltip-arrow:after {
    border-color: #4a4a4ae6 #0000 #0000;
    margin: -5px auto auto -5px;
    inset: 0 auto auto 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-top-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-top-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-top-fullhd:before {
    margin-top: -5px;
    margin-bottom: auto;
    inset: 0 auto auto 50%;
    transform: translate(-50%, -100%);
  }
}

@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-mobile.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-right-mobile.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-right-mobile.has-tooltip-arrow:after {
    border-color: #0000 #4a4a4ae6 #0000 #0000;
    margin: auto -6px -6px auto;
    inset: auto 0 50% auto;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-right-mobile:before, [data-tooltip]:not(.is-disabled).has-tooltip-right-mobile:before, [data-tooltip]:not([disabled]).has-tooltip-right-mobile:before {
    margin-top: auto;
    inset: auto -5px 50% auto;
    transform: translate(100%, 50%);
  }
}

@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-right-tablet.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-right-tablet.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-right-tablet.has-tooltip-arrow:after {
    border-color: #0000 #4a4a4ae6 #0000 #0000;
    margin: auto -6px -6px auto;
    inset: auto 0 50% auto;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-right-tablet:before, [data-tooltip]:not(.is-disabled).has-tooltip-right-tablet:before, [data-tooltip]:not([disabled]).has-tooltip-right-tablet:before {
    margin-top: auto;
    inset: auto -5px 50% auto;
    transform: translate(100%, 50%);
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-tablet-only.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-right-tablet-only.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-right-tablet-only.has-tooltip-arrow:after {
    border-color: #0000 #4a4a4ae6 #0000 #0000;
    margin: auto -6px -6px auto;
    inset: auto 0 50% auto;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-right-tablet-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-right-tablet-only:before, [data-tooltip]:not([disabled]).has-tooltip-right-tablet-only:before {
    margin-top: auto;
    inset: auto -5px 50% auto;
    transform: translate(100%, 50%);
  }
}

@media screen and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-touch.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-right-touch.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-right-touch.has-tooltip-arrow:after {
    border-color: #0000 #4a4a4ae6 #0000 #0000;
    margin: auto -6px -6px auto;
    inset: auto 0 50% auto;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-right-touch:before, [data-tooltip]:not(.is-disabled).has-tooltip-right-touch:before, [data-tooltip]:not([disabled]).has-tooltip-right-touch:before {
    margin-top: auto;
    inset: auto -5px 50% auto;
    transform: translate(100%, 50%);
  }
}

@media screen and (min-width: 1024px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-desktop.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-right-desktop.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-right-desktop.has-tooltip-arrow:after {
    border-color: #0000 #4a4a4ae6 #0000 #0000;
    margin: auto -6px -6px auto;
    inset: auto 0 50% auto;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-right-desktop:before, [data-tooltip]:not(.is-disabled).has-tooltip-right-desktop:before, [data-tooltip]:not([disabled]).has-tooltip-right-desktop:before {
    margin-top: auto;
    inset: auto -5px 50% auto;
    transform: translate(100%, 50%);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-desktop-only.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-right-desktop-only.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-right-desktop-only.has-tooltip-arrow:after {
    border-color: #0000 #4a4a4ae6 #0000 #0000;
    margin: auto -6px -6px auto;
    inset: auto 0 50% auto;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-right-desktop-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-right-desktop-only:before, [data-tooltip]:not([disabled]).has-tooltip-right-desktop-only:before {
    margin-top: auto;
    inset: auto -5px 50% auto;
    transform: translate(100%, 50%);
  }
}

@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-until-widescreen.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-right-until-widescreen.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-right-until-widescreen.has-tooltip-arrow:after {
    border-color: #0000 #4a4a4ae6 #0000 #0000;
    margin: auto -6px -6px auto;
    inset: auto 0 50% auto;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-right-until-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-right-until-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-right-until-widescreen:before {
    margin-top: auto;
    inset: auto -5px 50% auto;
    transform: translate(100%, 50%);
  }
}

@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-widescreen.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-right-widescreen.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-right-widescreen.has-tooltip-arrow:after {
    border-color: #0000 #4a4a4ae6 #0000 #0000;
    margin: auto -6px -6px auto;
    inset: auto 0 50% auto;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-right-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-right-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-right-widescreen:before {
    margin-top: auto;
    inset: auto -5px 50% auto;
    transform: translate(100%, 50%);
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-widescreen-only.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-right-widescreen-only.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-right-widescreen-only.has-tooltip-arrow:after {
    border-color: #0000 #4a4a4ae6 #0000 #0000;
    margin: auto -6px -6px auto;
    inset: auto 0 50% auto;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-right-widescreen-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-right-widescreen-only:before, [data-tooltip]:not([disabled]).has-tooltip-right-widescreen-only:before {
    margin-top: auto;
    inset: auto -5px 50% auto;
    transform: translate(100%, 50%);
  }
}

@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-until-fullhd.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-right-until-fullhd.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-right-until-fullhd.has-tooltip-arrow:after {
    border-color: #0000 #4a4a4ae6 #0000 #0000;
    margin: auto -6px -6px auto;
    inset: auto 0 50% auto;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-right-until-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-right-until-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-right-until-fullhd:before {
    margin-top: auto;
    inset: auto -5px 50% auto;
    transform: translate(100%, 50%);
  }
}

@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-fullhd.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-right-fullhd.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-right-fullhd.has-tooltip-arrow:after {
    border-color: #0000 #4a4a4ae6 #0000 #0000;
    margin: auto -6px -6px auto;
    inset: auto 0 50% auto;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-right-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-right-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-right-fullhd:before {
    margin-top: auto;
    inset: auto -5px 50% auto;
    transform: translate(100%, 50%);
  }
}

@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-mobile.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-mobile.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-bottom-mobile.has-tooltip-arrow:after {
    border-color: #0000 #0000 #4a4a4ae6;
    margin: auto auto -5px -5px;
    inset: auto auto -1px 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-bottom-mobile:before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-mobile:before, [data-tooltip]:not([disabled]).has-tooltip-bottom-mobile:before {
    margin-top: auto;
    margin-bottom: -5px;
    inset: auto auto 0 50%;
    transform: translate(-50%, 100%);
  }
}

@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-tablet.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-tablet.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-bottom-tablet.has-tooltip-arrow:after {
    border-color: #0000 #0000 #4a4a4ae6;
    margin: auto auto -5px -5px;
    inset: auto auto -1px 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-bottom-tablet:before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-tablet:before, [data-tooltip]:not([disabled]).has-tooltip-bottom-tablet:before {
    margin-top: auto;
    margin-bottom: -5px;
    inset: auto auto 0 50%;
    transform: translate(-50%, 100%);
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-tablet-only.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-tablet-only.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-bottom-tablet-only.has-tooltip-arrow:after {
    border-color: #0000 #0000 #4a4a4ae6;
    margin: auto auto -5px -5px;
    inset: auto auto -1px 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-bottom-tablet-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-tablet-only:before, [data-tooltip]:not([disabled]).has-tooltip-bottom-tablet-only:before {
    margin-top: auto;
    margin-bottom: -5px;
    inset: auto auto 0 50%;
    transform: translate(-50%, 100%);
  }
}

@media screen and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-touch.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-touch.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-bottom-touch.has-tooltip-arrow:after {
    border-color: #0000 #0000 #4a4a4ae6;
    margin: auto auto -5px -5px;
    inset: auto auto -1px 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-bottom-touch:before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-touch:before, [data-tooltip]:not([disabled]).has-tooltip-bottom-touch:before {
    margin-top: auto;
    margin-bottom: -5px;
    inset: auto auto 0 50%;
    transform: translate(-50%, 100%);
  }
}

@media screen and (min-width: 1024px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-desktop.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-desktop.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-bottom-desktop.has-tooltip-arrow:after {
    border-color: #0000 #0000 #4a4a4ae6;
    margin: auto auto -5px -5px;
    inset: auto auto -1px 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-bottom-desktop:before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-desktop:before, [data-tooltip]:not([disabled]).has-tooltip-bottom-desktop:before {
    margin-top: auto;
    margin-bottom: -5px;
    inset: auto auto 0 50%;
    transform: translate(-50%, 100%);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-desktop-only.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-desktop-only.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-bottom-desktop-only.has-tooltip-arrow:after {
    border-color: #0000 #0000 #4a4a4ae6;
    margin: auto auto -5px -5px;
    inset: auto auto -1px 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-bottom-desktop-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-desktop-only:before, [data-tooltip]:not([disabled]).has-tooltip-bottom-desktop-only:before {
    margin-top: auto;
    margin-bottom: -5px;
    inset: auto auto 0 50%;
    transform: translate(-50%, 100%);
  }
}

@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-until-widescreen.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-until-widescreen.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-bottom-until-widescreen.has-tooltip-arrow:after {
    border-color: #0000 #0000 #4a4a4ae6;
    margin: auto auto -5px -5px;
    inset: auto auto -1px 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-bottom-until-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-until-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-bottom-until-widescreen:before {
    margin-top: auto;
    margin-bottom: -5px;
    inset: auto auto 0 50%;
    transform: translate(-50%, 100%);
  }
}

@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-widescreen.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-widescreen.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-bottom-widescreen.has-tooltip-arrow:after {
    border-color: #0000 #0000 #4a4a4ae6;
    margin: auto auto -5px -5px;
    inset: auto auto -1px 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-bottom-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-bottom-widescreen:before {
    margin-top: auto;
    margin-bottom: -5px;
    inset: auto auto 0 50%;
    transform: translate(-50%, 100%);
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-widescreen-only.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-widescreen-only.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-bottom-widescreen-only.has-tooltip-arrow:after {
    border-color: #0000 #0000 #4a4a4ae6;
    margin: auto auto -5px -5px;
    inset: auto auto -1px 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-bottom-widescreen-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-widescreen-only:before, [data-tooltip]:not([disabled]).has-tooltip-bottom-widescreen-only:before {
    margin-top: auto;
    margin-bottom: -5px;
    inset: auto auto 0 50%;
    transform: translate(-50%, 100%);
  }
}

@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-until-fullhd.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-until-fullhd.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-bottom-until-fullhd.has-tooltip-arrow:after {
    border-color: #0000 #0000 #4a4a4ae6;
    margin: auto auto -5px -5px;
    inset: auto auto -1px 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-bottom-until-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-until-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-bottom-until-fullhd:before {
    margin-top: auto;
    margin-bottom: -5px;
    inset: auto auto 0 50%;
    transform: translate(-50%, 100%);
  }
}

@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-fullhd.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-fullhd.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-bottom-fullhd.has-tooltip-arrow:after {
    border-color: #0000 #0000 #4a4a4ae6;
    margin: auto auto -5px -5px;
    inset: auto auto -1px 50%;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-bottom-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-bottom-fullhd:before {
    margin-top: auto;
    margin-bottom: -5px;
    inset: auto auto 0 50%;
    transform: translate(-50%, 100%);
  }
}

@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-mobile.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-left-mobile.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-left-mobile.has-tooltip-arrow:after {
    border-color: #0000 #0000 #0000 #4a4a4ae6;
    margin: auto auto -6px -5px;
    inset: auto auto 50% 0;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-left-mobile:before, [data-tooltip]:not(.is-disabled).has-tooltip-left-mobile:before, [data-tooltip]:not([disabled]).has-tooltip-left-mobile:before {
    inset: auto auto 50% -5px;
    transform: translate(-100%, 50%);
  }
}

@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-left-tablet.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-left-tablet.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-left-tablet.has-tooltip-arrow:after {
    border-color: #0000 #0000 #0000 #4a4a4ae6;
    margin: auto auto -6px -5px;
    inset: auto auto 50% 0;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-left-tablet:before, [data-tooltip]:not(.is-disabled).has-tooltip-left-tablet:before, [data-tooltip]:not([disabled]).has-tooltip-left-tablet:before {
    inset: auto auto 50% -5px;
    transform: translate(-100%, 50%);
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-tablet-only.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-left-tablet-only.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-left-tablet-only.has-tooltip-arrow:after {
    border-color: #0000 #0000 #0000 #4a4a4ae6;
    margin: auto auto -6px -5px;
    inset: auto auto 50% 0;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-left-tablet-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-left-tablet-only:before, [data-tooltip]:not([disabled]).has-tooltip-left-tablet-only:before {
    inset: auto auto 50% -5px;
    transform: translate(-100%, 50%);
  }
}

@media screen and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-touch.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-left-touch.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-left-touch.has-tooltip-arrow:after {
    border-color: #0000 #0000 #0000 #4a4a4ae6;
    margin: auto auto -6px -5px;
    inset: auto auto 50% 0;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-left-touch:before, [data-tooltip]:not(.is-disabled).has-tooltip-left-touch:before, [data-tooltip]:not([disabled]).has-tooltip-left-touch:before {
    inset: auto auto 50% -5px;
    transform: translate(-100%, 50%);
  }
}

@media screen and (min-width: 1024px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-desktop.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-left-desktop.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-left-desktop.has-tooltip-arrow:after {
    border-color: #0000 #0000 #0000 #4a4a4ae6;
    margin: auto auto -6px -5px;
    inset: auto auto 50% 0;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-left-desktop:before, [data-tooltip]:not(.is-disabled).has-tooltip-left-desktop:before, [data-tooltip]:not([disabled]).has-tooltip-left-desktop:before {
    inset: auto auto 50% -5px;
    transform: translate(-100%, 50%);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-desktop-only.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-left-desktop-only.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-left-desktop-only.has-tooltip-arrow:after {
    border-color: #0000 #0000 #0000 #4a4a4ae6;
    margin: auto auto -6px -5px;
    inset: auto auto 50% 0;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-left-desktop-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-left-desktop-only:before, [data-tooltip]:not([disabled]).has-tooltip-left-desktop-only:before {
    inset: auto auto 50% -5px;
    transform: translate(-100%, 50%);
  }
}

@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-until-widescreen.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-left-until-widescreen.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-left-until-widescreen.has-tooltip-arrow:after {
    border-color: #0000 #0000 #0000 #4a4a4ae6;
    margin: auto auto -6px -5px;
    inset: auto auto 50% 0;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-left-until-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-left-until-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-left-until-widescreen:before {
    inset: auto auto 50% -5px;
    transform: translate(-100%, 50%);
  }
}

@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-widescreen.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-left-widescreen.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-left-widescreen.has-tooltip-arrow:after {
    border-color: #0000 #0000 #0000 #4a4a4ae6;
    margin: auto auto -6px -5px;
    inset: auto auto 50% 0;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-left-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-left-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-left-widescreen:before {
    inset: auto auto 50% -5px;
    transform: translate(-100%, 50%);
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-widescreen-only.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-left-widescreen-only.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-left-widescreen-only.has-tooltip-arrow:after {
    border-color: #0000 #0000 #0000 #4a4a4ae6;
    margin: auto auto -6px -5px;
    inset: auto auto 50% 0;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-left-widescreen-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-left-widescreen-only:before, [data-tooltip]:not([disabled]).has-tooltip-left-widescreen-only:before {
    inset: auto auto 50% -5px;
    transform: translate(-100%, 50%);
  }
}

@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-until-fullhd.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-left-until-fullhd.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-left-until-fullhd.has-tooltip-arrow:after {
    border-color: #0000 #0000 #0000 #4a4a4ae6;
    margin: auto auto -6px -5px;
    inset: auto auto 50% 0;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-left-until-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-left-until-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-left-until-fullhd:before {
    inset: auto auto 50% -5px;
    transform: translate(-100%, 50%);
  }
}

@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-fullhd.has-tooltip-arrow:after, [data-tooltip]:not(.is-disabled).has-tooltip-left-fullhd.has-tooltip-arrow:after, [data-tooltip]:not([disabled]).has-tooltip-left-fullhd.has-tooltip-arrow:after {
    border-color: #0000 #0000 #0000 #4a4a4ae6;
    margin: auto auto -6px -5px;
    inset: auto auto 50% 0;
  }

  [data-tooltip]:not(.is-loading).has-tooltip-left-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-left-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-left-fullhd:before {
    inset: auto auto 50% -5px;
    transform: translate(-100%, 50%);
  }
}

@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-mobile:after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-mobile:before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-mobile:after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-mobile:before, [data-tooltip]:not([disabled]).has-tooltip-hidden-mobile:after, [data-tooltip]:not([disabled]).has-tooltip-hidden-mobile:before {
    opacity: 0 !important;
    display: none !important;
  }
}

@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-tablet:after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-tablet:before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-tablet:after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-tablet:before, [data-tooltip]:not([disabled]).has-tooltip-hidden-tablet:after, [data-tooltip]:not([disabled]).has-tooltip-hidden-tablet:before {
    opacity: 0 !important;
    display: none !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-tablet-only:after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-tablet-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-tablet-only:after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-tablet-only:before, [data-tooltip]:not([disabled]).has-tooltip-hidden-tablet-only:after, [data-tooltip]:not([disabled]).has-tooltip-hidden-tablet-only:before {
    opacity: 0 !important;
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-touch:after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-touch:before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-touch:after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-touch:before, [data-tooltip]:not([disabled]).has-tooltip-hidden-touch:after, [data-tooltip]:not([disabled]).has-tooltip-hidden-touch:before {
    opacity: 0 !important;
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-desktop:after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-desktop:before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-desktop:after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-desktop:before, [data-tooltip]:not([disabled]).has-tooltip-hidden-desktop:after, [data-tooltip]:not([disabled]).has-tooltip-hidden-desktop:before {
    opacity: 0 !important;
    display: none !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-desktop-only:after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-desktop-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-desktop-only:after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-desktop-only:before, [data-tooltip]:not([disabled]).has-tooltip-hidden-desktop-only:after, [data-tooltip]:not([disabled]).has-tooltip-hidden-desktop-only:before {
    opacity: 0 !important;
    display: none !important;
  }
}

@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-until-widescreen:after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-until-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-until-widescreen:after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-until-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-hidden-until-widescreen:after, [data-tooltip]:not([disabled]).has-tooltip-hidden-until-widescreen:before {
    opacity: 0 !important;
    display: none !important;
  }
}

@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-widescreen:after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-widescreen:after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-hidden-widescreen:after, [data-tooltip]:not([disabled]).has-tooltip-hidden-widescreen:before {
    opacity: 0 !important;
    display: none !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-widescreen-only:after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-widescreen-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-widescreen-only:after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-widescreen-only:before, [data-tooltip]:not([disabled]).has-tooltip-hidden-widescreen-only:after, [data-tooltip]:not([disabled]).has-tooltip-hidden-widescreen-only:before {
    opacity: 0 !important;
    display: none !important;
  }
}

@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-until-fullhd:after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-until-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-until-fullhd:after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-until-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-hidden-until-fullhd:after, [data-tooltip]:not([disabled]).has-tooltip-hidden-until-fullhd:before {
    opacity: 0 !important;
    display: none !important;
  }
}

@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-fullhd:after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-fullhd:after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-hidden-fullhd:after, [data-tooltip]:not([disabled]).has-tooltip-hidden-fullhd:before {
    opacity: 0 !important;
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-mobile:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-mobile:before, [data-tooltip]:not([disabled]).has-tooltip-text-left-mobile:before {
    text-align: left;
  }
}

@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-tablet:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-tablet:before, [data-tooltip]:not([disabled]).has-tooltip-text-left-tablet:before {
    text-align: left;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-tablet-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-tablet-only:before, [data-tooltip]:not([disabled]).has-tooltip-text-left-tablet-only:before {
    text-align: left;
  }
}

@media screen and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-touch:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-touch:before, [data-tooltip]:not([disabled]).has-tooltip-text-left-touch:before {
    text-align: left;
  }
}

@media screen and (min-width: 1024px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-desktop:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-desktop:before, [data-tooltip]:not([disabled]).has-tooltip-text-left-desktop:before {
    text-align: left;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-desktop-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-desktop-only:before, [data-tooltip]:not([disabled]).has-tooltip-text-left-desktop-only:before {
    text-align: left;
  }
}

@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-until-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-until-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-text-left-until-widescreen:before {
    text-align: left;
  }
}

@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-text-left-widescreen:before {
    text-align: left;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-widescreen-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-widescreen-only:before, [data-tooltip]:not([disabled]).has-tooltip-text-left-widescreen-only:before {
    text-align: left;
  }
}

@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-until-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-until-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-text-left-until-fullhd:before {
    text-align: left;
  }
}

@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-text-left-fullhd:before {
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-mobile:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-mobile:before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-mobile:before {
    text-align: center;
  }
}

@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-tablet:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-tablet:before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-tablet:before {
    text-align: center;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-tablet-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-tablet-only:before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-tablet-only:before {
    text-align: center;
  }
}

@media screen and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-touch:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-touch:before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-touch:before {
    text-align: center;
  }
}

@media screen and (min-width: 1024px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-desktop:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-desktop:before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-desktop:before {
    text-align: center;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-desktop-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-desktop-only:before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-desktop-only:before {
    text-align: center;
  }
}

@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-until-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-until-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-until-widescreen:before {
    text-align: center;
  }
}

@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-widescreen:before {
    text-align: center;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-widescreen-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-widescreen-only:before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-widescreen-only:before {
    text-align: center;
  }
}

@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-until-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-until-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-until-fullhd:before {
    text-align: center;
  }
}

@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-fullhd:before {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-mobile:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-mobile:before, [data-tooltip]:not([disabled]).has-tooltip-text-right-mobile:before {
    text-align: right;
  }
}

@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-tablet:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-tablet:before, [data-tooltip]:not([disabled]).has-tooltip-text-right-tablet:before {
    text-align: right;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-tablet-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-tablet-only:before, [data-tooltip]:not([disabled]).has-tooltip-text-right-tablet-only:before {
    text-align: right;
  }
}

@media screen and (max-width: 1023px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-touch:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-touch:before, [data-tooltip]:not([disabled]).has-tooltip-text-right-touch:before {
    text-align: right;
  }
}

@media screen and (min-width: 1024px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-desktop:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-desktop:before, [data-tooltip]:not([disabled]).has-tooltip-text-right-desktop:before {
    text-align: right;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-desktop-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-desktop-only:before, [data-tooltip]:not([disabled]).has-tooltip-text-right-desktop-only:before {
    text-align: right;
  }
}

@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-until-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-until-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-text-right-until-widescreen:before {
    text-align: right;
  }
}

@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-widescreen:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-widescreen:before, [data-tooltip]:not([disabled]).has-tooltip-text-right-widescreen:before {
    text-align: right;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-widescreen-only:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-widescreen-only:before, [data-tooltip]:not([disabled]).has-tooltip-text-right-widescreen-only:before {
    text-align: right;
  }
}

@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-until-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-until-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-text-right-until-fullhd:before {
    text-align: right;
  }
}

@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-fullhd:before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-fullhd:before, [data-tooltip]:not([disabled]).has-tooltip-text-right-fullhd:before {
    text-align: right;
  }
}

span[data-tooltip] {
  border-bottom: 1px dashed #dbdbdb;
}

span[data-tooltip].has-tooltip-white {
  border-bottom-color: #fff;
}

span[data-tooltip].has-tooltip-black {
  border-bottom-color: #171717;
}

span[data-tooltip].has-tooltip-light {
  border-bottom-color: #fff;
}

span[data-tooltip].has-tooltip-dark {
  border-bottom-color: #352a34;
}

span[data-tooltip].has-tooltip-primary {
  border-bottom-color: #2e5e5a;
}

span[data-tooltip].has-tooltip-link {
  border-bottom-color: #b58a6a;
}

span[data-tooltip].has-tooltip-info {
  border-bottom-color: #4f3c2d;
}

span[data-tooltip].has-tooltip-success {
  border-bottom-color: #98d5a3;
}

span[data-tooltip].has-tooltip-warning {
  border-bottom-color: #ffe7a3;
}

span[data-tooltip].has-tooltip-danger {
  border-bottom-color: #96463f;
}

.control span[data-tooltip] {
  border-bottom: none;
}

.divider {
  text-transform: uppercase;
  color: #7a7a7a;
  letter-spacing: .5px;
  align-items: center;
  margin: 25px 0;
  font-size: .75rem;
  font-weight: 600;
  display: flex;
  position: relative;
}

.divider:after, .divider:before {
  content: "";
  height: 1px;
  background-color: #dbdbdb;
  flex: 1;
  display: block;
}

.divider:not(.is-right):after {
  margin-left: 10px;
}

.divider:not(.is-left):before {
  margin-right: 10px;
}

.divider.is-left:before, .divider.is-right:after {
  display: none;
}

.divider.is-vertical {
  flex-direction: column;
  margin: 0 25px;
}

.divider.is-vertical:after, .divider.is-vertical:before {
  height: auto;
  width: 1px;
}

.divider.is-vertical:after {
  margin-top: 10px;
  margin-left: 0;
}

.divider.is-vertical:before {
  margin-bottom: 10px;
  margin-right: 0;
}

.divider.is-white:after, .divider.is-white:before {
  background-color: #fff;
}

.divider.is-black:after, .divider.is-black:before {
  background-color: #0a0a0a;
}

.divider.is-light:after, .divider.is-light:before {
  background-color: #f5f5f5;
}

.divider.is-dark:after, .divider.is-dark:before {
  background-color: #271f26;
}

.divider.is-primary:after, .divider.is-primary:before {
  background-color: #264d4a;
}

.divider.is-primary.is-light:after, .divider.is-primary.is-light:before {
  background-color: #f1f8f8;
}

.divider.is-link:after, .divider.is-link:before {
  background-color: #ad7d59;
}

.divider.is-link.is-light:after, .divider.is-link.is-light:before {
  background-color: #f8f4f1;
}

.divider.is-info:after, .divider.is-info:before {
  background-color: #3f3024;
}

.divider.is-info.is-light:after, .divider.is-info.is-light:before {
  background-color: #f8f4f2;
}

.divider.is-success:after, .divider.is-success:before {
  background-color: #86ce93;
}

.divider.is-success.is-light:after, .divider.is-success.is-light:before {
  background-color: #f0f9f2;
}

.divider.is-warning:after, .divider.is-warning:before {
  background-color: #ffe08a;
}

.divider.is-warning.is-light:after, .divider.is-warning.is-light:before {
  background-color: #fffaeb;
}

.divider.is-danger:after, .divider.is-danger:before {
  background-color: #843e37;
}

.divider.is-danger.is-light:after, .divider.is-danger.is-light:before {
  background-color: #f9f1f1;
}

[data-tooltip] {
  cursor: unset !important;
}

a {
  text-decoration: none;
}

body, html {
  height: 100%;
  width: 100%;
  background-color: #fafcfb;
  margin: 0;
  padding: 0;
}

* {
  scroll-behavior: smooth;
}

.wrap-text {
  max-width: 200px;
  word-wrap: break-word;
  margin-left: auto;
  margin-right: auto;
}

.calendar {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  -webkit-user-select: none;
  user-select: none;
  min-width: 702px;
  background-color: var(--calendar-background);
}

.calendar > thead {
  box-shadow: 0 1px 1px 0px var(--calendar-border-color);
  background-color: var(--calendar-background);
  z-index: 10;
  position: sticky;
  top: 0;
}

.calendar td, .calendar p {
  margin: 0;
  padding: 0;
}

.calendar .week {
  width: 100%;
  table-layout: fixed;
}

.calendar .time-header {
  width: 3.75rem;
}

.calendar .display-time > p {
  color: var(--calendar-time-legend-color);
  white-space: nowrap;
  text-align: right;
  -webkit-user-select: none;
  user-select: none;
  font-size: .75rem;
  position: relative;
  top: -8px;
  right: 2px;
}

.calendar .day-name {
  text-align: center;
  width: 100%;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

.calendar .all-day-interval {
  border: solid 1px var(--calendar-border-color);
  height: 17px;
  cursor: pointer;
}

.calendar .all-day-interval svg {
  height: 12px;
  flex-shrink: 1;
}

.calendar .all-day-interval .lock-text {
  color: var(--calendar-background);
  flex-grow: 1;
  font-size: 12px;
}

.calendar .all-day-interval .all-day-lock, .calendar .all-day-interval .all-day-unlock {
  height: 100%;
  align-items: center;
  gap: .25rem;
  padding-inline: 1rem;
  display: flex;
}

.calendar .all-day-interval:hover {
  background-color: var(--calendar-hover);
  color: var(--calendar-hover-text) !important;
}

.calendar .all-day-interval:hover .lock-text {
  color: var(--calendar-hover-text) !important;
}

.calendar td:not(.display-time) {
  height: 25px;
  border: 1px solid var(--calendar-border-color);
}

.calendar td:not(.display-time):hover {
  background-color: var(--calendar-hover);
}

.calendar td:not(.display-time).blocked {
  background-color: var(--calendar-blocked-color);
  cursor: not-allowed;
  cursor: not-allowed;
  border: none;
}

.calendar td:not(.display-time).blocked:hover {
  background-color: var(--calendar-blocked-color);
}

.calendar .event {
  width: calc(100% - .5rem);
  background-color: var(--calendar-event-color);
  height: 100%;
  margin-inline: auto;
  position: relative;
}

.calendar .event:not(.draft) {
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.calendar .event:not(.event-end):not(.event-start) {
  height: calc(100% + 1px);
  margin-bottom: -1px;
}

.calendar .event-start {
  z-index: 1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  top: 1px;
}

.calendar .event-start:not(.event-end) > .event-time {
  white-space: unset;
  overflow: unset;
}

.calendar .event-start > .event-resize {
  cursor: n-resize;
  border-top-color: var(--calendar-event-text-color);
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-color: unset;
  border-bottom-style: unset;
  border-bottom-width: unset;
  top: 2px;
}

.calendar .event-end {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  bottom: 1px;
}

.calendar .event-time {
  text-align: center;
  color: var(--calendar-event-text-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 20px);
  height: 100%;
  vertical-align: center;
  margin-top: 2px;
  font-size: .85em;
  position: absolute;
  overflow: hidden;
}

.calendar .event-close {
  color: var(--calendar-event-text-color);
  cursor: pointer;
  width: 20px;
  z-index: 1;
  background-color: #0000;
  border: none;
  position: absolute;
  top: 4px;
  right: 0;
}

.calendar .event-resize {
  height: 15px;
  min-width: 20px;
  width: 25%;
  border: none;
  border-bottom-color: var(--calendar-event-text-color);
  cursor: s-resize;
  z-index: 1;
  background-color: #0000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
}

.calendar .event.dragging {
  opacity: .9;
}

.calendar .event.draft {
  opacity: .5;
  cursor: ns-resize;
}

.calendar .event.draft.dragging {
  cursor: grabbing;
}

.calendar .event.blocked {
  cursor: not-allowed;
  background-color: var(--calendar-event-color);
  opacity: .7;
}

.calendar .event.blocked .event-resize, .calendar .event.blocked .event-close {
  cursor: not-allowed;
}

.calendar .event-start.event-end {
  height: calc(100% - 2px);
  top: 1px;
  bottom: 1px;
}

.calendar .overlay-event, .calendar .overlay-event:hover {
  background-color: var(--calendar-overlay);
}

.calendar .all-day-row {
  font-size: smaller;
}

.participants {
  height: 80vh;
  flex-direction: row;
  column-gap: 1.5rem;
  display: flex;
}

.participant-name {
  flex-direction: row;
  justify-content: space-between;
  display: flex !important;
}

.participant-list-item {
  margin-bottom: 2px;
}

.participant-list {
  max-height: calc(80vh - 114px - 1em);
  overflow-y: auto;
}

.meeting-configuration label.panel-block {
  border-left-style: solid;
  border-left-width: 3px;
}

.meeting-configuration label.panel-block:not(.is-active) {
  border-left-color: #0000;
}

.meeting-configuration .meeting-item {
  height: min-content;
}

.meeting-configuration .meeting-form {
  flex-shrink: 1;
}

.meeting-configuration .meeting-participant-list {
  max-height: 30vh;
  overflow-y: auto;
}

.loading {
  filter: blur(2px);
}

.loading-container {
  height: 100%;
  width: 100%;
  place-items: center;
  display: grid;
}

.unstyled {
  background: unset;
  border: unset;
}

#participant-calendar, #available-calendar {
  height: 80vh;
  --calendar-background: #fff;
  --calendar-hover: #f8f4f2;
  --calendar-hover-text: #aa866a;
  --calendar-border-color: #dbdbdb;
  --calendar-event-color: #264d4a;
  --calendar-event-text-color: #fff;
  --calendar-blocked-color: #271f26;
  --calendar-time-legend-color: #4a4a4a;
  --calendar-overlay: #5baea8;
  overflow: scroll;
}

.ignore-pointer-events {
  pointer-events: none;
}

.highlighted {
  background: #f8f4f2;
}

.zeitplan-container {
  height: 100%;
}

.zeitplan-footer {
  position: sticky;
  top: 100%;
}

/*# sourceMappingURL=index.9e4b089f.css.map */
